export default {
  leftTitle: 'Indicator Classification Management',
  rightTitle: 'Indicator Details',
  msg: {
    title: 'Hint',
    delSelect: 'Please select the index directory you want to delete',
    delConfirm: 'Confirm to delete the selected directory?',
    delConfirmChild:
      'The selected menu has a subdirectory and cannot be deleted.',
  },
  form: {
    kindId: {
      label: 'Indicator ID',
    },
    kindName: { label: 'Indicator Name' },
    parentKindId: { label: 'Parent indicator classification ID' },
    parentKindNm: { label: 'Parent indicator classification Name' },
    sortNo: { label: 'Sequence' },
  },
  tips: {
    add: 'Successfully Add!',
    modify: 'Successfully Modify!',
    del: 'Successfully Delete!',
    cancel: 'Operation cancelled',
    delSelect: 'Please select the item you want to delete',
    delConfirm:
      'Do you want to confirm the deletion of the following indicators',
  },
  index: {
    leftTitle: 'Indicator Classification',
    placeHolder: {
      indexSearch: 'Enter keywords to search', // 输入关键字进行搜索
    },
    form: {
      indexId: 'Indicator ID',
      indexName: 'Indicator Name',
      indexType: 'Indicator Type',
      calcType:'Calc Type',
      unit: 'Unit',
      indexMeas: 'Metric',
      indexNumProp: 'Attribute',
      dataFrom: 'Data Source',
      isOpen: 'Activated',
      fitObj: 'Indicator Dimension',
      howDecimal: 'Exact Number of Digits',
      isHot: 'Hot Selling',
      indexDesc: 'Indicator Describe',
      formula: 'Formula',
      operator: 'Operator',
      selectIndex: 'Select Indicator',
      ruleTable: 'Data Source Table',
      fieldName: 'Field Name',
      calcMode: 'Calc Mode',
      whereSql: 'custom SQL',
      back: 'Back',
      next: 'Next',
    },
    formRules: {
      indexId: 'Please input indicator id',
      indexName: 'Please input indicator name',
      indexType: 'Please select indicator type',
      fitObj: 'Please select indicator dimension',
      unit: 'Please select indicator unit',
      indexMeas: 'Please select indicator metric',
      indexNumProp: 'Please select attribute ',
      dataFrom: 'Please select data source',
      isOpen: 'Please select whether it is valid or not',
      howDecimal: 'Please select the exact number of digits',
      calcType: 'Please select calculation type',
      isHot: 'Please select whether it is a hot selling item',
      formula: 'Please input formula',
      calcMode: 'Please select Calc Mode',
      ruleTableId: 'Please select data source table',
      fieldNameEn: 'Please select field name',
    },
    dialog: {
      dialogTitle: {
        mod: 'Metric Modify',
      add: 'Metric Add',
      },
      form: {
        stepOne: 'Configuration of Indicator Definition',
        stepTwo: 'Configuration of Indicator rules',
      }
    },
  },
};
