import LayoutBase from '@/layout/base-layout.vue';
import { RouteRecordRaw } from 'vue-router';

const cddWorkflowRoutes = [
  {
    path: 'customer-information',
    name: 'Customer Information',
    component: () => import('@/views/cip/workflow/views/customer-information/index.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'name-screening',
    name: 'Customer Information name-screening',
    component: () => import('@/views/cip/workflow/views/name-screening/name-screening-upload.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'transaction-review',
    name: 'Customer Information transaction-review',
    component: () => import('@/views/cip/workflow/views/transaction-review/transaction-review-upload.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'customer-communication',
    name: 'Customer Information customer-communication',
    component: () => import('@/views/cip/workflow/views/customer-communication/customer-communication.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'cara-edd',
    name: 'Customer Information cara-edd',
    component: () => import('@/views/cip/workflow/views/cara-edd/index.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'ci-maintenance',
    name: 'Customer Information ci-maintenance',
    component: () => import('@/views/cip/workflow/views/ci-maintenance/ci-maintenance.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'applied-for-extension',
    name: 'Customer Information applied-for-extension',
    component: () => import('@/views/cip/workflow/views/applied-for-extension.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'document-upload',
    name: 'Customer Information document-upload',
    component: () => import('@/views/cip/workflow/views/document-upload/document-upload.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'customer-profile',
    name: 'Customer Information customer-profile',
    component: () => import('@/views/cip/workflow/views/customer-profile.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'customer-off-boarding',
    name: 'customer-off-boarding',
    component: () => import('@/views/cip/workflow/views/customer-off-boarding/customer-off-boarding.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'control-memo',
    name: 'control-memo',
    component: () => import('@/views/cip/workflow/views/control-memo.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'case-log',
    name: 'case-log',
    component: () => import('@/views/cip/workflow/views/case-log.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
  {
    path: 'overview-of-case-workflow',
    name: 'overview-of-case-workflow',
    component: () => import('@/views/cip/workflow/views/overview-of-case-workflow.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}',
      key: 'workflow-{okpCaseReferenceNo}',
    }
  },
]

const xaaFormRoutes = (): Array<RouteRecordRaw> => [
  {
    path: '/debug',
    name: 'Debug',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/views/cip/workflow/views/cara-edd/Debug/Debug.vue'
      ),
    meta: {
      permission: false,
    },
  },
  {
    path: '/edd-form',
    name: 'EddForm',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/views/cip/workflow/views/cara-edd/eddForm/index.vue'
      ),
    meta: {
      permission: false,
    },
  },
  {
    path: '/eca-form',
    name: 'EcaraForm',
    component: () =>
      import(
        /* webpackChunkName: "ecara" */ '@/views/cip/workflow/views/cara-edd/caraForm/index.vue'
      ),
    meta: {
      permission: false,
    },
  },
  {
    path: '/bcqd-form',
    name: 'BcqdForm',
    component: () =>
      import(
        /* webpackChunkName: "ecara" */ '@/views/cip/workflow/views/cara-edd/bcqdForm/index.vue'
      ),
    meta: {
      permission: false,
    },
  },
  {
    path: '/non-eca-form',
    name: 'NonEcaraForm',
    component: () =>
      import(
        /* webpackChunkName: "nonEcara" */ '@/views/cip/workflow/views/cara-edd/nonIndividual/index.vue'
      ),
    meta: {
      permission: false,
    },
  },
];

const constantRoutes = (): Array<RouteRecordRaw> => [
  ...xaaFormRoutes(),
  {
    path: '/',
    component: () => import('@/layout/base-layout.vue'),
    redirect: '/main',
    children: [
      {
        path: '/main',
        name: 'mainPage',
        component: () => import('@/views/main/index.vue'),
        meta: {
          title: 'Main Page',
          name: 'mainPage',
          icon: '',
          breadcrumb: ['首页'],
        },
      },
      {
        path: '/team-head-main-page',
        name: 'teamHeadMainPage',
        component: () => import('@/views/team-head-main/index.vue'),
        meta: {
          title: 'Team Head Main Page',
          name: 'teamHeadMainPage',
          icon: '',
          breadcrumb: ['headPage'],
        },
      },
      {
        path: '/welcome',
        name: 'welcomePage',
        component: () => import('@/views/welcome/v-list.vue'),
        meta: {
          permission: false,
          title: 'Welcome Page',
          name: 'welcomePage',
          icon: '',
          breadcrumb: ['welcomePage'],
        },
      },
      {
        path: '/confirm-received',
        name: 'confirmReceived',
        component: () =>
            import(
                '@/views/cip/workflow/views/customer-communication/v-list.vue'
                ),
        meta: {
          permission: false,
          title: 'Confirm Received-{taskId}',
          key: 'confirmReceived-{taskId}',
        },
      },
      {
        path: '/cip/post-str-enquiry/detail',
        name: 'postStrEnquiryDetail',
        component: () =>
          import('@/views/cip/post-str-enquiry/detail/index.vue'),
        meta: {
          permission: '/cip/post-str-enquiry',
          title: 'postStrEnquiryDetail',
          name: 'postStrEnquiryDetail',
          icon: '',
        },
      },
      {
        path: '/empty',
        name: 'empty',
        component: () => import('@/views/no-fund/v-empty.vue'),
        meta: {
          permission: false,
          title: '404',
          name: '404',
          icon: '',
          breadcrumb: ['menu', 'empty'],
        },
      },
      {
        path: '/custList',
        name: 'custList',
        component: () => import('@/views/no-fund/v-empty.vue'),
        meta: {
          permission: false,
          title: '客户信息列表',
          name: 'custList',
          icon: '',
          breadcrumb: ['menu', 'empty'],
        },
      },
      {
        path: '/post-str-enquiry/checklist/:okpPostStrCaseRefNo',
        name: 'checklistView',
        component: () => import('@/views/cip/post-str-enquiry/checklist.vue'),
        meta: {
          permission: false,
          title: 'Check List-{okpPostStrCaseRefNo}',
          key: 'checklist-{okpPostStrCaseRefNo}',
        },
      },
      {
        path: '/workflow',
        name: 'Workflow',
        redirect: (to) => ({
          path: to.path + '/customer-information',
          query: to.query,
        }),
        component: () => import('@/views/cip/workflow/index.vue'),
        children: cddWorkflowRoutes,
      },
      {
        path: '/ongoing-cdd-workflow/:okpCaseRefNo',
        name: 'ongoing-cdd-workflow',
        component: () => import('@/views/workflow-management/deal-with/v-list.vue'),
        meta: {
          permission: false,
          title: 'Workflow-{okpCaseRefNo}',
          key: 'Workflow-{okpCaseRefNo}',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/v-login.vue'),
    meta: {
      permission: false,
    },
  },
  {
    path: '/guidanceNote/individual',
    name: 'guidance-note-individual',
    component: () =>
      import(
        /* webpackChunkName: "login" */ '@/views/cip/workflow/views/cara-edd/guidance-note/individual.vue'
      ),
    meta: {
      permission: false,
    },
  },
  //废弃：走通用容器渲染
  // {
  //   path: '/post-str-enquiry/checklist',
  //   name: 'checklist',
  //   component: () => import('@/views/cip/post-str-enquiry/checklist.vue'),
  //   meta: {
  //     permission: false,
  //     title: 'Check List-{okpPostStrCaseRefNo}',
  //     key: 'checklist-{okpPostStrCaseRefNo}',
  //   },
  // },
  {
    path: '/out-workflow',
    name: 'Out Workflow',
    redirect: (to) => ({
      path: to.path + '/customer-information',
      query: to.query,
    }),
    component: () => import('@/views/cip/workflow/index.vue'),
    children: cddWorkflowRoutes.map((item) => {
      return {
        ...item,
        name: 'out-' + item.name,
      };
    }),
  },
  {
    path: '/out-applied-for-extension',
    name: 'Customer Information out applied-for-extension',
    component: () => import('@/views/cip/workflow/views/applied-for-extension.vue'),
    meta: {
      permission: false,
      title: 'Workflow-{okpCaseReferenceNo}-out',
      key: 'workflow-{okpCaseReferenceNo}-out',
    }
  },
  //废弃：走通用容器渲染
  // {
  //   path: '/workflow/delete/remarks',
  //   name: 'workflowDeleteRemarks',
  //   component: () => import('@/views/cip/workflow/views/remarks/index.vue'),
  //   meta: {
  //     permission: false,
  //     title: 'remarks',
  //     key: 'remarks',
  //   },
  // },
  {
    path: '/workflow/common/checklistAndTerminate',
    name: 'checklistAndTerminate',
    component: () =>
      import('@/views/cip/workflow/commonContainer/checklistAndTerminate.vue'),
    meta: {
      permission: false,
      title: 'checklistAndTerminate-{okpPostStrCaseRefNo}',
      key: 'checklistAndTerminate-{okpPostStrCaseRefNo}',
    },
  },
  {
    path: '/workflow/common',
    name: 'commonContainer',
    component: () => import('@/views/cip/workflow/commonContainer/index.vue'),
    meta: {
      permission: false,
      title: 'commonContainer',
      key: 'commonContainer',
    },
    children: [
      {
        path: '/workflow/common/checklist',
        name: 'checklist',
        component: () => import('@/views/cip/post-str-enquiry/checklist.vue'),
        meta: {
          permission: false,
          title: 'Check List-{okpPostStrCaseRefNo}',
          key: 'checklist-{okpPostStrCaseRefNo}',
        },
      },
      {
        path: '/workflow/common/remarks',
        name: 'Remarks',
        component: () => import('@/views/cip/workflow/views/remarks/index.vue'),
        meta: {
          permission: false,
          title: 'remarks',
          key: 'remarks',
        },
      },
      {
        path: '/workflow/common/confirm-received',
        name: 'workflw-confirmReceived',
        component: () =>
          import(
            '@/views/cip/workflow/views/customer-communication/workflow-confirm-received.vue'
          ),
        meta: {
          permission: false,
          title: 'confirmReceived',
          key: 'confirmReceived',
        },
      },
      {
        path: '/workflow/common/terminate',
        name: 'Terminate',
        component: () =>
          import(
            '@/views/cip/post-str-enquiry/detail/customer-off-boarding/terminate.vue'
          ),
        meta: {
          permission: false,
          title: 'Terminate',
          key: 'Terminate',
        },
      },
      {
        path: '/workflow/common/customer-information',
        name: 'CustomerInformation',
        component: () => import('@/views/cip/workflow/index.vue'),
        meta: {
          permission: false,
          title: 'CustomerInformation',
          key: 'CustomerInformation',
        },
      },
    ],
  },
  {
    path: '/cust-mgt',
    component: LayoutBase,
    children: [
      // {
      //   path: '/cust-mgt/cust-view',
      //   name: 'custView',
      //   component: () => import('@/views/cust-mgt/cust-view/v-list.vue'),
      //   meta: {
      //     title: i18n.global.t('route.custView'),
      //     name: 'custView',
      //     icon: '',
      //   },
      // },
      // {
      //   path: '/cust-mgt/group-view',
      //   name: 'groupView',
      //   component: () => import('@/views/cust-mgt/group-view/v-list.vue'),
      //   meta: {
      //     title: i18n.global.t('route.groupView'),
      //     name: 'groupView',
      //     icon: '',
      //   },
      // },
      {
        path: '/empty',
        name: 'empty',
        component: () => import('@/views/no-fund/v-empty.vue'),
        meta: {
          permission: false,
          title: '404',
          name: '404',
          icon: '',
          breadcrumb: ['menu', 'empty'],
        },
      },
    ],
  },
  {
    path: '/to-do-flow-form',
    name: 'To-Do flow form',
    component: () => import('@/views/workflow-management/to-do/flow-form.vue'),
    meta: {
      permission: false,
    },
  },
];

export default constantRoutes;
