import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ba1bd5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-box"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$setup.hide ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["menu-box", {
      'unfold-menu': $setup.unfold
    }])
  }, [_withDirectives(_createElementVNode("img", {
    src: $setup.logo,
    alt: "logo",
    class: "logo"
  }, null, 8 /* PROPS */, _hoisted_2), [[_vShow, $setup.unfold]]), _createVNode($setup["VerticalMenu"], {
    "is-collapse": !$setup.unfold
  }, null, 8 /* PROPS */, ["is-collapse"]), _createElementVNode("img", {
    src: $setup.alignRightSvg,
    onClick: $setup.unfoldMenu,
    alt: "logo",
    class: "square-icon"
  }, null, 8 /* PROPS */, _hoisted_3)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(["content-box", {
      'unfold-menu': $setup.unfold,
      'w-100': $setup.hide
    }])
  }, [!$setup.hide ? (_openBlock(), _createBlock($setup["VNavbar"], {
    key: 0
  })) : _createCommentVNode("v-if", true), _createVNode($setup["BaseApp"])], 2 /* CLASS */)]);
}