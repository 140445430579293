import { defineComponent, reactive, ref, toRefs, computed } from 'vue';
export default defineComponent({
  name: 'VDynamicGrid',
  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableColumns: {
      type: Array,
      required: true
    },
    //是否显示复选框
    isSelect: {
      type: Boolean,
      default: false
    },
    //是否显示单选框
    isSingleSel: {
      type: Boolean,
      default: false
    },
    //是否显示序号列
    isIndex: {
      type: Boolean,
      default: false
    },
    //总数
    total: {
      type: Number,
      default: 0
    },
    //翻页操作
    currentPage: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    // 条/页限制
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100]
    },
    //表格动态高度
    tableHight: {
      type: Number || String,
      default: 0
    },
    isPage: {
      type: Boolean,
      default: true
    },
    //分页按钮个数
    pagerCount: {
      type: Number,
      default: 5
    },
    //操作按钮
    hasOpbtn: {
      type: Boolean,
      default: true
    },
    //是否自动撑开高度
    isAutoHeight: {
      type: Boolean,
      default: false
    },
    // 自定义列样式
    cellStyle: {
      type: Function,
      default: null
    },
    // 自定义单元格类名
    cellClassName: {
      type: Function,
      default: null
    },
    isBorder: {
      type: Boolean,
      default: true
    }
  },
  emits: ['handleColumn', 'selectionChange', 'update:currentPage', 'update:pageSize', 'currentChange', 'sizeChange', 'row-click', 'row-dblclick'],
  setup(props, {
    emit
  }) {
    const elTableRef = ref(null);
    const dataMap = reactive({
      meTableColumns: computed(() => [...props.tableColumns]),
      selectedColumns: [],
      isDblclick: false,
      basePagerCount: props.pagerCount,
      radio: ''
    });
    const methodsMap = reactive({
      getSingleId(row) {
        return JSON.stringify(row);
      },
      handleColumn: (row, $propKey) => {
        emit('handleColumn', {
          data: {
            ...row
          },
          column: $propKey
        });
      },
      handleCurrentChange(currentPage) {
        emit('update:currentPage', currentPage);
        emit('currentChange');
      },
      handleSizeChange(pageSize) {
        emit('update:pageSize', pageSize);
        emit('update:currentPage', 1);
        emit('sizeChange');
      },
      handleSelectionChange(val) {
        dataMap.selectedColumns = val;
        emit('selectionChange', val);
      },
      clearSelection() {
        elTableRef.value.clearSelection();
      },
      rowClickFn(row) {
        emit('row-click', row);
        if (props.isSelect) {
          elTableRef.value.toggleRowSelection(row);
        }
        if (props.isSingleSel) {
          dataMap.radio = JSON.stringify(row);
        }
      },
      rowDbCliclFn(row) {
        if (props.isSelect) return false;
        emit('row-dblclick', row);
      }
    });
    return {
      ...toRefs(dataMap),
      ...toRefs(methodsMap),
      elTableRef
    };
  }
});