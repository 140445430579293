import reports from './reports/hk'
import tasks from './tasks/hk';
import notice from './notice/hk';
import message from './message/hk';
import approval from './approval/hk'
export default {
  ...tasks,
  ...reports,
  ...notice,
  ...message,
  ...approval,
};
