export default {
  bireport: {
    promptId: '錯誤碼',
    promptDesc: '錯誤描述',
    Remark: '備註',
    number: '序號',
    promptDescCN: '錯誤碼(簡中)',
    promptDescTC: '錯誤碼(繁中)',
    promptDescEN: '錯誤碼(英文)',
    creator: '創建人',
    creatTime: '創建時間',
    updater: '修改人',
    updateTime: '修改時間',
  },
};
