export default {
  currentDtTip: '批次日期:',
  helloTip: '您好',
  modPswTip: '修改密碼',
  logoutBtnTip: '退出登錄',
  sysTitle: '公司客戶關系管理系統',
  custSearchPlaceholder: '請輸入客戶號/客戶名稱/證件號/集團號/集團名稱',
  search: {
    rules: '請輸入',
  },
  tagView: {
    refreshTag: '刷新當前頁面',
    closeRightTags: '關閉右側頁面',
    closeOtherTags: '關閉其他頁面',
    closeAllTags: '關閉所有頁面',
  },
  searchDialog: {
    customerLabel: '客戶信息列表',
    customerColumns: {
      custId: '客戶號',
      custEname: '客戶名稱 (英)',
      custName: '客戶名稱 (中)',
      certType: '證件類型',
      certCode: '證件號',
    },
    utimateLabel: '集團信息列表',
    utimateColumns: {
      ultmCtrlrId: '集團號',
      ultmCtrlrNameEn: '集團名稱 (英)',
      ultmCtrlrNameCh: '集團名稱 (中)',
      ultmCtrlrGrmEname: 'GRM',
    },
  },
};
