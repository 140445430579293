export enum WebsocketActionType {
  startWebSocket = 'startWebSocket',
  checkOpen = 'checkOpen',
  sendWebSocketMessage = 'sendWebSocketMessage',
  reconnectWebSocket = 'reconnectWebSocket',
  clearWebSocket = 'clearWebSocket',
  startHeartCheck = 'startHeartCheck',
  clearHeartCheckTimer = 'clearHeartCheckTimer',
  closeWebsocket = 'closeWebsocket',
  handleMsg = 'handleMsg'
}
