export default {
  report: {
    myReports: '我的工作報告',
    myTeamsReports: '我的團隊工作報告',
    reportType: '報告類型',
    reportTitle: '報告標題',
    costomerName: '客戶名稱',
    reportstate: '報告狀態',
    reportDate: '報告日期',
    number: '編號',
    createDate: '創建時間',
    userId: '提交人',
    reportDetails: '報告詳情',
    editReport:'修改報告',
    newReport:'添加報告',
    basicInfo:'基本信息',
    reporter:'報告人',
    team:'團隊',
    section:'分部',
    department:'部門',
    visitdetails:'報告詳細信息',
    time:'時間',
    Address:'地址',
    remark:'備註',
    appointAppr: '指定審批人',
    selectAppr: '選擇審批人',
    yesAppointAppr: '是',
    noAppointAppr: '否',
    formRules: {
      reportTitleRule: '請輸入報告標題',
      visitDateRule: '請選擇日期',
      custIdRule: '請選擇客戶',
      appointApprRule: '請選擇是否指定審批人',
      selectApprRule: '請選擇審批人',
      deleteMsg: '確認刪除該條報告嗎？',
      reportTypeRule:'請選擇報告類型',
    },
    attachment:'附件',
    selectFile:'選擇文件',
    supportedFileType:'支持的文件類型:',
    eachfile:'每個文件不得超過15MB。最多5個文件'
  },
};
