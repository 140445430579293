export default {
  role: {
    form: {
      roleId: '角色ID',
      roleName: '角色名称(简中)',
      roleNameTC: '角色名称(繁中)',
      roleNameEN: '角色名称(英)',
      roleProperty: '岗位属性',
      roleScope: '角色范围',
      status: '是否启用',
      stNo: '排序号',
      remark: '备注',
    },
    roleColumns: {
      homeUrl: '菜单类型',
      hpType: '首页类型',
    },
    tooltip: {
      view: '查看',
      funcConfig: '功能权限配置',
      dataPowerConfig: '数据权限配置',
      dataPower: ' 数据权限',
      user: '角色用户配置',
    },
    delMes1: '请选择需要删除的项',
    delMes2: '确认删除id为',
    delMes3: '的角色吗',
    succ1: '启用成功',
    succ2: '禁用成功',
    addSucc: '新增成功！',
    editSucc: '修改成功！',
    baseFromRules: {
      roleId: '请输入角色ID',
      roleName: '请输入角色名称',
      lawOrgId: '请输入法人机构id',
      roleNameTC: '请输入角色名称(繁中)',
      roleNameEN: '请输入角色名称(英)',
      remark: '请输入备注',
      roleProperty: '请选择岗位范围',
      roleScope: '请选择角色范围',
    },
    dialog: {
      title1: '新增角色',
      title2: '功能权限配置',
      title3: '修改角色',
      title4: '查看角色',
      title5: '数据权限配置',
    },
    roleData: {
      tableColumns: {
        tableName: '业务表名',
        authType: '权限类型',
        lineAuth: '条线数据权限',
        authScopeName: '数据范围',
        isIncludeSub: '是否含下级',
      },
      ruleType: {
        authType: '请选择权限类型',
        lineAuth: '请选择条线数据权限!',
        tableName: '请选择业务表名!',
        authScopeName: '请选择数据范围!',
        isIncludeSub: '请选择是否包含上下级!',
      },
    },
    user: {
      title: '角色用户配置',
      userName: '用户姓名',
      orgName: '所属机构',
      suc1: '添加成功',
      suc2: '删除成功',
      suc3: '已取消删除',
      suc4: '请选择需要删除的项',
      con1: '确认删除用户id为',
      con2: '的用户吗',
      mes: '请至少选择一名用户添加',
      tabcolumns: {
        userNameEN: '用户姓名(英)',
        userName: '用户姓名(中)',
        sex: '性别',
        orgLevelName: '所属机构',
        roleName: '当前角色',
        roleNames: '拥有的全部角色',
        status: '用户状态',
      },
    },
  },
};
