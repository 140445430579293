export default {
  tasks: {
    tabs1: '我收到的待办任务',
    tabs2: '我发起的待办任务',
    form: {
      noticeTitle: '任务名称',
      tasksDt1: '任务接收时间',
      tasksDt2: '任务创建时间',
      planfinishDt: '计划完成时间',
      importanceType: '重要程度',
      noticeType: '任务状态',
      launch: '发起人',
      received: '接收人',
    },
    confirmMessage: '任务完成后不可再执行反馈',
    warnMsg: '请先选择一条数据',
    editSucc: '反馈成功',
    addSucc: '新增成功',
    selTitle1: '分配任务到执行人',
    selTitle2: '请选择执行人',
    selTitle3: '已选择执行人',
    clearBtn: '清除所有',
    sel: '选择',
    warnMsg1: '请选择一个员工',
    taskDetail: '任务详情',
    taskFeedback: '任务反馈',
    taskFeedbackRecording: '任务反馈记录',
    tableBtn: {
      finishTitle: '任务完成',
      addTask: '任务创建',
      detailTitle: '详情',
      feedback: '反馈',
      lookFeedback: '查看反馈记录',
      publish: '发布',
    },
    tableColumns: {
      noticeTitle: '任务名称',
      noticeType: '任务状态',
      importanceType: '重要程度',
      taskCycle: '任务周期',
      feedbackDt: '最近反馈时间',
      nextfeedbackDt: '下次计划反馈时间',
      launch: '发起人',
      launchOrg: '发起人岗位',
      received: '接收人',
      receivedOrg: '接收人岗位',
      taskCompleteTime: '实际完成时间',
    },
    historyColumns: {
      feedbackDetail: '反馈内容',
      feedbackDt: '反馈时间',
      feedbackPel: '反馈人',
    },
    formDialog: {
      taskTitle: '任务标题',
      taskDetail: '任务详情',
      taskRequest: '任务要求',
      file: '任务附件',
      uploadfile: '上传附件',
      taskFeedback: '任务反馈',
      taskObjType: '任务对象类型',
      taskObj: '任务对象',
    },
    rules: {
      rulesFeedbackDetail: '请输入任务反馈',
      rulesnextFeedbackDate: '请输入下次计划反馈时间',
      rulestaskName: '请输入任务标题',
      rulescreateDate: '请输入任务周期',
      rulestaskLevel: '请选择重要程度',
      rulestaskDetail: '请输入任务详情',
      rulestaskRequest: '请输入任务要求',
      rulestaskObjType: '请选择任务对象类型',
      rulestaskObj: '请选择任务对象',
    },
  },
};
