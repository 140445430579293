import { defineComponent } from 'vue';
export default defineComponent({
  name: 'VCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    //是否增加边框
    isBorder: {
      type: Boolean,
      default: false
    },
    isDivision: {
      type: Boolean,
      default: false
    },
    isDashed: {
      type: Boolean,
      default: false
    }
  }
});