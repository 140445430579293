export default {
  mainInfo: {
    title: {
      work: '我的工作',
      tools: '工具台',
      dashboar: '经营仪表盘',
      performance: '业务构成分析',
      followedCustomers: '关注客户',
      alerts: '事件提醒',
      calendar: '月历展示',
      bulletin: '公告栏',
      tasks: '待办任务',
    },
    tools: {
      logTitle: '工具台配置',
    },
    myWork: {
      toDoTasks: '待办任务',
      messageReminder: '消息提醒',
      myApproval: '我的审批',
      mySchedule: '我的日程',
    },
    mgkpiIndex: {
      comparisonValue: {
        lastMonth: '较上月末',
        lastYear: '较上年末',
      },
    },
    mgkpiMore: {
      moreTitle: '仪表盘重要指标配置（币种：人民币）',
      moreTitleTips: '最多可展示6个指标',
    },
    calendar: {
      today: '今天',
      selectLabel: {
        chn: '中国',
        hk: '香港',
        us: '美国',
      },
    },
    planPressInfo: {
      echartsLabel: {
        income: '收入',
        loans: '贷款',
        deposits: '存款',
      },
      echartsQueryParam: {
        timeframe: '周期类型',
        calculationType: '计算类型',
        balanceType: '余额类型',
      },
      custLbael: '客户排名',
      incomeTableLabel: {
        index: '排名',
        custNum: '客户编号',
        custName: '客户名称',
        incomeMTD: '本月收入',
        incomeQTD: '本季度收入',
        incomeYTD: '本年收入 (YTD)',
        incomeLastMTD: '上月收入',
        incomeLastQTD: '上季度收入',
        incomeLastYTD: '上年收入 (YTD)',
        loanMTD: '月均余额 (本月)',
        loanYTD: '年均余额 (本月)',
        loanPeEnd: '期末余额(本期)',
        loanBalLastMTD: '月均余额 (去年同月)',
        loanBalLastYTD: '年均余额 (去年同月)',
        loanBalLastPeEnd: '期末余额 (去年年底)',
        depositMTD: '月均余额 (本月)',
        depositYTD: '年均余额 (本月)',
        depositPeEnd: '期末余额 (本期)',
        depositLastMTD: '月均余额 (去年同月)',
        depositLastYTD: '年均余额 (去年同月)',
        depositLastPeEnd: '期末余额 (去年年底)',
      },
    },
    taskRadio: {
      myReceivedTasks: '我收到的待办',
      myCreatedTasks: '我发起的待办',
    },
    task: {
      index: '序号',
      taskName: '任务名称',
      taskStatus: '任务状态',
      taskLevel: '重要程度',
      receivedDate: '接收日期',
      createdDate: '创建日期',
      taskEndDate: '任务周期',
      taskCreatorName: '发起人',
      taskRoleName: '发起人岗位',
      loansCreatorName: '发起人',
      loansRoleName: '发起人岗位',
      moreLogTitle: '详情',
      moreLogTableTitle: '反馈记录详情',
    },
    alerts: {
      tableLabel: {
        index: '序号',
        eventType: '事件名称',
        remindDate: '提醒日期',
        stauts: '阅读状态',
      },
      moreSreach: {
        custName: '客户名称：',
        custNo: '客户编号：',
      },
      moreTableLabel: {
        index: '序号',
        eventType: '事件分类',
        remindDate: '提醒日期',
        custEname: '客户名称',
        custId: '客户号',
        accountNo: '账户号',
        transacNo: '交易号',
        amount: '金额（‘000HKE）',
        operation: '操作',
        view: '查看详情',
      },
      viewLabel: {
        operationDate: '操作日期',
        operationTime: '操作时间',
        transCurrency: '交易货币',
        transAmount: '交易金额',
        operationKey: '操作键',
        operationKeyDes: '操作键简述',
        accountNumber: '帐号',
        currency: '货币',
      },
    },
    workStatement: {
      tabsLabel: {
        report: '工作报告',
        schedule: '我的日程',
      },
      workStatementAdd: {
        title: '新增日程',
        scheduleTitle: '日程标题',
        scheduleDate: '日程日期',
        timeRange: ' 起止时间',
        address: '地点',
        relatedCustomers: '关联客户',
        scheduleContent: '日程内容',
        confirmTitle: '提示',
        confirmEvent: '是否確認刪除該日程？',
        confirmUndone: '刪除操作無法撤銷。',
      },
      placeholder: {
        scheduleTitle: '请输入',
        scheduleDate: '日期选择器，到日',
        timeRange: ' 起止时间',
        startPlaceholder: '请选择开始时间，时间选择器',
        endPlaceholder: '请选择截至时间，精确到分',
        address: '请输入',
        relatedCustomers: '请输入',
        scheduleContent: '请输入',
      },
      rules: {
        scheduleTitle: '请填写日程标题',
        scheduleDate: '请选择日程日期',
        timeArr: ' 请选择起止时间',
        address: '请填写地点',
      },
      port: {
        success: '保存成功!',
        edit: '日程修改成功!',
        error: '保存失败!',
        del: '删除成功!',
        msg: '该日程已超过有效期！',
        warn: '结束时间小于当前时间，请重新选择',
      },
    },
    button: {
      other: '其他',
    },
    tips: {
      indexSuccess: '',
      indexError: '',
      indexWarning: '',
      kpiMore1: '经营仪表盘不能少于1个指标',
      kpiMore6: '经营仪表盘最多只能展示6个指标',
      kpiIndexDel: '成功移除指标',
      addSuccess: '操作成功',
    },
  },
  information: {
    button: {
      Search: '查询',
      Resetting: '重置',
      Add: '新增',
      Delete: '删除',
      Refresh: '刷新缓存',
      TpDownload: '模板下载',
      Import: '导入',
      Cancel: '取消',
      Determine: '确定',
      Update: '修改',
      Export: '导出',
    },
    title: {
      ultimateController: '集团名称',
      borrowerGrade: '信贷评级',
      loanClassification: '五级分类',
      income: '收入',
      asOfDate: '统计日期',
      dod: '较上日',
      mom: '较上月',
      momEnd: '较上月末',
      yoy: '较上年同期',
      laYeEn: '较上年末',
      loan: '贷款',
      deposit: '存款',
    },
    /* 
            mileston:'合作里程碑',
            business:'业务信息',
            management:'管理信息',
            attribute:'属性信息' */
    summary: {
      summary: '概要信息',
      basic: {
        basic: '基本信息',
        basicInformation: '基本信息',
        customerName: '客户名称 (英)',
        customerCName: '客户名称(中)',
        priPhNo: '主要电话号码',
        industry: '行业',
        dateOfEstablishment: '成立日期',
        businessAddress: '营业地址',
        countryOfOperation: '运营国家',
        officePhoneNumber: '办公室电话号码',
        mobile: '手机',
        email: '电子邮件',
      },
      document: {
        document: '证件',
        documentInfomation: '证件信息',
        documentIssuedCountry: '证件签发国家',
        documentType: '证件类型',
        documentID: '证件号',
        issueDate: '签发日期',
        expiryDate: '到期日期',
        mobile: '手机',
        email: '电子邮件',
      },
      income: {
        income: '收入',
        asOfDate: '统计日期',
        incomeYTD: '收入 (本年至今)',
        lastYearTotal: '去年总计',
        dod: '较上日',
        mom: '较上月',
        yoy: '较上年同期',
        incomeBreakdown: 'YTD收入构成',
        incomeLineChart: '月度收入趋势',
      },
      loans: {
        loans: '贷款',
        asOfDate: '统计日期',
        loanBalance: '贷款余额',
        lbatPreviousYearEnd: '上年末贷款余额',
        mothlyAverge: '月平均值',
        yearlyAverge: '年平均值',
        momEnd: '较上月末',
        laYeEn: '较上年末',
        loanBreakdown: '贷款构成',
        loanLineChart: '贷款月均趋势',
      },
      deposits: {
        deposits: '存款',
        asOfDate: '统计日期',
        depositBalance: '存款余额',
        dbatPreviousYearEnd: '上年末存款余额',
        mothlyAverge: '月平均值',
        yearlyAverge: '年平均值',
        momEnd: '较上月末',
        laYeEn: '较上年末',
        depositBreakdown: '存款构成',
        depositLineChart: '存款月均趋势',
      },
    },
    milestone: {
      milestone: '合作里程碑',
      dataRange: '数据范围',
      to: '至',
    },
    business: {
      business: '业务信息',
      income: '收入',
      loan: '贷款',
      deposit: '存款',
      productType: '产品类型',
      selectProductType: '请选择产品类型',
      calculationType: '计算类型',
      selectCalculationType: '请选择计算类型',
      dateRange: '日期范围',
      endDate: '结束时间',
      incomeDetails: '收入明细',
      incomeType: '收入类型',
      selincomeType: '请选择收入类型',
      seltransactionMonth: '请选择交易月',
      transactionMonth: '交易月',
      transactionMonthF: '交易月（从）',
      transactionMonthT: '交易月（到）',
      loanType: '贷款类型',
      selectLoanType: '请选择贷款类型',
      loanBalance: '贷款余额',
      selectLoanBalance: '请选择贷款余额',
      balance: '余额',
      selectBalance: '请选择余额',
      basicInfo: '基本信息',
      loanDetails: '贷款明细',
      depositDetails: '存款明细',
      product: '产品',
      periodEndBalanceM: '月累计金额 (HKE)',
      MonthlyAveBal: '年累计金额 (HKE)',
      YTDAveBal: '本年至今平均余额',
      netIntIncome: '净利息收入',
      nonNetIntIncome: '非净利息收入',
      department: '部门',
      rmName: 'RM名称',
      depName: '部门',
      loanNo: '贷款合同号',
      tradeRefNo: '交易号',
      transDate: '交易日期',
      currency: '币种',
      amount: '交易金额',
      exchangeRate: '汇率',
      AmountHKE: '交易金额 (HKE)',
      baseRate: '基本利率',
      effectiveRate: '有效利率',
      spread: '贷款利差',
      RWA: '风险加权资产',
      loanClassif: '五级分类',
      pastDueAmount: '上次到期金额',
      depositType: '存款类型',
      dpstAcctNo: '存款账号',
      accountNo: '账户编号',
      accountNum: '账户号码',
      accType: '账号类型',
      accountName: '账户名称',
      timeDepNo: '定期存款编号',
      minAmountHKE: '最小余额 (HKE)',
      maxAmountHke: '最大余额 (HKE)',
      avgAmountHke: '平均余额 (HKE)',
      interestRate: '利率  (%)',
      accountStatus: '账户状态',
      tenor: '存期',
      interestCurrent: '利息币种',
      regularInterest: '定期利息',
      startDate: '开始日期',
      maturityDate: '到期日期',
      callDpstIndt: '通知存款标志',
      particularInterest: '特定利率',
      openAccDt: '开户日期',
      closAccDt: '销户日期',
      accountOpenDate: '开户日期',
      callDepInd: '现金存款指标',
      matInstruction: '到期指示',
      greenDeposit: '绿色存款',
      preferRate: '优惠税率  (%)',
      bonusCASA: '奖金CASA',
      action: '操作',
      transactionDtails: '交易',
      creditUseAmt: '信用已用金额',
      creditAmt: '信用额度',
      expCreditAmt: '风险信用额度',
      creditBal: '信用剩余金额',
      incomeTypeCheck: '请先选择产品类型',
      operationDate: '操作日期',
      operationTime: '操作时间',
      transCurrency: '交易币种',
      transAmount: '交易金额',
      operationKey: '操作键',
      operationKeyShDe: '操作键简述',
    },
    management: {
      management: '管理',
      address: {
        address: '地址信息',
        registeredAddress: '注册地址',
        registAddrInfo: '注册地址信息',
        countryOfReg: '注册国家',
        operationAddrInfo: '运营地址信息',
        operationAddr: '运营地址',
        countryOfOpera: '运营国家',
      },
      ulmimateCtrl: {
        ulmimateCtrl: '集团',
        ulmimateCtrlInfo: '集团信息',
        ulCtrlNum: '集团号',
        ulCtrlName: '集团名称 (英)',
        ulCtrlNameCN: '集团名称 (中文)',
        GRMInfo: 'OneBank信息',
        oneBankReg: 'OneBank所属区域',
        GRMReg: 'GRM所属区域',
        GRMU: 'GRM归属',
        GRMContact: 'GRM',
        GRMContactSecond: '第二GRM',
        GLCenterCode: 'GL中心代码',
        LRMInfo: 'LRM信息',
        LRM: 'LRM',
      },
      RM: {
        RM: 'RM',
        rmInformation: 'RM信息',
        RMInfo: 'RM名称',
        department: '部门',
        teamHead: '团队负责人',
        sectionHead: '分部负责人',
        relationshipManager: '关系经理',
        rmCode: 'RM编码',
        rmSellerCode: '销售编码',
      },
    },
    attribute: {
      attribute: '资本信息',
      basicInfo: '基本信息',
      registCapital: '注册资本',
      paidInCapital: '实收资本',
      registAddress: '注册地址',
      registCurrency: '注册资本币种',
      paidInCurrency: '实收资本币种',
    },
  },
};
