export const formatDate = (val: string) => {
  if (val) {
    let monthArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const reg = /(\d{4})-(\d{1,2})-(\d{1,2})\s*.*/;
    // const r = val.replace(reg, `$3-$2-$1`)
    return val.replace(
        reg,
        function (date: string, y: string, m: string, d: string) {
          return `${d}-${monthArr[(m as unknown as number) - 1]}-${y}`;
        }
    );
  } else {
    return '-';
  }
};

export const formatDateTime = (val: string) => {
  if (val) {
    let monthArr = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const reg = /(\d{4})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2}):(\d{1,2})\s*.*/;
    // const r = val.replace(reg, `$3-$2-$1`)
    return val.replace(
      reg,
      function (date: string, y: string, m: string, d: string, hh: string, mm: string, ss: string) {
        return `${d}-${monthArr[(m as unknown as number) - 1]}-${y} ${hh}:${mm}:${ss}`;
      }
    );
  } else {
    return '-';
  }
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $formatDate: (date: string) => string;
  }
}
