export default {
  log: {
    // 日志管理
    logMgr: {
      logMgrTab: '日志管理',
      searchKey: '用户',
      skPlaceholder: '请输入用户编号或者用户名称',
      optTime: '操作时间',
    },
    // 检测统计
    checkDet: {
      checkDetTab: '检测统计',
      access: '访问统计量',
      assessDate: '统计日期',
      monthActiveAsses: '月活统计',
      assessMonth: '统计月份',
    },
    // 模块使用分析
    logAnalysis: {
      logAnalysisTab: '模块使用分析',
      largeModule: '模块',
    },
    tableColumns: {
      userId: 'LAN ID',
      userNameEn: '用户',
      department: '部门',
      section: '分部',
      team: '团队',
      orgName: '所属机构',
      operType: '操作类型',
      operDesc: '操作内容',
      operTime: '操作时间',
    },
    visitColumns: {
      beginDate: '起始时间',
      endDate: '截止时间',
      userCount: '用户访问量',
      visits: '访问次数',
      visitsAvg: '平均访问量',
    },
    monthColumns: {
      statMonth: '统计月份',
      normalUserCount: '正常用户数',
      monthActiveUserCount: '月活跃用户量',
      monthActiveRate: '月活跃用户率（%）',
    },
    elMessageTip: {
      assessDate: '请先选择统计日期',
      assessMonth: '请先选择统计月份',
      operDesc1: '用户进行',
      operDesc2: '操作',
    },
  },
};
