export default {
  user: {
    searchPlaceholder: '输入关键字进行搜索',
    userDialog: {
      userDialogTitle: '用户列表',
      userName: '用户姓名',
    },
    columns: {
      sellerCode: 'Seller Code',
      userName: '用户姓名(中)',
      userNameEN: '用户姓名(英)',
      loginName: '登录名',
      sex: '性别',
      orgName: '所属机构',
      roleName: '当前角色',
      orgLevelName: '所属机构',
      _roleName: '岗位',
      roleNames: '全部岗位',
      status: '用户状态',
      userId: '用户ID',
      password: '密码',
      tel: '手机号',
      idCardType: '证件类型',
      idCardNo: '证件号码',
      sortNo: '排序号',
      lawOrgId: '法人机构号',
      tellerNo: '柜员号',
      email: '邮箱',
      addr: '联系地址',
      roleIds: '全部角色',
      landline: '座机号码',
      remark: '备注',
      rmCode: 'RM Code',
      roleId: '岗位',
    },
    searchForm: {
      sellerCode: 'Seller Code',
      userName: '用户姓名',
      sex: '性别',
      status: '状态',
      roleIds: '拥有的角色',
      orgLevelName: '所属机构',
    },
    delMsg: '请选择需要删除的用户',
    editMsg: '修改用户状态成功！',
    user: '用户',
    resetPwTitle: '重置密码',
    newPw: '新密码',
    confirmPw: '确认密码',
    confirmMsg: '确认密码与新密码不一致，请重新输入',
    resetPwTip1: '是否确定重置用户',
    resetPwTip2: '密码',
    resetSuccess: '重置密码成功',
    rules: {
      userId: '请输入用户ID',
      sellerCode: '请输入 seller code',
      userNameEN1: '请输入用户英文名',
      userName1: '请输入用户姓名',
      userName2: '长度在100个字符以内',
      loginName1: '请输入登录名',
      sex: '请选择性别',
      status: '请选择用户状态',
      orgName: '请选择所属机构',
      tel1: '请输入手机号',
      tel2: '请输入正确的手机号',
      lawOrgId: '法人机构号不能为空',
      idCardNo1: '请输入身份证号码',
      idCardNo2: '请输入正确的身份证号码',
      idCardNoNum: '证件号码长度不能超过50',
      sortNo: '请输入排序号',
      roleId: '请选择岗位',
      roleIds: '请选择拥有的全部岗位',
      password: '请输入新密码',
      rePassword: '请输入确认密码',
      rmCode: '请选择 RM Code',
    },
    elMessageTip: {
      freeze: '是否确定冻结用户',
      unFreeze: '是否确定解冻用户',
      isIncludeRoleId: '岗位必须包含于全部岗位中',
      addSuc: '新增成功',
      updSuc: '修改成功',
      rmCode: '请先选择所属机构',
      sellerCode: '请先填写 seller Code',
      loginName: '请先填写登录名',
      userNameEN: '请先填写用户姓名',
      levelChange: '更改所属机构会先解绑 RM CODE',
    },
    // org-user-select的语言
    orgUserSelect: {
      dialogTitle: '选择用户',
      userId: '用户号',
      role: '用户角色',
      org: '所属机构',
      selectUserTip: '请选择一个用户',
      selectUserLimitTip: '只能选择一个用户',
    },
    authRm: '授权RM',
    unAuthRm: '取消授权RM',
    authARm: '授权ARM',
    unAuthARm: '取消授权ARM',
  },
};
