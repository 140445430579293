import { MutationTree } from 'vuex';
import { WebsocketState } from './state';

export enum WebsocketMutationType {
  SET_WS = 'SET_WS',
  SET_HEART_CHECK_TIMER = 'SET_HEART_CHECK_TIMER',
  SET_NOTIFY = 'SET_NOTIFY',
  SET_CASE_EXIST_MSG = 'SET_CASE_EXIST_MSG',
}

export type Mutations<S = WebsocketState> = {
  [WebsocketMutationType.SET_WS](state: S, ws: any): void;
  [WebsocketMutationType.SET_HEART_CHECK_TIMER](state: S, heartCheckTimer: any): void;
  [WebsocketMutationType.SET_NOTIFY](state: S, notifyFun: any): void;
};

export const mutations: MutationTree<WebsocketState> & Mutations = {
  [WebsocketMutationType.SET_WS](
    state: WebsocketState,
    ws: any
  ) {
    state.ws = ws;
  },
  [WebsocketMutationType.SET_HEART_CHECK_TIMER](
    state: WebsocketState,
    heartCheckTimer: any
  ) {
    state.heartCheckTimer = heartCheckTimer;
  },
  [WebsocketMutationType.SET_NOTIFY](
    state: WebsocketState,
    notifyFun: any
  ) {
    state.notifyFun = notifyFun;
  },
  [WebsocketMutationType.SET_CASE_EXIST_MSG](
    state: WebsocketState,
    caseExistMsg: any[]
  ) {
    state.caseExistMsg = caseExistMsg ?? [];
  },
  
};
