import { App } from 'vue';
import { createVNode, render } from 'vue';

export default {
  install(app: App) {
    if (!app.config.globalProperties.$zl) {
      app.config.globalProperties.$zd = {};
    }
    const globalOptions = app.config.globalProperties;
    console.log(globalOptions,'app.config.globalProperties----globalOptions');
    let Dialog = {
      show(component: any, args: any) {
        console.log(component, 'component', args, 'args');
        return new Promise((resolve) => {
          // const Dialog = Vue.extend(component);

          // var $vm = new Dialog({
          //   el: document.createElement('div'),
          //   propsData: args || {},
          // });
          // var node = document.body.appendChild($vm.$el);

          const container = document.createElement('div');
          const vnode = createVNode(component);
          render(vnode, container);
          const instance = vnode.component;
          //将第三方或者其他全局属性方法注入当前组件实例上
          (instance as any).appContext.config.globalProperties = {
            ...globalOptions,
          };
          console.log(instance, 'instance--------------');
          document.body.appendChild(container);

          instance?.exposed?.open(args).then(
            (result: any) => {
              if (resolve) {
                resolve(result);
              }
              setTimeout(() => {
                document.body.removeChild(container);
              }, 300);
            },
            () => {
              setTimeout(() => {
                document.body.removeChild(container);
              }, 300);
            }
          );
        });
      },
    };
    app.config.globalProperties.$zd.dialog = Dialog;
  },
};

// export { Dialog };
