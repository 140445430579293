import {store} from "@/store";
import {AppActionType} from "@/store/modules/app/action-types";

const TOKEN = 'Authorization';
export const getToken = async () => {
    let token = sessionStorage.getItem(TOKEN);
    if (token) {
        return token;
    }
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name.trim() === 'token') {
            sessionStorage.setItem(TOKEN, value);
            //保存码值
            await store.dispatch(
                `app/${AppActionType.ACTION_SET_CODELIST}`,
                {},
                { root: true }
            );
            return value;
        }
    }
}
export const setToken = (token: string) => {
    sessionStorage.setItem(TOKEN, token);
    document.cookie = `token=${token}; path=/; SameSite=None; Secure`;
}
export const removeToken = () => sessionStorage.removeItem(TOKEN);
