export default {
  make: {
    // 经营计划 -- 创建计划
    createPlan: {
      createPlanTitle: '计划基本信息',
      planName: '计划名称',
      planNamePlaceholder: '请输入计划名称',
      cycleType: '周期类型',
      cycleTypePlaceholder: '请选择周期类型',
      cycleVal: '计划周期',
      cycleValPlaceholder: '请选择计划周期',
      nextBtnText: '下一步',
      closeBtnText: '取 消',
      confirmText: '已保存计划基本信息',
    },
    // 经营计划 -- 创建与修改计划
    edit: {
      totalTarget: '总任务目标',
      cycleTarget: '周期任务目标',
      errorMessage: '计划类型不存在,请联系管理人员',
      confirmMessage: '确认是否关闭，未保存的已修改信息将丢失！',
      confirmTitle: '提示',
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      cancleInfoText: '取消关闭',
      objInitValue: '上一计划阶段完成值',
      taskVal: '目标值',
      taskVal2: '设定目标值',
      firstQtr: '第1季度',
      secondQtr: '第2季度',
      thirdQtr: '第3季度',
      fourthQtr: '第4季度',
      total: '合计',
      firstMth: '第1个月',
      secondMth: '第2个月',
      thirdMth: '第3个月',
      fourthMth: '第4个月',
      fifthMth: '第5个月',
      sixthMth: '第6个月',
      seventhMth: '第7个月',
      eighthMth: '第8个月',
      ninthMth: '第9个月',
      tenthMth: '第10个月',
      eleventhMth: '第11个月',
      twelfthMth: '第12个月',
      indexName: '指标名称',
      unit: '指标单位',
      indexNumProp: '指标属性',
      initVal: '上一计划阶段完成值',
      taskVal1: '目标值',
      parentTaskVal: '上级目标值',
      action: '操作',
      check: '校 验',
      distributedTask: '分配任务',
      remove: '移 除',
      addIndex: '添加指标',
      objType: '任务对象类型',
      auto: '自动填充',
      objTypePlaceholder: '请选择任务对象',
      addTaskObj: '添加任务对象',
      index: '指标',
      objName: '对象名称',
      save: '暂 存',
      close: '关 闭',
      submit: '提 交',
      errorMessage1: '计划类型错误,请联系运维人员',
      approval: '审批',
      submitTitle: '提交成功！',
      saveTitle: '保存成功！',
      removeSucc: '移除成功',
      removeMessage: '确认移除该任务对象？',
      cancelledTitle: '已取消',
      addMessage: '请选择任务对象类型',
      addTitle: '该指标已在列表中！',
      confirmRemoveText: '确定移除指标',
      msg1: '设定指标目标值小于上级目标值!',
      msg2: '当前指标存在目标值未拆分！',
      checkSuccess: '校验通过',
      customizeTitle: '自定义监测指标',
      selectIndex: '选择指标',
      selectIndexPlaceholder: '请输入指标名称',
      allSel: '全部勾选',
      allCancleSel: '全部取消勾选',
      realTitle: '确定删除',
      replenish: '中选中的所有指标?',
      title: '确定设置所选指标？',
      indexSel: '指标已选择',
      indexCanSel: '已取消选择',
      orgSel: '选择机构',
      orgSelPlaceholder: '请输入关键字进行搜索',
      useSel: '选择用户',
      userId: '用户号',
      userName: '用户名称',
      roleName: '用户角色',
      orgId: '所属职能部门',
      orgId1: '所属机构',
      search: '查询',
      selObjName: '对象',
      qtrRadio1: '按数值',
      qtrRadio2: '按比例',
      cycleName: '季度',
      num: '数值',
      taskPercent: '比例',
      cycleName1: '月份',
      previous: '上一步',
      message1: '校验失败,当前计划类型错误,请联系管理员.',
      message2: '操作失败！所选任务不存在周期任务',
      message3: '存在未拆解值,当前季度拆解总和不等于目标值!',
      message4: '存在未拆解值,当前月度拆解总和不等于目标值',
      message5: '第1季度拆解值不等于第1、2、3月度拆分总值',
      message6: '第2季度拆解值不等于第4、5、6月度拆分总值',
      message7: '第3季度拆解值不等于第7、8、9月度拆分总值',
      message8: '第4季度拆解值不等于第10、11、12月度拆分总值',
      message9: '第4季度未达到目标值',
      message10: '第12月未达到目标值',
      message11: '第12月拆解值不等于第4季度拆解值',
      message12: '第9月拆解值不等于第3季度拆解值',
      message13: '第6月拆解值不等于第2季度拆解值',
      message14: '第3月拆解值不等于第1季度拆解值',
      message15: '校验失败,当前指标属性错误,请联系管理员.',
      message16: '存在未拆解值,当前月度拆解总和不等于目标值',
      message17: '月度末拆解值未达到目标值',
      message18: '校验通过',
      message19: '只能选择10条',
      indexText: '指标数据',
      objText: '对象数据',
      monthText: '月份数据',
      dayText: '天数据',
      quarterText: '季度数据',
    },
    // 经营计划 -- 列表三语表格
    tableColumn: {
      fixedPlanId: '计划Id',
      planName: '计划名称',
      cycleType: '周期类型',
      cycleVal: '计划周期',
      createDate: '创建时间',
      createOrgName: '创建部门',
      createUserName: '创建人',
      planSts: '状态',
    },

    addBtn: '创建计划',
    searchForm: {
      cycleType: '周期类型',
      cycleVal: '计划周期',
      createDate: '创建时间',
      planName: '计划名称',
      planSts: '状态',
      cycleTypePlaceholder: '请选择周期类型',
      cycleValPlaceholder: '请选择计划周期',
      createDatePlaceholder: '请选择日期',
      planNamePlaceholder: '请输入计划名称',
      planStsPlaceholder: '请选择计划状态',
    },
    // 经营计划 -- 承接计划分解
    assimt: {
      undertakeTitle: '承接计划基本信息',
      planName: '计划名称',
      startDate: '开始日期',
      endDate: '结束日期',
      planDesc: '计划描述',
    },
    // 经营计划 -- 查看弹窗三语表格
    view: {
      basicSetp: '基本信息',
      targetSetp: '目标',
      planName: '计划名称',
      cycleType: '周期类型',
      cycleVal: '计划周期',

      campaign: '计划指标',
      overall: '总目标',
      quarterly: '季度目标',
      monthly: '月度目标',
      daily: '日目标',
    },
  },
  // 经营承接
  assimt: {
    // 经营计划承接 -- 列表三语表格
    tableColumn: {
      parentPlanName: '计划名称', // 上一级计划名称
      publisher: '发布人',
      status: '状态',
      planName: '计划承接名称',
      assimtDate: '承接日期',
      receiveDate: '接收日期',
      planSts: '承接状态',
    },
  },
  action: {
    action: '操作',
    actionBtn: {
      view: '查看',
      edit: '修改',
      delete: '删除',
      submit: '提交',
      publish: '发布',
      adjust: '检视调整',
      unadjust: '取消检视调整',
      withdraw: '撤回',
      approvalDetails: '审批详情',
      distribute: '承接',
    },
  },
  elMessageTip: {
    delete: '确认删除该记录吗？',
    submit: '确认提交审批计划吗？',
    submitSuc: '提交成功',
    publish: '确认发布计划？',
    publishSuc: '发布成功',
    adjust: '确认检视调整计划？',
    adjustSuc: '计划已进入检视调整状态',
    withdraw: '确认撤回计划审批申请？',
    withdrawSuc: '撤回成功',
    unadjust: '确认取消检视调整计划？',
    unadjustSuc: '操作成功',
    assimt: '确认承接审批计划吗？',
  },
};
