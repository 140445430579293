export default {
  prompt: {
    promptId: '提示語ID',
    promptDesc: '提示語描述',
    Remark: '備註',
    number: '序號',
    promptDescCN: '提示語描述 (簡中)',
    promptDescTC: '提示語描述 (繁中)',
    promptDescEN: '提示語描述 (英文)',
    creator: '創建人',
    creatTime: '創建時間',
    updater: '修改人',
    updateTime: '修改時間',
    promptDelete1:'確定要刪除錯誤碼為',
    promptDelete2:'的提示語嗎？'
  },
};
