export default {
  tasks: {
    tabs1: 'My Received Tasks',
    tabs2: 'My Created Tasks',
    form: {
      noticeTitle: 'Task Name',
      tasksDt1: 'Received Date',
      tasksDt2: 'Assigned Date',
      planfinishDt: 'Task End Date',
      importanceType: 'Priority',
      noticeType: 'Status',
      launch: 'Created By',
      received: 'Assigned To',
    },
    confirmMessage:
      'Feedback can no longer be performed after the task is completed',
    warnMsg: 'Please select a piece of data first',
    editSucc: 'The feedback was successful',
    addSucc: 'The new is successful',
    selTitle1: 'Assign tasks to an assignees',
    selTitle2: 'Please select an executor',
    selTitle3: 'An executor has been selected',
    sel: 'Select',
    warnMsg1: 'Please select an employee',
    clearBtn: 'Clear All',
    taskDetail: 'View',
    taskFeedback: 'Feedback',
    taskFeedbackRecording: 'View Feedback History',
    tableBtn: {
      finishTitle: 'Task Completed',
      addTask: 'New',
      detailTitle: 'View',
      feedback: 'Feedback',
      lookFeedback: 'View Feedback History',
      publish: 'Publish',
    },
    tableColumns: {
      noticeTitle: 'Task Name',
      noticeType: 'Status',
      importanceType: 'Priority',
      taskCycle: 'Task Duration',
      feedbackDt: 'Last Feedback Date',
      nextfeedbackDt: 'Next Feedback Date',
      launch: 'Created By',
      launchOrg: 'Creator Position',
      received: 'Assigned To',
      receivedOrg: 'Assignee Position',
      taskCompleteTime: 'Actual Completion Date',
    },
    historyColumns: {
      feedbackDetail: 'Feedback Details',
      feedbackDt: 'Feedback Date',
      feedbackPel: 'Created By',
    },
    formDialog: {
      taskTitle: 'Task Name',
      taskDetail: 'Task Detail',
      taskRequest: 'Requirement',
      file: 'Attachments',
      uploadfile: 'Upload Attachments',
      taskFeedback: 'Task Feedback',
      taskObjType: 'Select Assignment',
      taskObj: 'Task Assignment',
    },
    rules: {
      rulesFeedbackDetail: 'Please enter feedback on the task',
      rulesnextFeedbackDate: 'Please enter the next scheduled feedback time',
      rulestaskName: 'Please enter a task title',
      rulescreateDate: 'Please enter the task period',
      rulestaskLevel: 'Please select the level of importance',
      rulestaskDetail: 'Please enter the task details',
      rulestaskRequest: 'Please enter the task requirements',
      rulestaskObjType: 'Please select the task object type',
      rulestaskObj: 'Please select the target of the task',
    },
  },
};
