import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vertical-menu", {
      'is-collapse': $setup.props.isCollapse
    }])
  }, [_withDirectives(_createVNode(_component_el_scrollbar, {
    height: "100%"
  }, {
    default: _withCtx(() => [!$setup.getAuthMenuList.length ? (_openBlock(), _createBlock(_component_el_empty, {
      key: 0,
      description: "暂无可访问的菜单权限"
    })) : (_openBlock(), _createBlock(_component_el_menu, {
      key: 1,
      "default-active": $setup.currentRoute.path,
      collapse: $setup.props.isCollapse,
      "collapse-transition": false,
      "unique-opened": true
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getAuthMenuList, item => {
        return _openBlock(), _createBlock($setup["MenuItem"], {
          key: item.path,
          item: item
        }, null, 8 /* PROPS */, ["item"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["default-active", "collapse"]))]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), [[_vShow, $setup.showMenu]])], 2 /* CLASS */);
}