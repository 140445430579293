import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock($setup["ElDialog"], {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.visible = $event),
    title: $props.title,
    width: "800",
    onClose: $setup.handleClose
  }, {
    header: _withCtx(() => [_renderSlot(_ctx.$slots, "header", {}, () => [_createTextVNode("Test-dialog")])]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "footer", {}, () => [_createVNode(_component_el_button, {
      type: "info",
      onClick: $setup.cancel
    }, {
      default: _withCtx(() => [_createTextVNode("Cancel")]),
      _: 1 /* STABLE */
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $setup.confirm
    }, {
      default: _withCtx(() => [_createTextVNode("Confirm")]),
      _: 1 /* STABLE */
    })])])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "content")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "title"]);
}