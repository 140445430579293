export default {
  remind: {
    alertName: 'Alert Name',
    remindDate: 'Received Date',
    readStatus: 'Status',
    custName: 'Customer Name',
    custId: 'Customer Number',
    dialogTitle: 'Event Reminder Details',
    markReadBtn: 'Mark as Read',
    // 弹窗head text
    accountNumber: 'Account Number',
    currency: 'Currency',
    // 大额客户、大额提现客户
    largeAmountOne: 'As of',
    largeAmountTwo: 'the deposit balance of',
    largeAmountThree: 'Customer No. ',
    largeAmountFour: 'increased by',
    largeAmountFive: 'decreased by',
    largeAmountSix: '(HKD equivalent)',
    // 定期七天內到期
    dueSeventOne: 'The Time Deposits of',
    dueSeventTwo: 'Account No. ',
    dueSeventSix: 'in HKD',
    dueSeventThree: 'will be matured in 7 days',
    dueSeventFour: 'Link',
    dueSeventFive: 'Dashboard - Time Deposit >> Time Deposit to be Matured in 7 Days',
    // 逾期未付利息的客户、逾期贷款客户
    dueOne: 'Customer No. ',
    dueTwo: 'with overdue interest record(s)',
    dueThree: 'with past due loan record(s)',
    // 贷款O/S将在7天内到期的客户
    loanOSOne: 'Please be informed that',
    loanOSTwo: 'Customer No. ',
    loanOSThree: 'with loan due record(s)',
    // 信用审查提醒
    creditOne: 'The following credit reviews under your RM code will be due in the coming three months',
    // 弹窗表格
    transDate: 'Transaction Date',
    transCurrency: 'Transaction Currency',
    transAmountOrigin: 'Transaction Amount (Original Currency)',
    transAmountHke: 'Transaction Amount (HKE)',
    transRate: 'Exchange Rate',
    alertDate: 'Alert Date',
    loanNo: 'Loan No. ',
    loanReferenceNo: 'Loan Reference No. ',
    currencyOD: 'OD Currency',
    amountOD: 'OD Amount',
    currencyOS: 'O/S Currency',
    amountOS: 'O/S Amount',
    overdueDays: 'No. of Days',
    accountNo: 'Account No. ',
    accountName: 'Account Name',
    nexrReviewDate: 'Next Review Date',
    // 消息提示
    tipTitle: 'Prompt',
    tipOne: 'Please select a data at least',
    markReadTip: 'Are you sure to mark them as read?',
    actionCancel: 'Operation cancelled',
    //列表提醒内容
    remindContentOne: 'Your company\'s users: ',
    remindContentTwo: ' experienced ',
    remindContentThree: ' on ',
    remindContentFour: ', with an amount of ',
    remindContentFive: '. Please follow up as soon as possible!',
  }
}
