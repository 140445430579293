import reports from './reports/us'
import tasks from './tasks/us';
import notice from './notice/us';
import message from './message/us';
import approval from './approval/us'
export default {
  ...tasks,
  ...reports,
  ...notice,
  ...message,
  ...approval,
};
