export default {
  wf: {
    columns: {
      templateName: '模板名称',
      templateType: '模板类型',
      orgName: '所属机构',
      sortNo: '排序号',
      remark: '备注',
    },
    processColumns: {
      nodeName: '节点名称',
      nodeType: '节点类型',
      teamFilter: '分派过滤器',
      teamName: '分派审批组',
      handleMode: '处理模式',
    },
    setRouteColumns: {
      nextNodeName: '下一节点',
      routeCondition: '路由条件',
    },
    approvalColumns: {
      teamName: '审批组名称',
      userId: '用户ID',
      userName: '用户姓名',
    },
    process: '流程定义',
    dialog: {
      viewUrl: '查看Url',
      setRoute: '设置路由',
      addNode: '新增节点',
      editNode: '修改节点',
    },
    elMessageTip: {
      delSelectOne: '请选择一条记录',
      delTip: '确认删除所选择的记录吗',
      copyTip: '确认复制所选模板',
      opSuc: '操作成功',
      delNodeTip: '请选择要删除的节点',
      delNodeSure: '确定删除所选节点',
      tip1: '未匹配到表单操作类型',
    },
  },
};
