import navBarCN from '../components/navBar/cn';
import custMgtCn from '../components/custMgt/cn';
import mainCN from '../components/main/cn';
import groupCN from '../components/group/cn';
import sysCn from '../components/sys/cn';
import cbmCn from '../components/cbm/cn';
import perferCn from '../components/pfAnalyse/cn';
import cipCn from '../components/cip/cn';
import perforReviewCn from '../components/cip/performanceReview/cn';
import routeCn from '../components/route/cn';
import dashboardCn from '../components/dashboard/cn';

export default {
  navBar: { ...navBarCN },
  // 工作平台
  dashboard: { ...dashboardCn },
  main: { ...mainCN },
  //业绩管理
  pfAnalyse: { ...perferCn },
  // 表格序号
  tableIndexNm: '序号',
  ...groupCN,
  // 经营计划
  cbm: { ...cbmCn },
  // 指标管理
  cip: { ...cipCn },
  // 客户管理
  custMgt: { ...custMgtCn },
  //系统管理
  sys: { ...sysCn },
  // 业绩查看
  ...perforReviewCn,
  // 语言切换
  message: {
    language: '简体中文',
  },
  // 面包屑
  breadcrumbs: {
    menuTitle: '首页', // 首页
  },
  // 接口报错提示
  errorMsg: {
    msg1: '未知错误，请联系管理员',
    msg2: '后端接口连接异常',
    msg3: '系统接口请求超时',
    msg4: '系统接口',
    msg5: '异常',
    noData: '暂无数据',
  },
  // 查询按钮
  searchBtn: {
    searchLabel: '查询',
    resetLabel: '重置',
    collapseLabel: '收起',
    expandLabel: '展开',
  },
  // 弹窗按钮
  dialogBtn: {
    cancelLabel: '取消',
    saveLabel: '保存',
    confirmLabel: '确定',
    resetLabel: '重置',
    closeLabel: '关闭',
    submitLabel: '提交',
  },
  // load提示语
  load: {
    loading: '正在请求数据...',
  },
  // 表格操作按钮
  tableBtns: {
    addLabel: '新增',
    newLabel: '新增',
    delLabel: '删除',
    modLabel: '修改',
    editLabel: '修改',
    copyLabel: '复制',
    viewLabel: '查看',
    resetPWLabel: '重置密码',
    refreshLabel: '刷新',
    opt: '操作',
    export: '导出',
    exportDetail: '导出明细',
    action: '操作',
    download: '下载',
    upload: '上传文件',
    freeze: '冻结',
    unFreeze: '解冻',
    submit: '提交',
    expandAll: '全部展开',
    noExpandAll: '全部收起',
    annex: '附件',
  },
  // 表格操作提示
  tableOptTips: {
    rowSelectTips: '请至少选择一条数据',
    adaddSuccess: '新增成功！',
    updateSuccess: '修改成功！',
    saveSuccess: '保存成功！',
    submitSuccess: '提交成功！',
    promptTitleTips: '提示',
  },
  // 文件上传
  upload: {
    supType: '支持文件类型',
    maxSize: '每个文件最大',
    maxN: '最多',
    files: '个文件',
    clientFileName: '文件名',
    uploadTime: '上传时间',
    delSucc: '删除成功',
    delFail: '删除失败',
    msg1: '请上传文件名后缀名为',
    msg2: '的附件',
    msg3: '上传文件大小超过',
    msg4: '一次最多只能上传',
    msg4_1: '最多只能上传',
    msg5: '个文件',
    msg6: '文件上传失败，请注意文件内容是否为空!',
  },
  loginForm: {
    title: '欢迎来到 OKP !',
    loginName: {
      label: 'LAN ID',
      placeholder: 'LAN ID',
      rules: '请输入用户名',
    },
    password: {
      label: '密码',
      placeholder: '密码',
      rules: '请输入密码',
    },
    rememberMe: '记住我',
    loginLabel: '登 录',
    forgotPassword: '忘记密码'
  },
  // 表单输入框提示
  formPlaceholder: {
    input: '请输入',
    select: '请选择',
    searchPlaceholder: '输入关键字进行搜索',
    textAreaInput: '在此处键入...',
  },
  // 日期范围输入框提示
  dateRangeholder: {
    pickaDt: '请选择一个月份',
    dateTo: '至',
    startMonth: '开始月份',
    endMonth: '结束月份',
    startDate: '开始日期',
    endDate: '结束日期',
  },
  // 时间相关三语配置
  time: {
    year: '年',
    hy1: '上半年',
    hy2: '下半年',
    q1: '第1季度',
    q2: '第2季度',
    q3: '第3季度',
    q4: '第4季度',
    m1: '01月',
    m2: '02月',
    m3: '03月',
    m4: '04月',
    m5: '05月',
    m6: '06月',
    m7: '07月',
    m8: '08月',
    m9: '09月',
    m10: '10月',
    m11: '11月',
    m12: '12月',
    m: '月',
  },
  route: { ...routeCn },
};
