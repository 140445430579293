export default {
  tableColumns: {
    custEname: {
      label: '客户名称 (英)',
    },
    custName: {
      label: '客户名称 (中)',
    },
    Action: {
      label: '操作',
    },
    rowAction: {
      label: '查看',
    },
  },
  groupListTableColumns: {
    Action: {
      label: '操作',
    },
    rowAction: {
      label: '查看',
    },
    ultmCtrlrId: {
      label: '集团号',
    },
    ultmCtrlrNameEn: {
      label: '集团名称(英)',
    },
    ultmCtrlrNameCh: {
      label: '集团名称(中)',
    },
    grmDep: {
      label: '部门',
    },
    ultmCtrlrGrmEname: {
      label: 'GRM',
    },
  },
  searchForm: {
    custName: {
      label: '客户名称',
    },
    custType: {
      label: '客户类型',
    },
    industryCode: {
      label: '行业',
    },
    custId: {
      label: '客户号',
    },
    certType: {
      label: '证件类型',
    },
    rmDepName: {
      label: '部门',
    },
    certCode: {
      label: '证件号',
    },
    sectionHead: {
      label: '分部负责人',
    },
    teamHead: {
      label: '团队负责人',
    },
    rmCode: {
      label: 'RM',
    },
  },
  groupListSearchForm: {
    ultmCtrlrId: {
      label: '集团号',
    },
    ultmCtrlrName: {
      label: '集团名称',
    },
    grmDep: {
      label: '部门',
    },
    custId: {
      label: '客户号',
    },
    custName: {
      label: '客户名称',
    },
  },
  custInfoDtl: {
    dialogTitle: '客户信息详情',
    relation: {
      cardTitle: '关联信息',
    },
    basic: {
      cardTitle: '基本信息',
      basicInformation: {
        cardTitle: '基本信息',
        custId: {
          label: '客户号',
        },
        custEname: {
          label: '客户名称 (英)',
        },
        custName: {
          label: '客户名称 (中)',
        },
        custType: {
          label: '客户类型',
        },
        industry: {
          label: '行业',
        },
      },
      operationInformation: {
        cardTitle: '公司信息',
        certRegisAddrCountry: {
          label: '注册国家/地区',
        },
        certRegisAddr: {
          label: '注册地址',
        },
        busAddrCountry: {
          label: '运营国家/地区',
        },
        busRegisAddr: {
          label: '公司地址',
        },
        foundDt: {
          label: '成立日期',
        },
        empCnt: {
          label: '员工人数',
        },
        regisCcy: {
          label: '注册货币',
        },
        regisCapit: {
          label: '注册资本',
        },
        padCcy: {
          label: '实收资本货币',
        },
        paidCapit: {
          label: '实收资本',
        },
        isList: {
          label: '已上市',
        },
        fixTel: {
          label: '办公室电话号码',
        },
        fixTelAddrContry: {
          label: '办公室电话区号',
        },
        phAddrContry: {
          label: '手机区号',
        },
        fixTelNo: {
          label: '办公室号码',
        },
        phoneNo: {
          label: '手机号码',
        },
        fixTelNoExt: {
          label: '办公室分机号',
        },
        primaryPhoneNo: {
          label: '首要电话号码',
        },
      },
      classInfo: {
        cardTitle: '股东信息',
        entitySizeHkmaCode: {
          label: '实体规模',
        },
        holder: {
          label: '股东',
        },
        holdRat: {
          label: '股东持股比例 (%)',
        },
      },
      rmInformation: {
        cardTitle: 'RM 信息',
        rmCode: {
          label: 'RM编码',
        },
        rmName: {
          label: 'RM名称',
        },
        rmSellerCode: {
          label: '销售编码',
        },
        rmDepName: {
          label: '部门',
        },
        belgSectionName: {
          label: '分部负责人',
        },
        belgTeamName: {
          label: '团队负责人',
        },
      },
    },
    doc: {
      cardTitle: '证件信息',
      tableColumns: {
        certCountry: {
          label: '证件签发国家/地区',
        },
        certType: {
          label: '证件类型',
        },
        certCode: {
          label: '证件号',
        },
        certBegDate: {
          label: '证件签发日期',
        },
        certValidDate: {
          label: '证件到期日',
        },
        remark: {
          label: '备注',
        },
      },
    },
    ultCon: {
      cardTitle: '集团信息',
      tableColumns: {
        ultmCtrlrId: {
          label: '集团号',
        },
        ultmCtrlrNameEn: {
          label: '集团名称 (英)',
        },
        ultmCtrlrNameCh: {
          label: '集团名称 (中)',
        },
        ultmCtrlrGrmEname: {
          label: 'GRM',
        },
        grmDep: {
          label: '部门',
        },
      },
    },
    contacts: {
      cardTitle: '联系信息',
      tableColumns: {
        contactName: {
          label: '联系人姓名',
        },
        title: {
          label: '联系人称谓',
        },
        contactTelNo: {
          label: '联系公司电话',
        },
        contactPhoneNo: {
          label: '手机号码',
        },
        email: {
          label: '邮箱',
        },
      },
    },
    shareholder: {
      cardTitle: '股东信息',
      tableColumns: {
        relCustId: {
          label: '客户号',
        },
        relCustEname: {
          label: '客户名称 (英)',
        },
        relCustName: {
          label: '客户名称 (中)',
        },
        isMgr: {
          label: '是否关联人',
        },
        invPerc: {
          label: '占股比例 (%)',
        },
      },
    },
    reCompany: {
      cardTitle: '关联企业信息',
      tableColumns: {
        mangCustId: {
          label: '关联企业客户号',
        },
        mangCustEname: {
          label: '关联企业客户名称 (英)',
        },
        mangCustName: {
          label: '关联企业客户名称 (中)',
        },
        relType: {
          label: '关系类型',
        },
      },
    },
    reMgt: {
      cardTitle: '关联管理信息',
      tableColumns: {
        mngCustId: {
          label: '客户号',
        },
        mngCustEname: {
          label: '客户名称 (英)',
        },
        mngCustNameCh: {
          label: '客户名称 (中)',
        },
        relType: {
          label: '管理类型',
        },
        isShareholder: {
          label: '是否股东',
        },
      },
    },
  },
  groupInfo: {
    dialogTitle: '集团客户详情',
    basic: {
      cardTable: '基本',
      cardTitle: '基本信息',
      ultmCtrlrId: {
        label: '集团号',
      },
      ultmCtrlrNameEn: {
        label: '集团名称(英)',
      },
      ultmCtrlrNameCh: {
        label: '集团名称(中)',
      },
      onebankRegion: {
        label: 'OneBank 所属区域',
      },
      grmRegion: {
        label: 'GRM 所属区域',
      },
      ultmCtrlrGrmUnit: {
        label: 'GRM 归属',
      },
      grmcontact: {
        label: 'GRM',
      },
      grm2ndContact: {
        label: '第二 GRM',
      },
      ultmCtrlrLrmEname: {
        label: 'LRM',
      },
    },
    subsidiaries: {
      cardTable: '子公司',
      cardTitle: '子公司',
      tableColumns: {
        custId: {
          label: '客户号',
        },
        custEname: {
          label: '客户名称(英)',
        },
        custName: {
          label: '客户名称(中)',
        },
        certType: {
          label: '证件类型',
        },
        certCode: {
          label: '证件号',
        },
        rmName: {
          label: 'RM',
        },
      },
    },
  },
  elMessageTip: {
    certType: '请先选择证件类型',
    department: '请选择部门',
    sectionHead: '请选择分部负责人',
    teamHead: '请选择团队负责人',
    selectOneUser: '请至少选择一名用户',
  },
  attention: {
    tabs1: '公司客户',
    tabs2: '集团客户',
    editSucc: '取消关注成功',
    confirmText: '是否取消关注该客户？',
    tableColumns: {
      grmUnit: 'GRM Unit',
      grmContact: '集团客户经理',
      numOfSub: '子公司数量',
    },
  },
};
