export default {
  prompt: {
    promptId: '提示语ID',
    promptDesc: '提示语描述',
    Remark: '备注',
    number: '序号',
    promptDescCN: '提示语描述 (简中)',
    promptDescTC: '提示语描述 (繁中)',
    promptDescEN: '提示语描述 (英文)',
    creator: '创建人',
    creatTime: '创建时间',
    updater: '修改人',
    updateTime: '修改时间',
    promptDelete1:'确定要删除错误码为',
    promptDelete2:'的提示语吗？'
  },
};
