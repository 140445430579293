import org from './org/hk';
import user from './user/hk';
import role from './role/hk';
import log from './log/hk'
import bireport from './bi-report/hk'
import prompt from './prompt/hk'
import wf from './wf/hk'
export default {
  ...org,
  ...user,
  ...role,
  ...log,
  ...prompt,
  ...bireport,
  ...wf,
  CodeType: {
    Button: {
      Search: '查詢',
      Resetting: '重置',
      Add: '新增',
      Delete: '刪除',
      Refresh: '刷新緩存',
      TpDownload: '模板下載',
      Import: '導入',
      Cancel: '取消',
      Determine: '確定',
      Update: '修改',
    },
    Search: {
      CodeTypeID: '代碼類別ID:',
      CodeTypeName: '代碼類別名称:',
      Notes: '備註:',
    },

    Form: {
      CodeTypeID: '代碼類別ID:',
      CodeTypeNameCN: '代碼類別名称（简体）:',
      CodeTypeNameTC: '代碼類別名称（繁体）:',
      CodeTypeNameEN: '代碼類別名称（英文）:',
      SortNumber: '排序號:',
      Notes: '備註:',
      Operate: '操作',
      Update: '修改',
      CodeValue: '碼值',
      importInfo: '請選擇通過模板修改的XLSX文件',
      importWarning: '請上傳文件後綴名為xls、xlsx的附件',
      importError: '上传文件大小超过',
    },
    Add: {
      CodeTypeAddTitle: '新增代碼類別',
    },
    Delete: {
      CodeTypeDelete1: '確認刪除代碼id為',
      CodeTypeDelete2: '的代碼類別嗎？',
      Prompt: '提示',
      hasCancel: '已取消',
      errDelete: '請選擇需要刪除的項',
      delMsg1: '確認刪除選中的',
      delMsg2: '條代碼信息嗎？',

      deleteDefault: '確認刪除這些項嗎？',
      delSuc: '刪除成功',
      delCancel: '已取消刪除',
    },
    Update: {
      UpdateTitle: '修改代碼類別',
    },
    CodeValue: {
      CodeValueTitle1: '編輯',
      CodeValueTitle2: '代碼信息',
      addCodeValueTitle1: '新增',
      addCodeValueTitle2: '碼值',
      dropDownText: '下拉框內容：',
      dropDownTextCN: '下拉框內容（簡中）：',
      dropDownTextTC: '下拉框內容（繁中）：',
      dropDownTextEN: '下拉框內容（英文）：',
      dropDownValue: '下拉框值：',
      slDropdownValue: '上級聯動下拉框值：',
      SortNumber: '排序號:',
      Notes: '備註:',
      pleaseEnter: '請輸入！',
      pleaseEnterContent: '請輸入下拉框內容！',
      pleaseEnterValue: '請輸入下拉框值！',
      pleaseEnterSort: '請輸入排序號！',
    },
  },
};
