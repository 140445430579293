export default {
  remind: {
    alertName: '警报名称',
    remindDate: '接收的日期',
    readStatus: '状态',
    custName: '客户名称',
    custId: '客户编号',
    dialogTitle: '事件提醒详情',
    markReadBtn: '标注已读',
    // 弹窗head text
    accountNumber: '账号',
    currency: '货币',
    // 大额客户、大额提现客户
    largeAmountOne: 'As of',
    largeAmountTwo: 'The deposit balance of',
    largeAmountThree: 'Customer No. ',
    largeAmountFour: 'increased by',
    largeAmountFive: 'decreased by',
    largeAmountSix: 'HKD',
    // 定期七天內到期
    dueSeventOne: 'The Time Deposits of',
    dueSeventTwo: 'Account No. ',
    dueSeventSix: 'in HKD',
    dueSeventThree: 'will be matured in 7 days',
    dueSeventFour: 'Link',
    dueSeventFive: 'Dashboard - Time Deposit >> Time Deposit to be Matured in 7 Days',
    // 逾期未付利息的客户、逾期贷款客户
    dueOne: 'Customer No. ',
    dueTwo: 'with overdue interest record(s)',
    dueThree: 'with past due loan record(s)',
    // 贷款O/S将在7天内到期的客户
    loanOSOne: 'Please be informed that',
    loanOSTwo: 'Customer No. ',
    loanOSThree: 'with loan due record(s)',
    // 信用审查提醒
    creditOne: 'The following credit reviews under your RM code will be due in the coming three months',
    // 弹窗表格
    transDate: '交易日期',
    transCurrency: '交易货币',
    transAmountOrigin: '交易金额 (原货币)',
    transAmountHke: '交易金额 (HKE)',
    transRate: '汇率',
    alertDate: '警报日期',
    loanNo: '贷款号',
    loanReferenceNo: '贷款参考号',
    currencyOD: 'OD 货币',
    amountOD: 'OD 金额',
    currencyOS: 'O/S 货币',
    amountOS: 'O/S 金额',
    overdueDays: '逾期天数',
    accountNo: '账户号',
    accountName: '账号名称',
    nexrReviewDate: '下次审查日期',
    // 消息提示
    tipTitle: '提示',
    tipOne: '请至少选择一条数据',
    markReadTip: '确认标记为已读吗？',
    actionCancel: '已取消操作',
    //列表提醒内容
    remindContentOne: '您的公司用户：',
    remindContentTwo: '发生',
    remindContentThree: '于【',
    remindContentFour: '】，金额：',
    remindContentFive: '，请尽快跟进！',
  }
}
