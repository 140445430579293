export default {
  org: {
    title:'机构管理',
    button: {
      Resetting: '重置',
      Add: '新增',
      Delete: '删除',
      Refresh: '刷新',
      Save: '保存',
    },
    rmColumns: {
      departmentName: {
        label: '部门',
      },
      sectionName: {
        label: '分组',
      },
      teamName: {
        label: '团队',
      },
      rmCode: {
        label: 'RM Code',
      },
      remark: {
        label: '备注',
      },
      userName: {
        label: '关联 RM',
      },
      linkedRM: {
        label: '关联 RM',
      },
      linkedARM: {
        label: '关联 ARM',
      },
    },
    dialogTitle: '新 RM Code',
    formTitle: '当前机构详情',
    tableTitle: 'RM Code管理',
    form: {
      orgId: '上级',
      orgIdRule: '请输入上级',
      parentEntity: '上级实体',
      parentEntityRule: '请选择上级实体',
      orgType: '机构层级',
      orgTypeRule: '请选择机构层级',
      orgName: '实体名称',
      orgNameRule: '请输入实体名称',
      leader: '实体主管',
      leaderNameRule: '请选择实体主管',
      hasLeader: '是否有实体主管',
      yesHasLeader: '是',
      noHasLeader: '否',
      hasLeaderRule: '请选择是否有实体主管',
      sortNo: '排序号',
      remark: '备注',
      updateTime: '更新时间',
      updateUserName: '更新人',
      parentOrgId: '主管',
      tel: '电话号码',
      telRule: '请输入正确的电话号码',
      addr: '地址',
    },
    rmRules: {
      rmCode: '请输入 RM Code',
    },
    addDialog: {
      teamId: {
        label: '团队Id',
      },
      rmCode: {
        label: 'RM Code',
        rules: '请输入 RM Code',
        placeHolder: '请输入 RM Code',
      },
      remark: {
        label: '备注',
        placeHolder: '请输入备注',
      },
    },
    messageTip: {
      tipTitle: '警告',
      deleteTip: '此操作不可撤回！确认继续。',
      actionSure: '确定',
      actionCancel: '取消',
      deleteActionCancel: '已取消删除',
      tableOptTips: '请至少选择一条数据',
      selectAction: '请先在左侧点击您需要的操作',
      addSuccess: '新增成功！',
      updateSuccess: '修改成功！',
      deleteNoteOne: '此操作不可撤回！删除实体将永久影响系统数据。请重新分配实体和子公司下的所有RM。确认继续。',
      selectNote: '请选择一个节点',
    }
  },
};
