export enum TagsActionTypes {
  //添加访问的路由
  ACTION_ADD_VIEW = 'ACTION_ADD_VIEW',
  //删除访问的路由
  ACTION_DEL_VIEW = 'ACTION_DEL_VIEW',
  //删除全部访问的路由
  ACTION_DEL_ALL_VIEW = 'ACTION_DEL_ALL_VIEW',
  //删除除选中路由的其他路由
  ACTION_DEL_OTHER_VIEW = 'ACTION_DEL_OTHER_VIEW',
  //删除选中路由右侧路由
  ACTION_DEL_RIGHT_VIEW = 'ACTION_DEL_RIGHT_VIEW',
  //删除选中路由右侧路由
  ACTION_REFRESH_VISITED_VIEW = 'ACTION_REFRESH_VISITED_VIEW',
}
