import org from './org/cn';
import user from './user/cn';
import role from './role/cn'
import log from './log/cn'
import prompt from './prompt/cn'
import bireport from './bi-report/cn'
import wf from './wf/cn'
export default {
  ...org,
  ...user,
  ...role,
  ...log,
  ...prompt,
  ...bireport,
  ...wf,
  CodeType: {
    Button: {
      Search: '查询',
      Resetting: '重置',
      Add: '新增',
      Delete: '删除',
      Refresh: '刷新缓存',
      TpDownload: '模板下载',
      Import: '导入',
      Cancel: '取消',
      Determine: '确定',
      Update: '修改',
    },
    Search: {
      CodeTypeID: '代码类别ID:',
      CodeTypeName: '代码类别名称:',
      Notes: '备注:',
    },
    Form: {
      CodeTypeID: '代码类别ID:',
      CodeTypeNameCN: '代码类别名称（简体）:',
      CodeTypeNameTC: '代码类别名称（繁体）:',
      CodeTypeNameEN: '代码类别名称（英文）:',
      SortNumber: '排序号:',
      Notes: '备注:',
      Operate: '操作',
      Update: '修改',
      CodeValue: '码值',
      importInfo: '请选择通过模板修改的XLSX文件',
      importWarning: '请上传文件名后缀名为xls、xlsx的附件',
      importError: '上传文件大小超过',
    },
    Add: {
      CodeTypeAddTitle: '新增代码类别',
    },
    Delete: {
      CodeTypeDelete1: '确认删除代码类型id为',
      CodeTypeDelete2: '的代码类别吗？',
      Prompt: '提示',
      hasCancel: '已取消',
      errDelete: '请选择需要删除的项',
      delMsg1: '确认删除选中的',
      delMsg2: '条代码信息吗？',

      delDefault: '确认删除这些项吗？',
      delSuc: '删除成功',
      delCancel: '已取消删除',
    },
    Update: {
      UpdateTitle: '修改代码类别',
    },
    CodeValue: {
      CodeValueTitle1: '编辑',
      CodeValueTitle2: '代码信息',
      addCodeValueTitle1: '新增',
      addCodeValueTitle2: '码值',
      dropDownText: '下拉框内容：',
      dropDownTextCN: '下拉框内容（简中）：',
      dropDownTextTC: '下拉框内容（繁中）：',
      dropDownTextEN: '下拉框内容（英文）：',
      dropDownValue: '下拉框值：',
      slDropdownValue: '上级联动下拉框值：',
      SortNumber: '排序号:',
      Notes: '备注:',
      pleaseEnter: '请输入！',
      pleaseEnterContent: '请输入下拉框内容！',
      pleaseEnterValue: '请输入下拉框值！',
      pleaseEnterSort: '请输入排序号！',
    },
  },
};
