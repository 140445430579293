export default {
  tasks: {
    tabs1: '我收到的待辦任務',
    tabs2: '我發起的待辦任務',
    form: {
      noticeTitle: '任務名稱',
      tasksDt1: '任務接收時間',
      tasksDt2: '任務創建時間',
      planfinishDt: '計劃完成時間',
      importanceType: '重要程度',
      noticeType: '任務狀態',
      launch: '發起人',
      received: '接收人',
    },
    confirmMessage: '任務完成後不可再執行反饋',
    warnMsg: '請先選擇一條數據',
    editSucc: '反饋成功',
    addSucc: '新增成功',
    selTitle1: '分配任務到執行人',
    selTitle2: '請選擇執行人',
    selTitle3: '已選擇執行人',
    sel: '選擇',
    warnMsg1: '請選擇一個員工',
    clearBtn: '清除所有',
    taskDetail: '任務詳情',
    taskFeedback: '任務反饋',
    taskFeedbackRecording: '任務反饋記錄',
    tableBtn: {
      finishTitle: '任務完成',
      addTask: '任務創建',
      detailTitle: '詳情',
      feedback: '反饋',
      lookFeedback: '查看反饋記錄',
      publish: '發佈',
    },
    tableColumns: {
      noticeTitle: '任務名稱',
      noticeType: '任務狀態',
      importanceType: '重要程度',
      taskCycle: '任務週期',
      feedbackDt: '最近反饋時間',
      nextfeedbackDt: '下次計劃反饋時間',
      launch: '發起人',
      launchOrg: '發起人崗位',
      received: '接收人',
      receivedOrg: '接收人崗位',
      taskCompleteTime: '實際完成時間',
    },
    historyColumns: {
      feedbackDetail: '反饋內容',
      feedbackDt: '反饋時間',
      feedbackPel: '反饋人',
    },
    formDialog: {
      taskTitle: '任務標題',
      taskDetail: '任務詳情',
      taskRequest: '任務要求',
      file: '任務附件',
      uploadfile: '上傳附件',
      taskFeedback: '任務反饋',
      taskObjType: '任務對象類型',
      taskObj: '任務對象',
    },
    rules: {
      rulesFeedbackDetail: '請輸入任務反饋',
      rulesnextFeedbackDate: '請輸入下次計劃反饋時間',
      rulestaskName: '請輸入任務標題',
      rulescreateDate: '請輸入任務週期',
      rulestaskLevel: '請選擇重要程度',
      rulestaskDetail: '請輸入任務詳情',
      rulestaskRequest: '請輸入任務要求',
      rulestaskObjType: '請選擇任務對象類型',
      rulestaskObj: '請選擇任務對象',
    },
  },
};
