export default {
  prompt: {
    promptId: 'Prompt ID',
    promptDesc: 'Prompt Describe',
    Remark: 'Remark',
    number: 'Number',
    promptDescCN: 'Prompt Decribe (CN)',
    promptDescTC: 'Prompt Decribe (TC)',
    promptDescEN: 'Prompt Decribe (EN)',
    creator: 'Creator',
    creatTime: 'Create Time',
    updater: 'Updater',
    updateTime: 'Update Time',
    promptDelete1:'Are you sure to delete the code category with Prompt ID',
    promptDelete2:'?'
  },
};
