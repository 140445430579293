export default {
  user: {
    searchPlaceholder: '輸入關鍵字進行搜索',
    userDialog: {
      userDialogTitle: '用戶列表',
      userName: '用戶姓名',
    },
    columns: {
      sellerCode: 'Seller Code',
      userName: '用戶姓名(中)',
      userNameEN: '用戶姓名(英)',
      loginName: '登錄名',
      sex: '性別',
      orgName: '所屬機構',
      roleName: '當前角色',
      orgLevelName: '所屬機構',
      _roleName: '崗位',
      roleNames: '全部崗位',
      status: '用戶狀態',
      userId: '用戶ID',
      password: '密碼',
      tel: '手機號',
      idCardType: '證件類型',
      idCardNo: '證件號碼',
      sortNo: '排序號',
      lawOrgId: '法人機構號',
      tellerNo: '櫃員號',
      email: '郵箱',
      addr: '聯繫地址',
      roleIds: '全部角色',
      landline: '座機號碼',
      remark: '備註',
      rmCode: 'RM Code',
      roleId: '崗位',
    },
    searchForm: {
      sellerCode: 'Seller Code',
      userName: '用戶姓名',
      sex: '性別',
      status: '狀態',
      roleIds: '擁有的角色',
      orgLevelName: '所屬機構',
    },
    delMsg: '請選擇需要刪除的用戶',
    editMsg: '修改用戶狀態成功！',
    user: '用戶',
    resetPwTitle: '重置密碼',
    newPw: '新密碼',
    confirmPw: '確認密碼',
    confirmMsg: '確認密碼與新密碼不一致，請重新輸入',
    resetPwTip1: '是否確定重置用戶',
    resetPwTip2: '密碼',
    resetSuccess: '重置密碼成功',
    rules: {
      userId: '請輸入用戶ID',
      sellerCode: '請輸入 seller code',
      userNameEN1: '請輸入用戶英文名',
      userName1: '請輸入用戶姓名',
      userName2: '長度在100個字符以內',
      loginName1: '請輸入登錄名',
      sex: '請選擇性別',
      status: '請選擇用戶狀態',
      orgName: '請選擇所屬機構',
      tel1: '請輸入手機號',
      tel2: '請輸入正確的手機號',
      lawOrgId: '法人機構號不能為空',
      idCardNo1: '請輸入身份證號碼',
      idCardNo2: '請輸入正確的身份證號碼',
      idCardNoNum: '件號碼長度不能超過50',
      sortNo: '請輸入排序號',
      roleId: '請選擇崗位',
      roleIds: '請選擇擁有的全部崗位',
      password: '請輸入新密碼',
      rePassword: '請輸入確認密碼',
      rmCode: '請選擇 RM Code',
    },
    elMessageTip: {
      freeze: '是否確定凍結用戶',
      unFreeze: '是否確定解凍用戶',
      isIncludeRoleId: '崗位必須包含於全部崗位中',
      addSuc: '新增成功',
      updSuc: '修改成功',
      rmCode: '請先選擇所屬機構',
      sellerCode: '請先填寫 seller Code',
      loginName: '請先填寫登錄名',
      userNameEN: '請先填寫用戶姓名',
      levelChange: '更改所屬機構會先解綁 RM CODE',
    },
    // org-user-select的语言
    orgUserSelect: {
      dialogTitle: '選擇用戶',
      userId: '用戶號',
      role: '用戶角色',
      org: '所屬機構',
      selectUserTip: '請選擇一個用戶',
      selectUserLimitTip: '只能選擇一個用戶',
    },
    authRm: '授權RM',
    unAuthRm: '取消授權RM',
    authARm: '授權ARM',
    unAuthARm: '取消授權ARM',
  },
};
