export default {
  groupView: {
    button: {
      search: '查詢',
      reset: '刷新',
      export: '導出',
    },
    title: {
      borrowGrade: '借款人等級',
      BorrowGradeReDa: '借款人等級審核日期',
      income: '收入',
      dod: '較上日',
      mom: '較上月',
      yoy: '較上年同期',
      laMonEn: '較上月末',
      laYeEn: '較上年末',
      loan: '貸款',
      deposit: '存款',
      asOfDate: '統計日期',
    },
    summary: {
      summary: '概要信息',
      basic: '基本信息',
      basicInfo: '基本信息',
      ulCtrlNum: '集團號',
      ulCtrlName: '集團名稱 (英)',
      ulCtrlNameCN: '集團名稱 (中)',
      certCode: '證件號碼',
      certType: '證件類型',
      ulCtrlInfo: '集團信息',
      subsidiaries: '子公司',
      customNum: '客戶號',
      customName: '客戶名稱 (英)',
      customNameCN: '客戶名稱 (中)',
      RM: 'RM',
      department: '部門',
      sectionHead: '分部負責人',
      teamHead: '團隊負責人',
    },
    milestone: {
      milestone: '合作裏程碑',
    },
    business: {
      business: '業務信息',
      income: '收入',
      loans: '貸款',
      deposits: '存款',
      timeframe: '周期類型',
      incomeTypeValue: '收入類型',
      productTypeValue: '產品類型',
      calculationType: '計算類型',
      detSumData: '詳細匯總數據',
      // 表頭
      rank: '排序',
      customNum: '客戶號',
      customName: '客戶名稱 (英)',
      rmEname: 'RM',
      comparison: '比較',
      percentage: '百分比 (%)',
      balance: '余額',
      balanceType: '余額類型',
      incomeMTD: '本月收入',
      incomeQTD: '本季度收入',
      incomeYTD: '本年收入 (YTD)',
      incomeLastMTD: '上月收入',
      incomeLastQTD: '上季度收入',
      incomeLastYTD: '上年收入 (YTD)',
      loanMTD: '月均余額 (本月)',
      loanYTD: '年均余額 (本月)',
      loanPeEnd: '期末余額(本期)',
      loanBalLastMTD: '月均余額 (去年同月)',
      loanBalLastYTD: '年均余額 (去年同月)',
      loanBalLastPeEnd: '期末余額 (去年年底)',
      depositMTD: '月均余額 (本月)',
      depositYTD: '年均余額 (本月)',
      depositPeEnd: '期末余額 (本期)',
      depositLastMTD: '月均余額 (去年同月)',
      depositLastYTD: '年均余額 (去年同月)',
      depositLastPeEnd: '期末余額 (去年年底)',
      monthly: '按月計算',
      yearly: '按年計算',
      quarterly: '按季度計算',
      mtd: '月至今',
      ytd: '年至今',
      qtd: '季至今',
      peEnBa: '期末余額',
      daAv: '每日平均',
      peEn: '期末',
      usedCredit:'已使用額度',
      unit: '單位',
    },
    management: {
      management: '管理',
      credLimts: '信用額度',
      ulConInfo: '集團信息',
      ulConCreLim: '集團信用額度',
      creLimReaMax: '信用額度最大值',
      creLimCur: '信用額度貨幣',
      creLimCurOut:'信用額度夥伴 (未清余額)',
      OutstanBa:'未清余額',
      creLimt: '信用額度',
      exCreLim: '風險信用額度',
      oneInfo: 'OneBank 信息',
      oneBankReg: 'OneBank所屬區域',
      GRMRegion: 'GRM所屬區域',
      GRMUnit: 'GRM歸屬',
      GRMContact: 'GRM',
      GRM2Contact: '第二GRM',
      LRM: 'LRM',
    },
  },
};
