export default {
  role: {
    form: {
      roleId: '角色ID',
      roleName: '角色名稱(簡中)',
      roleNameTC: '角色名稱(繁中)',
      roleNameEN: '角色名稱(英)',
      roleProperty: '崗位屬性',
      roleScope: '角色範圍',
      status: '是否啟用',
      stNo: '排序號',
      remark: '備註',
    },
    roleColumns: {
      homeUrl: '菜單類型',
      hpType: '首頁類型',
    },
    tooltip: {
      view: '查看',
      funcConfig: '功能權限配置',
      dataPowerConfig: '數據權限配置',
      dataPower: ' 數據權限',
      user: '角色用戶配置',
    },
    delMes1: '請選擇需要刪除的項',
    delMes2: '確認刪除id為',
    delMes3: '的角色嗎',
    succ1: '啟用成功',
    succ2: '禁用成功',
    addSucc: '新增成功！',
    editSucc: '修改成功！',
    baseFromRules: {
      roleId: '請輸入角色ID',
      roleName: '請輸入角色名稱',
      lawOrgId: '請輸入法人機構id',
      roleNameTC: '請輸入角色名稱(繁中)',
      roleNameEN: '請輸入角色名稱(英)',
      remark: '請輸入備註',
      roleProperty: '請選擇崗位範圍',
      roleScope: '請選擇角色範圍',
    },
    dialog: {
      title1: '新增角色',
      title2: '功能權限配置',
      title3: '修改角色',
      title4: '查看角色',
      title5: '數據權限配置',
    },
    roleData: {
      tableColumns: {
        tableName: '業務表名',
        authType: '權限類型',
        lineAuth: '條線數據權限',
        authScopeName: '數據範圍',
        isIncludeSub: '是否含下級',
      },
      ruleType: {
        authType: '請選擇權限類型',
        lineAuth: '請選擇條線數據權限!',
        tableName: '請選擇業務表名!',
        authScopeName: '請選擇數據範圍!',
        isIncludeSub: '請選擇是否包含上下級!',
      },
    },
    user: {
      title: '角色用戶配置',
      userName: '用戶姓名',
      orgName: '所屬機構',
      suc1: '添加成功',
      suc2: '刪除成功',
      suc3: '已取消刪除',
      suc4: '請選擇需要刪除的項',
      con1: '確認刪除用戶id為',
      con2: '的用戶嗎',
      mes: '請至少選擇一名用戶添加',
      tabcolumns: {
        userNameEN: '用戶姓名(英)',
        userName: '用戶姓名(中)',
        sex: '性別',
        orgLevelName: '所屬機構',
        roleName: '當前角色',
        roleNames: '擁有的全部角色',
        status: '用戶狀態',
      },
    },
  },
};
