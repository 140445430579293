export default {
  performanceButton: {
    reset: '重置',
    search: '查询',
    export: '导出',
  },
  performanceRank: {
    pleaseSelect: '请选择',
    periodType: '周期类型',
    campaignName: '活动名称：',
    campaignPeriod:'活动周期',
    rmRanking: 'RM 排名',
    dimension: '范围',
    rankBy: '排名依据',
    calculation: '计算方式',
    dataTime: '数据时间',
    rank: '排序',
    RM: 'RM',
    sellerCode: '供应商编码',
    rmCode: 'RM编码',
    rankInNa: '排名指标名称',
    rankInVa: '排名指标值',
    teamHead: '团队负责人',
    sectionHead: '部门负责人',
    department: '部门',
    division: '条线',
    teHeSeCo:'团队负责人卖方编码',
    teamHeRank: '团队负责人排名',
    seHeRMCode: '部门负责RM编码',
    section: '分组',
    team: '团队',
    pickADay: '选择月份',
    plsPT:'请选择周期类型'

  },
  complete: {
    searchForm: {
      planPeriodType: '周期类型',
      planId: '活动名称',
      date: '日期',
    },
    label: '活动名称',
    tableColumns: {
      title: '指标完成度分析',
      dimAgg: '分部 / RM',
      indCode: '指标',
      indValue: '完成值',
      compLastMonthEnd: '较上月末',
      compLastYearEnd: '较上年末',
      compLastYearSp: '较去年同期',
      curMVal: '本月目标',
      curMRate: '当月目标完成率',
      curYVal: '度目标',
      curYRate: '度目标完成率',
    },
  },
};
