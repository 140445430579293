/*
 * @Description: 权限指令
 */
import { Directive, DirectiveBinding } from 'vue';

export const permission: Directive = {
  mounted(el: HTMLDivElement, binding: DirectiveBinding) {
    const { value } = binding;
    const funcList = sessionStorage.getItem('funcList');
    const roles = funcList ? JSON.parse(funcList) : [];
    const hasPermission = roles.filter((k: any) => k === value);
    if (!hasPermission || !hasPermission.length) {
      el.style.display = 'none';
    }
  }
};

export const allowSpace: Directive = {
  // 当被绑定的元素插入到 DOM 中时调用
  mounted(el: HTMLDivElement) {
    // 监听 keydown 事件
    el.addEventListener('keydown', (event: any) => {
      // 如果按下的是空格键，并且没有按住 shift 键，则允许输入
      if (event.key === ' ' && !event.shiftKey) {
        event.stopPropagation();
      }
    });
  },
};
