import navBarUs from '../components/navBar/us';
import custMgtUs from '../components/custMgt/us';
import mainUS from '../components/main/us';
import sysUS from '../components/sys/us';
import groupUS from '../components/group/us';
import perferUS from '../components/pfAnalyse/us';
import cipUs from '../components/cip/us';
import cbmUs from '../components/cbm/us';
import perforReviewUs from '../components/cip/performanceReview/us';
import routeUs from '../components/route/us';
import dashboardUs from '../components/dashboard/us';

export default {
  main: { ...mainUS },
  // 工作平台
  dashboard: { ...dashboardUs },
  navBar: { ...navBarUs },
  //业绩管理
  pfAnalyse: { ...perferUS },
  ...groupUS,
  // 经营计划
  cbm: { ...cbmUs },
  // 指标管理
  cip: { ...cipUs },
  // 客户管理
  custMgt: { ...custMgtUs },
  // 业绩查看
  ...perforReviewUs,
  // 系统管理
  sys: { ...sysUS },
  ...mainUS,
  // 表格序号
  tableIndexNm: 'No. ',
  // 语言切换
  message: {
    language: 'English',
  },
  // 面包屑
  breadcrumbs: {
    menuTitle: 'Home Page', // 首页
  },
  // 接口报错提示
  errorMsg: {
    msg1: 'Unknown error, contact your administrator',
    msg2: 'The backend interface is abnormally connected',
    msg3: 'The system interface request timed out',
    msg4: 'System interfaces',
    msg5: 'Abnormal',
    noData: 'No Data',
  },
  // 查询按钮
  searchBtn: {
    searchLabel: 'Search',
    resetLabel: 'Reset',
    collapseLabel: 'Collapse',
    expandLabel: 'Expand',
  },
  // 表格操作按钮
  tableBtns: {
    addLabel: 'Add',
    newLabel: 'New',
    delLabel: 'Delete',
    modLabel: 'Modify',
    editLabel: 'Edit',
    copyLabel: 'Copy',
    viewLabel: 'View',
    resetPWLabel: 'Reset-password',
    refreshLabel: 'Refresh',
    opt: 'Option',
    export: 'Export',
    exportDetail: 'Export Detail',
    action: 'Action',
    download: 'Download',
    upload: 'Upload the file',
    freeze: 'Freeze',
    unFreeze: 'Unfreeze',
    submit: 'Submit',
    expandAll: 'Expand All',
    noExpandAll: 'Collapse All',
    annex: 'Attachment',
  },
  // 表格操作提示
  tableOptTips: {
    rowSelectTips: 'Please select at least one data',
    addSuccess: 'Added successfully!',
    modifySuccess: 'Modified successfully!',
    saveSuccess: 'Saved successfully!',
    submitSuccess: 'Submit successfully!',
    promptTitleTips: 'Confirmation',
  },
  // 文件上传
  upload: {
    supType: 'Supported file types',
    maxSize: 'Each file must not exceed',
    maxN: 'Maximum',
    files: 'files',
    clientFileName: 'filename',
    uploadTime: 'uploadTime',
    delSucc: 'Deletion successful',
    delFail: 'Deletion failed',
    msg1: 'Please upload the file with the suffix',
    msg2: 'attachments',
    msg3: 'The upload file size exceeds',
    msg4: 'You can only upload at a time',
    msg4_1: 'You can only upload',
    msg5: 'files',
    msg6: 'The file upload failed, please pay attention to whether the file content is empty!',
  },
  // 弹窗按钮
  dialogBtn: {
    cancelLabel: 'Cancel',
    saveLabel: 'Save',
    confirmLabel: 'Confirm',
    resetLabel: 'Reset',
    closeLabel: 'Close',
    submitLabel: 'Submit',
  },
  // load提示语
  load: {
    loading: 'Loading...',
  },
  // 登录页面
  loginForm: {
    title: 'Welcome To OKP !',
    loginName: {
      label: 'LAN ID',
      placeholder: 'LAN ID',
      rules: 'Please enter LAN ID',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
      rules: 'Please enter password',
    },
    rememberMe: 'Remember me',
    loginLabel: 'Login',
    forgotPassword: 'Forgot Password'
  },
  // 表单输入框提示
  formPlaceholder: {
    input: 'Please enter',
    select: 'Please select',
    searchPlaceholder: 'Enter keywords for search',
    textAreaInput: 'Type here...',
  },
  // 日期范围输入框提示
  dateRangeholder: {
    pickaDt: 'Pick a Month',
    dateTo: 'To',
    startMonth: 'Start Month',
    endMonth: 'End Month',
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  // 时间相关三语配置
  time: {
    year: ' Year',
    hy1: 'First Half of',
    hy2: 'Second Half of',
    q1: 'Q1',
    q2: 'Q2',
    q3: 'Q3',
    q4: 'Q4',
    m1: 'Jan',
    m2: 'Feb',
    m3: 'Mar',
    m4: 'Apr',
    m5: 'May',
    m6: 'Jun',
    m7: 'Jul',
    m8: 'Aug',
    m9: 'Sep',
    m10: 'Oct',
    m11: 'Nov',
    m12: 'Dec',
    m: 'Month',
  },
  route: { ...routeUs },
};
