export default {
  performanceReview: {
    detailSearch: {
      dimension: '维度',
      department: '层级',
      dataDate: '选择月份',
      indicator: '业绩指标',
      searchCheck: '请选择维度、月份和业绩指标后再查询',
    },
    trendSearch: {
      department: '层级',
      timeRange: '时间范围',
      indicator: '业绩指标',
      timeRangeCheck: '请选择时间范围',
    },
    detailTitle: '业绩明细',
    detailChartTitle: '业绩明细图',
    trendTitle: '业绩趋势',
    trendChartTitle: '业绩趋势图',
    tableExport: '导出',
    detailTableColumns: {
      department: '部门',
      section: '分部',
      team: '团队',
      rm: 'RM',
      customer: '客户',
      toi: 'TOI',
      nii: 'NII',
      nfi: 'NFI',
      insurance: '保险',
      investment: '投资',
      treasury: '证券',
      deposit: '存款',
      loan: '贷款',
    },
    trendTableColumns: {
      indicator: '指标',
      unit: '单位',
    },
    formContent: {
      indicatorDialogTitile: '选择指标',
      indicatorDetailLimit: '最多只能选择2个指标',
      indicatorTrendLimit: '最多只能选择4个指标',
    },
  },
};
