export default {
  wf: {
    columns: {
      templateName: '範本名稱',
      templateType: '範本類型',
      orgName: '所屬機構',
      sortNo: '排序號',
      remark: '備註',
    },
    processColumns: {
      nodeName: '節點名稱',
      nodeType: '節點類型',
      teamFilter: '分派篩檢程式',
      teamName: '分派審批組',
      handleMode: '處理模式',
    },
    setRouteColumns: {
      nextNodeName: '下一節點',
      routeCondition: '路由條件',
    },
    approvalColumns: {
      teamName: '審批組名稱',
      userId: '用戶ID',
      userName: '用戶姓名',
    },
    process: '流程定義',
    dialog: {
      viewUrl: '查看Url',
      setRoute: '設置路由',
      addNode: '新增節點',
      editNode: '修改節點',
    },
    elMessageTip: {
      delSelectOne: '請選擇一條記錄',
      delTip: '確認刪除所選擇的記錄嗎',
      copyTip: '確認複製所選範本',
      opSuc: '操作成功',
      delNodeTip: '請選擇要刪除的節點',
      delNodeSure: '確定刪除所選節點',
      tip1: '未匹配到表單操作類型',
    },
  },
};
