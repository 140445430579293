import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e4c37cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tags-view"
};
const _hoisted_2 = {
  class: "tags-view-route",
  ref: "tagViewRouterRef"
};
const _hoisted_3 = {
  style: {
    "width": "max-content",
    "display": "flex"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowLeft = _resolveComponent("ArrowLeft");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_HomeFilled = _resolveComponent("HomeFilled");
  const _component_router_link = _resolveComponent("router-link");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_ArrowRight = _resolveComponent("ArrowRight");
  const _component_RefreshRight = _resolveComponent("RefreshRight");
  const _component_CloseBold = _resolveComponent("CloseBold");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "tags-view-button",
    onClick: _cache[0] || (_cache[0] = $event => $setup.scrollFn('L'))
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_ArrowLeft)]),
    _: 1 /* STABLE */
  })]), _createVNode(_component_el_scrollbar, {
    ref: "tagViewScrollbarRef",
    onScroll: $setup.scroll
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.visitedViews, (tag, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: tag.meta.key || tag.path,
        class: _normalizeClass(['route-item', ($setup.currentMetaKey ? $setup.currentMetaKey === tag.meta.key : $setup.currentRoute.path === tag.path) ? 'route-item-active' : ''])
      }, [!(tag.path.indexOf('/main') > -1 && !$setup.isMainPage(tag.path)) ? (_openBlock(), _createBlock(_component_el_tag, {
        key: 0,
        closable: tag.path.indexOf($setup.mainPath) > -1 ? false : true,
        "disable-transitions": false,
        onClose: $event => $setup.closeSelectedTag(tag, index)
      }, {
        default: _withCtx(() => [(_openBlock(), _createBlock(_component_router_link, {
          ref_for: true,
          ref: "tag",
          key: tag.meta.key || tag.path,
          to: {
            path: tag.path || tag.fullPath,
            query: tag.query
          }
        }, {
          default: _withCtx(() => [tag.path.indexOf($setup.mainPath) > -1 ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0
          }, {
            default: _withCtx(() => [_createVNode(_component_HomeFilled)]),
            _: 1 /* STABLE */
          })) : _createCommentVNode("v-if", true), _createElementVNode("span", null, _toDisplayString(tag.meta.title), 1 /* TEXT */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]))]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["closable", "onClose"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))], 512 /* NEED_PATCH */)]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "tags-view-button",
    onClick: _cache[1] || (_cache[1] = $event => $setup.scrollFn('R'))
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", {
    class: "tags-view-button",
    onClick: $setup.refresh
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_RefreshRight)]),
    _: 1 /* STABLE */
  })]), _createElementVNode("div", {
    class: "tags-view-button",
    onClick: $setup.closeSAllFn
  }, [_createVNode(_component_el_icon, null, {
    default: _withCtx(() => [_createVNode(_component_CloseBold)]),
    _: 1 /* STABLE */
  })])])]);
}