export default {
  performanceButton: {
    reset: '重置',
    search: '查詢',
    export: '導出',
  },
  performanceRank: {
    pleaseSelect: '請選擇',
    periodType: '周期類型',
    campaignName: '活動名稱：',
    campaignPeriod:'活動周期',
    rmRanking: 'RM 排名',
    dimension: '範圍',
    rankBy: '排名依據',
    calculation: '計算方式',
    dataTime: '數據時間',
    rank: '排序',
    RM: 'RM',
    sellerCode: '供應商編碼',
    rmCode: 'RM編碼',
    rankInNa: '排名指標名稱',
    rankInVa: '排名指標值',
    teamHead: '團隊負責人',
    sectionHead: '部門負責人',
    department: '部門',
    division: '條線',
    teamHeRank: '團隊負責人排名',
    seHeRMCode: '部門負責RM編碼',
    section: '分組',
    team: '團隊',
    pickADay: '選擇月份',
    plsPT:'請選擇周期類型'

  },
  complete: {
    searchForm: {
      planPeriodType: '周期類型',
      planId: '活動名稱',
      date: '日期',
    },
    label: '活動名稱',
    tableColumns: {
      title: '指標完成度分析',
      dimAgg: '分部 / RM',
      indCode: '指標',
      indValue: '完成值',
      compLastMonthEnd: '較上月末',
      compLastYearEnd: '較上年末',
      compLastYearSp: '較去年同期',
      curMVal: '本月目標',
      curMRate: '當月目標完成率',
      curYVal: '度目標',
      curYRate: '度目標完成率',
    },
  },
};
