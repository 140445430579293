export default {
  org: {
    title:'機構管理',
    button: {
      Resetting: '重置',
      Add: '新增',
      Delete: '刪除',
      Refresh: '刷新',
      Save: '保存',
    },
    rmColumns: {
      departmentName: {
        label: '部門',
      },
      sectionName: {
        label: '分組',
      },
      teamName: {
        label: '團隊',
      },
      rmCode: {
        label: 'RM Code',
      },
      remark: {
        label: '備註',
      },
      userName: {
        label: '關聯 RM',
      },
      linkedRM: {
        label: '關聯 RM',
      },
      linkedARM: {
        label: '關聯 ARM',
      },
    },
    dialogTitle: '新 RM Code',
    formTitle: '當前機構詳情',
    tableTitle: 'RM Code管理',
    form: {
      orgId: '上級',
      orgIdRule: '請輸入上級',
      parentEntity: '上級實體',
      parentEntityRule: '請選擇上級實體',
      orgType: '機構層級',
      orgTypeRule: '請選擇機構層級',
      orgName: '實體名稱',
      orgNameRule: '請輸入實體名稱',
      leader: '實體主管',
      leaderNameRule: '請選擇實體主管',
      hasLeader: '是否有實體主管',
      yesHasLeader: '是',
      noHasLeader: '否',
      hasLeaderRule: '请选择是否有實體主管',
      sortNo: '排序號',
      remark: '備註',
      updateTime: '更新時間',
      updateUserName: '更新人',
      parentOrgId: '主管',
      tel: '電話號碼',
      telRule: '請輸入正確的電話號碼',
      addr: '地址',
    },
    rmRules: {
      rmCode: '請輸入 RM Code',
    },
    addDialog: {
      teamId: {
        label: '團隊Id',
      },
      rmCode: {
        label: 'RM Code',
        rules: '請輸入 RM Code',
        placeHolder: '請輸入 RM Code',
      },
      remark: {
        label: '備註',
        placeHolder: '請輸入備註',
      },
    },
    messageTip: {
      tipTitle: '警告',
      deleteTip: '此操作不可撤回！确认继续。',
      actionSure: '確定',
      actionCancel: '取消',
      deleteActionCancel: '已取消刪除',
      tableOptTips: '請至少選擇一條數據',
      selectAction: '請先在左側點擊您需要的操作',
      addSuccess: '新增成功！',
      updateSuccess: '修改成功！',
      deleteNoteOne: '此操作不可撤回！刪除實體將永久影響系統數據。請重新分配實體和子公司下的所有RM。確認繼續。',
      selectNote: '請選擇一個節點',
    }
  },
};
