/*
 * @Author: zyl
 * @Date: 2023-07-17 11:02:13
 * @LastEditors: zyl
 * @LastEditTime: 2023-07-21 17:15:11
 * @Description:
 */
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import { TagsViewState, TagView } from './state';
import { Mutations } from './mutations';
import { TagsMutationTypes } from './mutation-types';
import { TagsActionTypes } from './action-types';
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<TagsViewState, RootState>, 'commit'>;

type NoAugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K): ReturnType<Mutations[K]>;
} & Omit<ActionContext<TagsViewState, RootState>, 'commit'>;
export interface Actions {
  [TagsActionTypes.ACTION_ADD_VIEW](
    { commit }: AugmentedActionContext,
    view: TagView
  ): void;
  [TagsActionTypes.ACTION_DEL_VIEW](
    { commit }: AugmentedActionContext,
    view: TagView
  ): void;
  [TagsActionTypes.ACTION_DEL_ALL_VIEW]({
    commit,
  }: NoAugmentedActionContext): void;
  [TagsActionTypes.ACTION_DEL_OTHER_VIEW](
    { commit }: AugmentedActionContext,
    view: TagView
  ): void;
  [TagsActionTypes.ACTION_DEL_RIGHT_VIEW](
    { commit }: AugmentedActionContext,
    view: TagView
  ): void;
}
export const actions: ActionTree<TagsViewState, RootState> & Actions = {
  [TagsActionTypes.ACTION_ADD_VIEW]({ commit }, view: TagView) {
    const { path, name, meta, fullPath, query, params } = view;
    const item = {
      path: path,
      name: name,
      meta: {
        ...meta
      },
      fullpath: fullPath,
      query,
      params
    };
    commit(TagsMutationTypes.ADD_VISITED_VIEW, item);
  },
  [TagsActionTypes.ACTION_DEL_VIEW]({ commit }, view: TagView) {
    commit(TagsMutationTypes.DEL_VISITED_VIEW, view);
  },
  [TagsActionTypes.ACTION_REFRESH_VISITED_VIEW]({ commit }, view) {
    commit(TagsMutationTypes.REFRESH_VISITED_VIEW, view);
  },
  [TagsActionTypes.ACTION_DEL_ALL_VIEW]({ commit }) {
    commit(TagsMutationTypes.DEL_ALL_CACHED_VIEWS);
  },
  [TagsActionTypes.ACTION_DEL_OTHER_VIEW]({ commit }, view: TagView) {
    commit(TagsMutationTypes.DEL_OTHER_VIEW, view);
  },
  [TagsActionTypes.ACTION_DEL_RIGHT_VIEW]({ commit }, view: TagView) {
    commit(TagsMutationTypes.DEL_RIGHT_VIEW, view);
  },
};
