export default {
  org: {
    title:'Institutional management',
    button: {
      Resetting: 'Reset',
      Add: 'Add',
      Delete: 'Delete',
      Refresh: 'Refresh',
      Save: 'Save',
    },
    rmColumns: {
      departmentName: {
        label: 'Department',
      },
      sectionName: {
        label: 'Section',
      },
      teamName: {
        label: 'Team',
      },
      rmCode: {
        label: 'RM Code',
      },
      remark: {
        label: 'Remarks',
      },
      userName: {
        label: 'Linked RM',
      },
      linkedRM: {
        label: 'Linked RM',
      },
      linkedARM: {
        label: 'Linked ARM',
      },
    },
    dialogTitle: 'New RM Code',
    formTitle: 'Details of the current institution',
    tableTitle: 'RM Code Management',
    form: {
      orgId: 'Selected Parents',
      orgIdRule: 'Please enter parents',
      parentEntity: 'Parent Entity',
      parentEntityRule: 'Please select parent entity',
      orgType: 'Current Level',
      orgTypeRule: 'Please select current level',
      orgName: 'Entity Name',
      orgNameRule: 'Please enter entity name',
      leader: 'Entity Head',
      leaderNameRule: 'Please select entity head',
      hasLeader: 'Has Entity Head',
      yesHasLeader: 'Yes',
      noHasLeader: 'No',
      hasLeaderRule: 'Please select if there is a entity head',
      sortNo: 'Sequence',
      remark: 'Remark',
      updateTime: 'UpdateTime',
      updateUserName: 'Updated by',
      parentOrgId: 'Supervisor',
      tel: 'Phone',
      telRule: 'Please enter Right Phone',
      addr: 'Address',
    },
    rmRules: {
      rmCode: 'Please enter RM-Code',
    },
    addDialog: {
      teamId: {
        label: 'Team Id',
      },
      rmCode: {
        label: 'RM Code',
        rules: 'Please enter RM Code',
        placeHolder: 'Please enter RM Code',
      },
      remark: {
        label: 'Remark',
        placeHolder: 'Please enter remark',
      },
    },
    messageTip: {
      tipTitle: 'Caution',
      deleteTip: 'Irreversible action! Confrm to continue.',
      actionSure: 'Confirm',
      actionCancel: 'Cancel',
      deleteActionCancel: 'Cancelled deletion',
      tableOptTips: 'Please select at least one piece of data',
      selectAction: 'Please click on the action you need on the left side first',
      deleteSuccess: 'Deleted successfully!',
      addSuccess: 'Added successfully!',
      updateSuccess: 'Modified successfully!',
      deleteNoteOne: 'Irreversible action! Deleting an entity will permanently affect system data. Please reassign all the RMs under the entity and subsidiaries. Confirmto continue.',
      deleteNoteTw: ' ?',
      selectNote: 'Please select a node',
    }
  },
};
