export default {
  performanceReview: {
    detailSearch: {
      dimension: 'Dimension',
      department: 'Level',
      dataDate: 'Select Month',
      indicator: 'Performance Indicator',
      searchCheck: 'Please select dimensions, month, and performance indicator before searching',
    },
    trendSearch: {
      department: 'Level',
      timeRange: 'Date Range',
      indicator: 'Performance Indicator',
      timeRangeCheck: 'Please select time range',
    },
    detailTitle: 'Performance Details',
    detailChartTitle: 'Performance Bar Chart',
    trendTitle: 'Performance Trend',
    trendChartTitle: 'Performance Line Chart',
    tableExport: 'Export',
    detailTableColumns: {
      department: 'Department',
      section: 'Section',
      team: 'Team',
      rm: 'RM',
      customer: 'Customer',
      toi: 'TOI',
      nii: 'NII',
      nfi: 'NFI',
      insurance: 'Insurance',
      investment: 'Investment',
      treasury: 'Treasury',
      deposit: 'Deposit',
      loan: 'Loan',
    },
    trendTableColumns: {
      indicator: 'Indicator',
      unit: 'Unit',
    },
    formContent: {
      indicatorDialogTitile: 'Select Indicator',
      indicatorDetailLimit: 'At most 2 indicators can be selected',
      indicatorTrendLimit: 'At most 4 indicators can be selected',
    },
  },
  complete: {
    searchForm: {
      planPeriodType: 'Period Type',
      planId: 'Campaign Name',
    },
    chartTitle: 'Indicator Completion Analysis',
    dtlTitle: 'Indicator Completion Analysis',
    tableColumns: {
      dimAgg: 'Division / RM',
      indCode: 'Indicator',
      indValue: 'Completed',
      compLastMonthEnd: 'Comparing to last month end',
      compLastYearEnd: 'Comparing to last year end',
      compLastYearSp: 'Comparing to last year same period',
      curMVal: 'Current Month Target',
      curMRate: 'Current Month Target Completion Rate',
      curYVal: 'Yearly Target',
      curYRate: 'Yearly Target Completion Rate',
    },
  },
};
