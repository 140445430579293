export default {
  notice: {
    noticeTitle: 'Announcement Title',
    noticeType: 'Announcement Type',
    noticeStatus: 'Status',
    createdDate: 'Created Date',
    releaseDate: 'Publish Date',
    invalidDate: 'Expiry Date',
    effectiveTime: 'Duration',
    action: 'Action',
    recall: 'Recall',
    addTitle: 'New Announcement',
    modifyTitle: 'Edit Announcement',
    viewTitle: 'Announcement Detail',
    notificationObject: 'Audience',
    selectObject: 'Select Audience',
    object: 'Select Audience',
    releaseMethod: 'Publish Type',
    noticeContent: 'Details',
    uploadFile: 'Attachment',
    maintenancePersonnel: 'Created By',
    maintenanceTime: 'Created Date',
    downloadFile: 'Files',
    assignAppr: 'Assign Approver',
    assignTo: 'Assign To',
    yesAssignAppr: 'Yes',
    noAssignAppr: 'No',
    haveNoFile: 'None',
    // 消息提示
    deleteMessage: 'Are you sure to delete the ',
    deleteMessageOne: 'Please select the Announcement that needs to be deleted',
    deleteMessageTwo: 'Are you sure to delete the selected',
    deleteMessageThree: 'bulletin?',
    deleteMessageFour: 'bulletins?',
    objectMessagedOne: 'Please select the audience first',
    objectMessagedTwo: 'Please select audience',
    addSuccess: 'Added successfully!',
    modifySuccess: 'Modified successfully!',
    submitSuccess: 'Submited successfully!',
    saveSuccess: 'Saved successfully!',
    optionSuccess: 'Operated successfully!',
    tipTitle: 'Prompt',
    offshelfMessage: 'After the announcement is taken down, it cannot be re edited and published. Do you want to take down this announcement?',
    offshelfMessageOne: 'Please select the Announcement that needs to be delist',
    offshelfMessageTwo: 'Are you sure to delist the selected',
    offshelfMessageThree: 'bulletin?',
    offshelfMessageFour: 'bulletins?',
    // 表单验证
    titleForm: 'Please enter the announcement title',
    typeForm: 'Please select the announcement type',
    releaseForm: 'Please select the publish type',
    releaseDateForm: 'Please select the publish date',
    invalidDateForm: 'Please select the expiry date',
    noticeObjForm: 'Please select the audience',
    objListForm: 'Please select the audience',
    contentForm: 'Please enter the details',
    assignApprForm: 'Please choose whether to assign an approver',
    selectApprForm: 'Please select the approver',
  }
};
