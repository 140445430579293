export interface TagView {
  path: string;
  name: string;
  meta?: any;
  fullPath?: string;
  query?: any;
  params?: any;
  [key: string]: any;
}

export interface TagsViewState {
  visitedViews: TagView[];
  activedViews: TagView;
}

import i18n from '@/locales/setup-i18n';

export const state: TagsViewState = {
  //当前访问的导航标签信息
  visitedViews: [
    {
      path: '/main',
      name: 'mainPage',
      meta: {
        // title: `${i18n.global.t('breadcrumbs.menuTitle')}`,
        title: 'Maker Main Page',
        breadcrumb: [],
        level: 1,
        icon: null,
        isFist: true,
        // breadcrumbs: [`${i18n.global.t('breadcrumbs.menuTitle')}`],
        breadcrumbs: ['Maker Main Page']
      },
      fullpath: '/main',
    },
  ],
  activedViews: {
    path: '/main',
    name: 'mainPage',
    meta: {
      // title: `${i18n.global.t('breadcrumbs.menuTitle')}`,
      title: 'Maker Main Page',
      breadcrumb: [],
      level: 1,
      icon: null,
      isFist: true,
      // breadcrumbs: [`${i18n.global.t('breadcrumbs.menuTitle')}`],
      breadcrumbs: ['Maker Main Page']
    },
    fullpath: '/main',
  },
};
