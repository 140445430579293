export default {
  performanceReview: {
    detailSearch: {
      dimension: '維度',
      department: '層級',
      dataDate: '選擇月份',
      indicator: '業績指標',
      searchCheck: '請選擇維度、月份和業績指標後再查詢',
    },
    trendSearch: {
      department: '層級',
      timeRange: '時間範圍',
      indicator: '業績指標',
      timeRangeCheck: '請選擇時間範圍',
    },
    detailTitle: '業績明細',
    detailChartTitle: '業績柱狀圖',
    trendTitle: '業績趨勢',
    trendChartTitle: '业绩績趨圖',
    tableExport: '導出',
    detailTableColumns: {
      department: '部門',
      section: '分部',
      team: '團隊',
      rm: 'RM',
      customer: '客戶',
      toi: 'TOI',
      nii: 'NII',
      nfi: 'NFI',
      insurance: '保險',
      investment: '投資',
      treasury: '證券',
      deposit: '存款',
      loan: '貸款',
    },
    trendTableColumns: {
      indicator: '指標',
      unit: '單位',
    },
    formContent: {
      indicatorDialogTitile: '選擇指標',
      indicatorDetailLimit: '最多只能選擇2個指標',
      indicatorTrendLimit: '最多只能選擇4個指標',
    },
  },
};
