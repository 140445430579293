import "core-js/modules/es.array.push.js";
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.isExternal($props.to) ? (_openBlock(), _createElementBlock("a", {
    key: 0,
    href: $props.to,
    target: "_blank",
    rel: "noopener"
  }, [_renderSlot(_ctx.$slots, "default")], 8 /* PROPS */, _hoisted_1)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    onClick: $setup.push
  }, [_renderSlot(_ctx.$slots, "default")]));
}