export default {
  approval: {
    myAppro: '我的审批',
    myAppli: '我的申请',
    status: '审批流程',
    submType:'提交类型',
    appName: '流程主题',
    creatBy: '申请人姓名',
    submiDate: '申请时间',
    currentAppr: '当前节点',
    endState: '最终状态',
    compDate:'完成时间',

    action: '操作',
    report: '报告详情',
    reportDetals: '报告详情信息',
    basicInfo: '基本信息',
    reportTitle: '报告标题',
    reporter: '报告人',
    team: '团队',
    section: '分部',
    department: '部门',
    visitDetails: '访问详情信息',
    time: '时间',
    address: '地址',
    reportType: '报告类型',
    custoName: '客户名称',
    remark: '备注',
    apprpeli: '已审批人列表',
    checkPoint: '检查点',
    receiDate: '接受日期',
    proceDate: '处理日期',
    apprLanID: '审批者局域网ID',
    liOfUnappr: '未审批人列表',
    apprCom: '审批人意见',
    agree:'同意',
    refuse:'拒绝',
    processing:'处理',
    camName:'计划名称',
    perType:'周期类型',
    camPer:'计划周期',
    targets:'目标',
    campaign:'计划',
    indicator:'指标名称',
    attribute:'属性',
    unit:'指标单位',
    lastTarCom:'上一计划阶段完成值',
    supTarVa:'上级目标值',
    tarValue:'本计划目标值',
    assTar:'指定目标',
    overall:'总目标',
    halfYear:'半年目标',
    quarterly:'季度目标',
    monthly:'月度目标',
    assignedTo:'指定给',
    lastPerCom:'最后一期完成',
    withdraw:'撤回',
    whthdrawLabel:'确认撤回此申请吗？',
    warning:'警告',
    whthdrawSuc:'撤回成功',
    canWithdraw:'取消撤回',
    approve:'确认通过该审批吗？',
    rejectApp:'确认拒绝该审批吗？',
    dismissal:'确认驳回该审批吗？',
    notMatch:'未匹配到相应的正确类型',
    operCanc:'已取消操作',
    approved:'审批通过',
    appReject:'审批驳回',
    appDismissal:'审批拒绝',
    reject:'驳回',
    annTitle:'公告标题',
    annType:'公告类型',
    audien:'通知对象',
    pubType:'发布类型',
    pubDate:'发布日期',
    expDate:'到期日期',
    details:'详情',
    attach:'附件',
    assiApp:'指定审批人',
    assiTo:'审批人',
    yes:'是',
    no:'否',
    appop:'请填写审批意见',

  },
};
