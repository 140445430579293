export default {
  leftTitle: '指标目录管理',
  rightTitle: '当前目录详情',
  msg: {
    title: '提示',
    delSelect:'请选择要删除的指标目录',
    delConfirm: '确认删除所选目录?',
    delConfirmChild:'所选菜单存在子目录,无法删除'
  },
  form: {
    kindId: {
      label: '指标分类Id',
    },
    kindName: { label: '指标分类名称' },
    parentKindId: { label: '上级菜单ID' },
    parentKindNm: { label: '上级菜单名称' },
    sortNo: { label: '排序号' },
  },
  tips: {
    add: '添加成功!',
    modify: '修改成功!',
    del: '删除成功!',
    cancel:'操作已取消',
    delSelect:'请选择需要删除的项',
    delConfirm:'确认删除以下指标吗'
  },
  index: {
    leftTitle: '指标分类',
    placeHolder: {
      indexSearch: '输入关键字进行搜索', // 输入关键字进行搜索
    },
    form:{
      indexId:'指标代码', 
      indexName:'指标名称',
      calcType:'计算类型',
      indexType:'指标类型',
      unit:'指标单位',
      indexMeas:'指标度量',
      indexNumProp:'指标属性',
      dataFrom:'数据来源',
      isOpen:'是否启用',
      fitObj: '指标维度',
      howDecimal: '精确位数',
      isHot: '是否热销',
      indexDesc: '指标描述',
      formula: '计算公式',
      operator: '运算符',
      selectIndex: '选择指标',
      ruleTable: '数据源表',
      fieldName: '来源表对应字段',
      calcMode: '归集方式',
      whereSql: '自定义SQL',
      back: '上一步',
      next: '下一步',
    },
    formRules: {
      indexId: '请输入指标代码',
      indexName: '请输入指标名称',
      indexType: '请选择指标性质',
      fitObj: '请选择指标纬度',
      unit: '请选择指标单位',
      indexMeas: '请选择指标度量',
      indexNumProp: '请选择数值属性',
      dataFrom: '请选择数据来源',
      isOpen: '请选择是否有效',
      howDecimal: '请选择精确位数',
      calcType: '请选择计算类型',
      isHot: '请选择是否热销',
      formula: '请输入计算公式',
      calcMode: '请选择归集方式',
      ruleTableId: '请选择数据源表',
      fieldNameEn: '请选择来源表对应字段',
    },
    dialog: {
      dialogTitle: {
        mod: '修改指标',
        add: '新增指标',
      },
      form: {
        stepOne: '配置指标定义',
        stepTwo: '配置指标规则',
      }
    },
  },
};
