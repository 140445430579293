export default {
  mainInfo: {
    title: {
      work: 'My Tasks',
      tools: 'Tools',
      dashboar: 'Dashboard',
      performance: 'Performance Analysis',
      followedCustomers: 'My Followings',
      alerts: 'Alerts',
      calendar: 'Calendar',
      bulletin: 'Announcement Board',
      tasks: 'Tasks',
    },
    tools: {
      logTitle: 'Tools Configuration'
    },
    myWork: {
      toDoTasks: 'Tasks',
      messageReminder: 'Alerts',
      myApproval: 'Approvals',
      mySchedule: 'Schedule'
    },
    mgkpiIndex: {
      comparisonValue: {
        lastMonth: 'MoM',
        lastYear: 'YoY',
      }
    },
    mgkpiMore: {
      moreTitle: 'Dashboard Configuration（HKE‘000）',
      moreTitleTips: 'Maximum display 6 indicators.',
      // moreTitleTips: 'Maximum display 6 indicators, [N] displayed, [6-N] available.',
    },
    calendar: {
      today: 'Today',
      selectLabel: {
        chn: 'CN',
        hk: 'HK',
        us: 'U.S.',
      },
    },
    planPressInfo: {
      echartsLabel: {
        income: 'Income',
        loans: 'Loans',
        deposits: 'Deposits',
      },
      echartsQueryParam: {
        timeframe: 'View By',
        calculationType: 'Calculation Type',
        balanceType: 'Balance Type',
      },
      custLbael: 'Customer Ranking',
      incomeTableLabel: {
        index: 'No.',
        custNum: 'Customer Number',
        custName: 'Customer Name',
        incomeMTD: 'Current Month Income',
        incomeQTD: 'Current Quarter Income',
        incomeYTD: 'Current Year Income (YTD)',
        incomeLastMTD: 'Last Month Income',
        incomeLastQTD: 'Last Quarter Income',
        incomeLastYTD: 'Last Year Income (YTD)',
        loanMTD: 'Monthly Average Balance (Current Month)',
        loanYTD: 'Yearly Average Balance (Current Month)',
        loanPeEnd: 'Period End Balance (Current)',
        loanBalLastMTD: 'Monthly Average Balance (Last Year Same Month)',
        loanBalLastYTD: 'Yearly Average Balance (Last Year Same Month)',
        loanBalLastPeEnd: 'Period End Balance (last Year End)',
        depositMTD: 'Monthly Average Balance (Current Month)',
        depositYTD: 'Yearly Average Balance (Current Month)',
        depositPeEnd: 'Period End Balance (Current)',
        depositLastMTD: 'Monthly Average Balance (Last Year Same Month)',
        depositLastYTD: 'Yearly Average Balance (Last Year Same Month)',
        depositLastPeEnd: 'Period End Balance (Last Year End)',
      }
    },
    taskRadio: {
      myReceivedTasks: 'My Received Tasks',
      myCreatedTasks: 'My Created Tasks'
    },
    task: {
      index: 'No.',
      taskName: 'Task Name',
      taskStatus: 'Status',
      taskLevel: 'Priority',
      receivedDate: 'Received Date',
      createdDate: 'Created Date',
      taskEndDate: 'Task Duration',
      taskCreatorName: 'Created By',
      taskRoleName: 'Creator Position',
      loansCreatorName: 'Assigned To',
      loansRoleName: 'Assignee Position',
      moreLogTitle: 'Detail',
      moreLogTableTitle: 'View Feedback History',
    },
    alerts: {
      tableLabel: {
        index: 'No.',
        eventType: 'Alert Name',
        remindDate: 'Received Date',
        stauts: 'Status'
      },
      moreSreach: {
        custName: 'Customer Name',
        custNo: 'Customer Number',
      },
      moreTableLabel: {
        index: 'No.',
        eventType: 'Alert Name',
        remindDate: 'Received Date',
        custEname: 'Customer Name',
        custId: 'Customer Number',
        accountNo: 'Account No.',
        transacNo: 'Number of Transactions',
        amount: "Amount (HKE'000)",
        operation: 'Action',
        view: 'View'
      },
    },
    workStatement: {
      tabsLabel: {
        report: 'Visit Report',
        schedule: 'Schedule',
      },
      workStatementAdd: {
        title: 'New Schedule Event',
        scheduleTitle: 'Event Title',
        scheduleDate: 'Event Date',
        timeRange: 'Start/End Time',
        address: 'Location',
        relatedCustomers: 'Related Customer',
        scheduleContent: 'Event Details',
        confirmTitle: 'Confirmation',
        confirmEvent: 'Confirm to delete schedule event?',
        confirmUndone: 'Delete action cannot be undone.'
      },
      placeholder: {
        scheduleTitle: 'Please Inp',
        scheduleDate: 'Please select',
        timeRange: 'Please select',
        startPlaceholder: 'Please select',
        endPlaceholder: 'Please select',
        address: 'please Inp',
        relatedCustomers: 'please Inp',
        scheduleContent: 'please Inp',
      },
      rules: {
        scheduleTitle: 'Please fill in the schedule title',
        scheduleDate: 'Please select a schedule date',
        timeArr: ' Please select a start and end time',
        address: 'Please fill in the location',
      },
      port: {
        success: 'Schedule addition success!',
        edit: 'The schedule was modified!',
        error: 'Schedule addition failure!',
        del: 'Delete successful!',
        msg: 'The expiration date has expired !',
        warn: 'If the end time is less than the current time, please select it again',
      }
    },
    button: {
      other: 'Other',
    },
    tips:{
      indexSuccess: '',
      indexError: '',
      indexWarning: '',
      kpiMore1: 'The management dashboard cannot have less than one indicator',
      kpiMore6: 'The business dashboard can only display a maximum of 6 indicators',
      kpiIndexDel: 'Successfully removed metrics',
      addSuccess: 'Operation successful',
    }
  },
  information: {
    button: {
      Search: 'Search',
      Resetting: 'Reset',
      Add: 'Add',
      Delete: 'Delete',
      Refresh: 'Refresh Cache',
      TpDownload: 'Template Download',
      Import: 'Import',
      Cancel: 'Cancel',
      Determine: 'Confirm',
      Update: 'Update',
      Export: 'Export',
    },
    title: {
      ultimateController: 'Ultimate Controller',
      borrowerGrade: 'Borrower Grade',
      loanClassification: 'Loan Classification',
      income: 'Income ',
      asOfDate: 'As of Date',
      dod: 'DoD',
      mom: 'MoM',
      momEnd: 'Last Month End',
      yoy: 'YoY',
      laYeEn: 'Last Year End',
      loan: 'Loans',
      deposit: 'Deposits',
    },
    summary: {
      summary: 'Summary',
      basic: {
        basic: 'Basic',
        basicInformation: 'Basic Information',
        customerName: 'Customer Name(English)',
        customerCName: 'Customer Name(Chinese)',
        priPhNo: 'Primary Phone No.',
        industry: 'Industry',
        dateOfEstablishment: 'Date of Establishment',
        businessAddress: 'Business Address',
        countryOfOperation: 'Country of Operation',
        officePhoneNumber: 'Office Phone Number',
        mobile: 'Mobile',
        email: 'Email',
      },
      document: {
        document: 'Document',
        documentInfomation: 'Document Information',
        documentIssuedCountry: 'Document Issued Country',
        documentType: 'Document Type',
        documentID: 'Document ID',
        issueDate: 'Issue Date',
        expiryDate: 'Expiry Date',
        mobile: 'Mobile',
        email: 'Email',
      },
      income: {
        income: 'Income',
        asOfDate: 'As of Date',
        incomeYTD: 'Income (YTD)',
        lastYearTotal: 'Last Year Total',
        dod: 'DoD',
        mom: 'MoM',
        yoy: 'YoY',
        incomeBreakdown: 'YTD Income Breakdown',
        incomeLineChart: 'Monthly Income Trend',
      },
      loans: {
        loans: 'Loans',
        asOfDate: 'As of Date',
        loanBalance: 'Loans Balance',
        lbatPreviousYearEnd: 'Loans Balance at Previous Year end',
        mothlyAverge: 'Monthly Average',
        yearlyAverge: 'Yearly Average',
        momEnd: 'Last Month End',
        laYeEn: 'Last Year End',
        loanBreakdown: 'Loans Breakdown',
        loanLineChart: 'Loans Monthly Average Trend',
      },
      deposits: {
        deposits: 'Deposits',
        asOfDate: 'As of Date',
        depositBalance: 'Desposits Balance',
        dbatPreviousYearEnd: 'Deposits Balance at Previous Year End',
        mothlyAverge: 'Monthly Average',
        yearlyAverge: 'Yearly Average',
        momEnd: 'Last Month End',
        laYeEn: 'Last Year End',
        depositBreakdown: 'Deposits Breakdown',
        depositLineChart: 'Deposits Monthly Average Trend',
      },
    },
    milestone: {
      milestone: 'Milestone',
      dataRange: 'Date Range',
      to: 'to',
    },
    business: {
      business: 'Business',
      income: 'Income',
      loan: 'Loans',
      deposit: 'Deposits',
      productType: 'Product Type',
      selectProductType: 'Please select Product Type',
      calculationType: 'Calculation Type',
      selectCalculationType: 'Please select Calculation Type',
      dateRange: 'Date Range',
      endDate: 'End date',
      incomeDetails: 'Income Details',
      incomeType: 'Income Type',
      selincomeType: 'Please select income type',
      seltransactionMonth: 'Please select transaction month',
      transactionMonthF: 'Transaction Month (From)',
      transactionMonthT: 'Transaction Month (To)',
      loanType: 'Loan Type',
      selectLoanType: 'Please select Loan Type',
      loanBalance: 'Loan Balance',
      selectLoanBalance: 'Please select Loan Balance',
      balance: 'Balance',
      selectBalance: 'Please select Balance',
      basicInfo: 'Basic Information',
      loanDetails: 'Loan Details',
      depositDetails: 'Deposit Details',
      transactionMonth: 'Transaction Month',
      product: 'Product',
      periodEndBalanceM: 'MTD Amount (HKE)',
      MonthlyAveBal: 'YTD Amount (HKE)',
      YTDAveBal: 'Average Yearly (HKE)',
      netIntIncome: 'Net Interest Income',
      nonNetIntIncome: 'Non Net Interest Income',
      department: 'Department',
      rmName: 'RM Name',
      depName: 'Department Name',
      loanNo: 'Loan No. ',
      tradeRefNo: 'Trade Ref. No. ',
      transDate: 'Transaction Date',
      currency: 'Currency',
      amount: 'Balance',
      exchangeRate: 'Exchange Rate',
      AmountHKE: 'Balance (HKE)',
      effectiveRate: 'Effecitive Rate',
      baseRate: 'Base Rate',
      spread: 'Spread',
      RWA: 'RWA',
      loanClassif: 'Loan Classification',
      pastDueAmount: 'Past Due Amount',
      depositType: 'Deposit Type',
      dpstAcctNo: 'Deposit No. ',
      accountNo: 'Account No. ',
      accountNum: 'Account Number',
      accType: 'Account Type',
      accountName: 'Account Name',
      timeDepNo: 'Time Deposit No. ',
      minAmountHKE: 'Min. Bal. (HKE)',
      maxAmountHke: 'Max. Bal. (HKE)',
      avgAmountHke: 'Avg. Bal. (HKE)',
      interestRate: 'Interest Rate (%)',
      accountStatus: 'Account Status',
      tenor: 'Tenor',
      interestCurrent: 'Interest Currency',
      regularInterest: 'Regular Interest',
      startDate: 'Start Date',
      maturityDate: 'Maturity Date',
      callDpstIndt: 'Call Deposit',
      particularInterest: 'Particular Interest',
      openAccDt: 'Account Open Date',
      closAccDt: 'Account Close Date',
      accountOpenDate: 'Account Open Date',
      callDepInd: 'Call Deposit Indicator',
      matInstruction: 'Maturity Instruction',
      greenDeposit: 'Green Deposit',
      preferRate: 'TD Preferential Rate (%)',
      bonusCASA: 'Bonus CASA',
      action: 'Action',
      transactionDtails: 'Transaction',
      creditUseAmt: 'Credit Used Amount',
      creditAmt: 'Credit Limit',
      expCreditAmt: 'Exposure Credit Limit',
      creditBal: 'Credit Remaining Limit',
      incomeTypeCheck: 'Please select the product type first',
      operationDate: 'Operation Date',
      operationTime: 'Operation Time',
      transCurrency: 'Transaction Currency',
      transAmount: 'Transaction Amount',
      operationKey: 'Operation Key',
      operationKeyShDe: 'Operation Key Short Description',
    },
    management: {
      management: 'Management',
      address: {
        address: 'Address',
        registeredAddress: 'Registered Address',
        registAddrInfo: 'Registered Address Information',
        countryOfReg: 'Country of Registration',
        operationAddrInfo: 'Operation Address Information',
        operationAddr: 'Business Address',
        countryOfOpera: 'Country of Operation',
      },
      ulmimateCtrl: {
        ulmimateCtrl: 'Ultimate Controller',
        ulmimateCtrlInfo: 'Ultimate Controller Information',
        ulCtrlNum: 'Ultimate Controller Number',
        ulCtrlName: 'Ultimate Controller Name',
        ulCtrlNameCN: 'Ultimate Controller Name (Chinese)',
        GRMInfo: 'OneBank Information',
        oneBankReg: 'OneBank Region',
        GRMReg: 'GRM Region',
        GRMU: 'GRM Unit',
        GRMContact: 'GRM Contact',
        GRMContactSecond: 'GRM 2nd Contact',
        GLCenterCode: 'GL Center Code',
        LRMInfo: 'LRM Information',
        LRM: 'LRM',
      },
      RM: {
        RM: 'RM',
        rmInformation: 'RM Information',
        RMInfo: 'RM Name',
        department: 'Department',
        teamHead: 'Team Head',
        sectionHead: 'Section Head',
        relationshipManager: 'Relationship Manager',
        rmCode: 'RM code',
        rmSellerCode: 'Seller Code'
      },
    },
    attribute: {
      attribute: 'Capital Information',
      basicInfo: 'Basic Information',
      registCapital: 'Registered Capital',
      paidInCapital: 'Paid-in Capital',
      registAddress: 'Registered Address',
      registCurrency: 'Registered Capital Currency',
      paidInCurrency: 'Paid-in Capital Currency',

    },
  },
};
