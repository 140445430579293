export default {
  log: {
    // 日志管理
    logMgr: {
      logMgrTab: '日誌管理',
      searchKey: '用戶',
      skPlaceholder: '請輸入用戶編號或者用戶名稱',
      optTime: '操作時間',
    },
    // 检测统计
    checkDet: {
      checkDetTab: '檢測統計',
      access: '訪問統計量',
      assessDate: '統計日期',
      monthActiveAsses: '月活統計',
      assessMonth: '統計月份',
    },
    // 模块使用分析
    logAnalysis: {
      logAnalysisTab: '模組使用分析',
      largeModule: '模組',
    },
    tableColumns: {
      userId: 'LAN ID',
      userNameEn: '用戶',
      department: '部門',
      section: '分部',
      team: '團隊',
      orgName: '所屬機構',
      operType: '操作類型',
      operDesc: '操作內容',
      operTime: '操作時間',
    },
    visitColumns: {
      beginDate: '起始時間',
      endDate: '截止時間',
      userCount: '用戶訪問量',
      visits: '訪問次數',
      visitsAvg: '平均訪問量',
    },
    monthColumns: {
      statMonth: '統計月份',
      normalUserCount: '正常用戶數',
      monthActiveUserCount: '月活躍用戶量',
      monthActiveRate: '月活躍用戶率（%）',
    },
    elMessageTip: {
      assessDate: '请先选择统计日期',
      assessMonth: '请先选择统计月份',
      operDesc1: '用戶進行',
      operDesc2: '操作',
    },
  },
};
