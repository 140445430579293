import org from './org/us';
import user from './user/us';
import role from './role/us';
import log from './log/us'
import bireport from './bi-report/us'
import prompt from './prompt/us'
import wf from './wf/us'
export default {
  ...org,
  ...user,
  ...role,
  ...log,
  ...prompt,
  ...bireport,
  ...wf,
  CodeType: {
    Button: {
      Search: 'Serch',
      Resetting: 'Reset',
      Add: 'Add',
      Delete: 'Delete',
      Refresh: 'Refresh Cache',
      TpDownload: 'Template Download',
      Import: 'Import',
      Cancel: 'Cancel',
      Determine: 'Confirm',
      Update: 'Update',
    },
    Search: {
      CodeTypeID: 'CodeTypeID:',
      CodeTypeName: 'CodeTypeName:',
      Notes: 'Notes:',
    },

    Form: {
      CodeTypeID: 'CodeTypeID :',
      CodeTypeNameCN: 'CodeTypeName（CN）:',
      CodeTypeNameTC: 'CodeTypeName（TC）:',
      CodeTypeNameEN: 'CodeTypeName（EN）:',
      CodeTypeName: 'CodeTypeName :',
      SortNumber: 'Sortnumber :',
      Notes: 'Notes :',
      Operate: 'Operate',
      Update: 'Update',
      CodeValue: 'CodeValue',
      importInfo: 'Please select XLSX file modified through template',
      importWarning:
        'Please upload attachments with file suffixes xls and xlsx',
      importError: 'Upload file size exceeds ',
    },
    Add: {
      CodeTypeAddTitle: 'Add code category',
    },
    Delete: {
      CodeTypeDelete1:
        'Are you sure to delete the code category with code type ',
      CodeTypeDelete2: '?',
      Prompt: 'Prompt',
      hasCancel: 'cancelled',
      errDelete: 'Please select the item that needs to be deleted',
      delMsg1: 'Are you sure to delete the selected ',
      delMsg2: ' code information？',

      deleteDefault: 'Are you sure to delete these items?',
      delSuc: 'Delete successful',
      delCancel: 'Cancelled deletion',
    },
    Update: {
      UpdateTitle: 'Modify code category',
    },
    CodeValue: {
      CodeValueTitle1: 'Edit the code information for ',
      CodeValueTitle2: '',
      addCodeValueTitle1: 'Add code value for ',
      addCodeValueTitle2: ' ',
      dropDownText: 'Dropdown Box Content:',
      dropDownTextCN: 'Dropdown Box Content(（CN）:',
      dropDownTextTC: 'Dropdown Box Content(（TC）:',
      dropDownTextEN: 'Dropdown Box Content(（EN）:',
      dropDownValue: 'Dropdown box value:',
      slDropdownValue: 'Superior linkage dropdown box value:',
      SortNumber: 'Sortnumber :',
      Notes: 'Notes :',
      pleaseEnter: 'Please enter!',
      pleaseEnterContent: 'Please enter the content of the dropdown box!',
      pleaseEnterValue: 'Please enter the dropdown box value!',
      pleaseEnterSort: 'Please enter the sorting number!',
    },
  },
};
