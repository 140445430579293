export default {
  role: {
    form: {
      roleId: 'Role ID',
      roleName: 'Role name(CN)',
      roleNameTC: 'Role name(TC)',
      roleNameEN: 'Role name(EN)',
      roleProperty: 'Job attributes',
      roleScope: 'Role scope',
      status: 'Whether  enabled',
      stNo: 'Sort number',
      remark: 'Remark',
    },
    roleColumns: {
      homeUrl: 'Menu type',
      hpType: 'Homepage type',
    },
    tooltip: {
      view: 'View',
      funcConfig: 'Configure function permissions',
      dataPowerConfig: 'Data permission configuration',
      dataPower: ' Data permission',
      user: 'Role user configuration',
    },
    delMes1: 'Please select the item you want to delete',
    delMes2: 'Confirm that the deletion ID is set to',
    delMes3: 'role',
    succ1: 'Enabled successfully',
    succ2: 'The disabling was successful',
    addSucc: 'New Success!',
    editSucc: 'Modification successful!',
    baseFromRules: {
      roleId: 'Please enter a role ID',
      roleName: 'Please enter a name for the role',
      lawOrgId: 'Please enter your legal entity ID',
      roleNameTC: 'Please enter a character name (Traditional Chinese)',
      roleNameEN: 'Please enter the name of the role',
      remark: 'Please enter a comment',
      roleProperty: 'Please select the job scope',
      roleScope: 'Please select a role scope',
    },
    dialog: {
      title1: 'New Position',
      title2: 'Function Access',
      title3: 'Edit Position',
      title4: 'View Position',
      title5: 'Data permission configuration',
    },
    roleData: {
      tableColumns: {
        tableName: 'The name of the business table',
        authType: 'Permission type',
        lineAuth: 'Line data permissions',
        authScopeName: 'Data range',
        isIncludeSub: 'Whether or not there are subordinates',
      },
      ruleType: {
        authType: 'Please select a permission type',
        lineAuth: 'Please select the stripe data permission!',
        tableName: 'Please select a service table name!',
        authScopeName: 'Please select a data scope!',
        isIncludeSub:
          'Please select  whether to include subordinates and subordinates!',
      },
    },
    user: {
      title: 'Role user configuration',
      userName: 'User name',
      orgName: 'Affiliation',
      suc1: 'The addition was successful',
      suc2: 'The deletion is successful',
      suc3: 'Deleted from the program',
      suc4: 'Please select the item you want to delete',
      con1: 'Confirm that the deleted user ID is set to',
      con2: 'users',
      mes: 'Please select at least one user to add',
      tabcolumns: {
        userNameEN: 'Username (English)',
        userName: 'User Name (Medium)',
        sex: 'gender',
        orgLevelName: 'Affiliation',
        roleName: 'Current Role',
        roleNames: 'All the characters you have',
        status: 'User status',
      },
    },
  },
};
