import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5e6a1893"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "header"
};
const _hoisted_2 = {
  class: "header-left"
};
const _hoisted_3 = {
  key: 0,
  class: "header-left-division"
};
const _hoisted_4 = {
  class: "header-left-text"
};
const _hoisted_5 = {
  class: "header-right"
};
const _hoisted_6 = {
  class: "v-card-main"
};
const _hoisted_7 = {
  key: 0,
  class: "dashed"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['v-card', _ctx.isBorder ? 'v-border' : ''])
  }, [_ctx.title ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "headerTitle", {}, () => [_ctx.isDivision ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1 /* TEXT */)], true)]), _createElementVNode("div", _hoisted_5, [_renderSlot(_ctx.$slots, "headerBtns", {}, undefined, true)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_6, [_ctx.isDashed ? (_openBlock(), _createElementBlock("div", _hoisted_7)) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "main", {}, undefined, true)])], 2 /* CLASS */);
}