export default {
  bireport: {
    reportName:'Report Name',
    reportID:'Report ID',
    reportURL:'Report URL',
    remark:'Remark',
    sortNo:'Sort No',
    manage:'Management',
  },
};
