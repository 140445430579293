export default {
  wf: {
    columns: {
      templateName: 'Template Name',
      templateType: 'Template Type',
      orgName: 'level',
      sortNo: 'Sort Number',
      remark: 'Remark',
    },
    processColumns: {
      nodeName: 'Node Name',
      nodeType: 'Node Type',
      teamFilter: 'Dispatch Filter',
      teamName: 'Dispatch Approval',
      handleMode: 'Handle Mode',
    },
    setRouteColumns: {
      nextNodeName: 'Next Node',
      routeCondition: 'Routing Conditions',
    },
    approvalColumns: {
      teamName: 'Approval Group Name',
      userId: 'User Id',
      userName: 'User Name',
    },
    process: 'Process Definition',
    dialog: {
      viewUrl: 'View Url',
      setRoute: 'Set Route',
      addNode: 'Add Node',
      editNode: 'Edit Node',
    },
    elMessageTip: {
      delSelectOne: 'Please select a record',
      delTip: 'Are you sure to delete the selected record',
      copyTip: 'Are you sure to  copy the selected template',
      opSuc: 'Operation successful',
      delNodeTip: 'Please select the node to be deleted',
      delNodeSure: 'Are you sure to delete the selected node',
    },
  },
};
