export default {
  report: {
    myReports: 'My Reports',
    myTeamsReports:"My Team's Reports",
    report:'Report',
    reportType: 'Report Type',
    reportTitle: 'Report Title',
    costomerName: 'Customer Name',
    reportstate: 'Report Status',
    reportDate: 'Report Date',
    number:'No.',
    createDate:'Create Date',
    reportDetails:'Report Details',
    newReport:'New Report',
    editReport:'Edit Report',
    userId: 'Created By',
    basicInfo:'Basic Information',
    reporter:'Reporter',
    team:'Team',
    section:'Section',
    department:'Department',
    visitdetails:'Visit Details Information',
    time:'Time',
    Address:'Address',
    remark:'Remark',
    appointAppr: 'Assign Approver',
    selectAppr: 'Assign To',
    yesAppointAppr: 'Yes',
    noAppointAppr: 'No',
    formRules: {
      reportTitleRule: 'Please enter the report title',
      visitDateRule: 'Please select a date',
      custIdRule: 'Please select a customer',
      appointApprRule: 'Please choose whether to specify an approver',
      selectApprRule: 'Please select the approver',
      deleteMsg: 'Are you sure to delete this report?',
      reportTypeRule:'Plese select the report type',
      
    },
    attachment:'Attachment',
    selectFile:'Select File',
    supportedFileType:'Supported File Type: ',
    eachfile:'Each file must not exceed 15MB.Maximum 5 files.'
  },
};