export default {
  approval: {
    myAppro: '我的審批',
    myAppli: '我的申請',
    status: '審批流程',
    submType:'提交類型',
    appName: '流程主題',
    creatBy: '申請人姓名',
    submiDate: '申請時間',
    currentAppr: '當前節點',
    endState: '最終狀態',
    compDate:'完成時間',

    action: '操作',
    report: '報告詳情',
    reportDetals: '報告詳情信息',
    basicInfo: '基本信息',
    reportTitle: '報告標題',
    reporter: '報告人',
    team: '團隊',
    section: '分部',
    department: '部門',
    visitDetails: '訪問詳情信息',
    time: '時間',
    address: '地址',
    reportType: '報告類型',
    custoName: '客戶名稱',
    remark: '備註',
    apprpeli: '已審批人列表',
    checkPoint: '檢查點',
    receiDate: '接受日期',
    proceDate: '處理日期',
    apprLanID: '審批者局域網ID',
    liOfUnappr: '未審批人列表',
    apprCom: '審批人意見',
    agree:'同意',
    refuse:'拒絕',
    processing:'處理',
    camName:'計劃名稱',
    perType:'周期類型',
    camPer:'計劃周期',
    targets:'目標',
    campaign:'計劃',
    indicator:'指標名稱',
    attribute:'屬性',
    unit:'指標單位',
    lastTarCom:'上一計劃階段完成值',
    supTarVa:'上級目標值',
    tarValue:'本計劃目標值',
    assTar:'指定目標',
    overall:'總目標',
    halfYear:'半年目標',
    quarterly:'季度目標',
    monthly:'月度目標',
    assignedTo:'指定給',
    lastPerCom:'最後一期完成',
    withdraw:'撤回',
    whthdrawLabel:'確認撤回此申請嗎？',
    warning:'警告',
    whthdrawSuc:'撤回成功',
    canWithdraw:'取消撤回',
    approve:'確認通過該審批嗎？',
    rejectApp:'確認拒絕該審批嗎？',
    dismissal:'確認駁回該審批嗎？',
    notMatch:'未匹配到相應的正確類型',
    operCanc:'已取消操作',
    approved:'審批通過',
    appReject:'審批駁回',
    appDismissal:'審批拒絕',
    reject:'駁回',
    annTitle:'公告標題',
    annType:'公告類型',
    audien:'通知對象',
    pubType:'發布類型',
    pubDate:'發布日期',
    expDate:'到期日期',
    details:'詳情',
    attach:'附件',
    assiApp:'指定審批人',
    assiTo:'審批人',
    yes:'是',
    no:'否',
    appop:'請填寫審批意見',

  },
};
