export default {
  log: {
    // 日志管理
    logMgr: {
      logMgrTab: 'Log Management',
      searchKey: 'User',
      skPlaceholder: 'Please enter user ID or user name',
      optTime: 'Date',
    },
    // 检测统计
    checkDet: {
      checkDetTab: 'Statistics',
      access: 'Traffic Statistics',
      assessDate: 'Date',
      monthActiveAsses: 'Monthly Active User',
      assessMonth: 'Date',
    },
    // 模块使用分析
    logAnalysis: {
      logAnalysisTab: 'Analysis',
      largeModule: 'Function',
    },
    tableColumns: {
      userId: 'LAN ID',
      userNameEn: 'User',
      department: 'Department',
      section: 'Section',
      team: 'Team',
      orgName: 'Level',
      operType: 'Operation Type',
      operDesc: 'Action',
      operTime: 'Date Time',
    },
    visitColumns: {
      beginDate: 'Start Date',
      endDate: 'End Date',
      userCount: 'Unique Visitor',
      visits: 'Page View',
      visitsAvg: 'Average Visits',
    },
    monthColumns: {
      statMonth: 'Month',
      normalUserCount: 'Normal Users',
      monthActiveUserCount: 'Monthly Active Users',
      monthActiveRate: 'Monthly Active Users Rate (%)',
    },
    elMessageTip: {
      assessDate: 'Please select the Date first',
      assessMonth: 'Please select the Date first',
      operDesc1: 'The user performs the ',
      operDesc2: ' operation',
    },
  },
};
