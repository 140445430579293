export default {
  notice: {
    noticeTitle: '公告标题',
    noticeType: '公告分类',
    noticeStatus: '公告状态',
    createdDate: '创建日期',
    releaseDate: '发布日期',
    invalidDate: '到期日期',
    effectiveTime: '有效期',
    action: '操作',
    recall: '下架',
    addTitle: '新增公告',
    modifyTitle: '修改公告',
    viewTitle: '公告详情',
    notificationObject: '通知对象',
    selectObject: '选择对象',
    object: '对象',
    releaseMethod: '发布方式',
    noticeContent: '详情',
    uploadFile: '附件',
    maintenancePersonnel: '创建人',
    maintenanceTime: '创建时间',
    downloadFile: '附件',
    assignAppr: '分配审批人',
    assignTo: '分配给',
    yesAssignAppr: '是',
    noAssignAppr: '否',
    haveNoFile: '无',
    // 消息提示
    deleteMessage: '是否确认删除',
    deleteMessageOne: '请选择需要删除的公告',
    deleteMessageTwo: '确认删除所选的',
    deleteMessageThree: '条公告吗？',
    deleteMessageFour: '条公告吗？',
    objectMessagedOne: '请先选择通知对象',
    objectMessagedTwo: '请选择对象',
    addSuccess: '新增成功！',
    modifySuccess: '修改成功！',
    submitSuccess: '提交成功！',
    saveSuccess: '保存成功！',
    optionSuccess: '操作成功！',
    tipTitle: '提示',
    offshelfMessage: '公告下架后不可重新编辑发布，是否下架该公告？',
    offshelfMessageOne: '请选择需要下架的公告',
    offshelfMessageTwo: '确认下架所选的',
    offshelfMessageThree: '条公告吗？',
    offshelfMessageFour: '条公告吗？',
    // 表单验证
    titleForm: '请输入公告标题',
    typeForm: '请选择公告类型',
    releaseForm: '请选择发布时间',
    releaseDateForm: '请选择发布时间',
    invalidDateForm: '请选择到期时间',
    noticeObjForm: '请选择通知对象',
    objListForm: '选择对象',
    contentForm: '请输入公告内容',
    assignApprForm: '请选择是否分配审批人',
    selectApprForm: '请选择审批人',
  }
};
