/**
 *loading框设置局部刷新
 * 当调用一次showLoading，则次数+1；当次数为0时，则显示loading
 * 当调用一次hideLoading，则次数-1; 当次数为0时，则结束loading
 */
import { ElLoading } from 'element-plus';
import useI18n from '@/locales/use-i18n';
//声明一个对象用于存储请求个数
let loadingRequestCount = 0;
// 初始化loading
let loadingInstance: any;

//显示
const showLoading = () => {
  if (loadingRequestCount === 0) {
    // 获取 dialog 集合，如果有则挂载到最后一个，没有则挂载 app-main
    let dialogArr = document.querySelectorAll('.el-dialog');
    let drawerArr = document.querySelectorAll('.el-drawer');
    let _target: any =
      (dialogArr.length && dialogArr[dialogArr.length - 1]) ||
      (drawerArr.length && drawerArr[drawerArr.length - 1]) ||
      document.querySelector('.app-main');
    loadingInstance = ElLoading.service({
      lock: true,
      text: useI18n.t('load.loading'),
      background: 'rgb(0,0,0,0.1)',
      target: _target,
      customClass: 'me-loading',
    });
  }
  loadingRequestCount++;
};

//隐藏
const hideLoading = () => {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    loadingInstance.close();
  }
};

export { showLoading, hideLoading };
