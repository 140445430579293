export default {
  tableColumns: {
    custEname: {
      label: '客戶名稱 (英)',
    },
    custName: {
      label: '客戶名稱 (中)',
    },
    Action: {
      label: '操作',
    },
    rowAction: {
      label: '查看',
    },
  },
  groupListTableColumns: {
    Action: {
      label: '操作',
    },
    rowAction: {
      label: '查看',
    },
    ultmCtrlrId: {
      label: '集團號',
    },
    ultmCtrlrNameEn: {
      label: '集團名稱(英)',
    },
    ultmCtrlrNameCh: {
      label: '集團名稱(中)',
    },
    grmDep: {
      label: '部門',
    },
    ultmCtrlrGrmEname: {
      label: 'GRM',
    },
  },
  searchForm: {
    custName: {
      label: '客戶名稱',
    },
    custType: {
      label: '客戶類型',
    },
    industryCode: {
      label: '行業',
    },
    custId: {
      label: '客戶號',
    },
    certType: {
      label: '證件類型',
    },
    rmDepName: {
      label: '部門',
    },
    certCode: {
      label: '證件號',
    },
    sectionHead: {
      label: '分部負責人',
    },
    teamHead: {
      label: '團隊負責人',
    },
    rmCode: {
      label: 'RM',
    },
  },
  groupListSearchForm: {
    ultmCtrlrId: {
      label: '集團號',
    },
    ultmCtrlrName: {
      label: '集團名稱',
    },
    grmDep: {
      label: '部門',
    },
    custId: {
      label: '客戶號',
    },
    custName: {
      label: '客戶名稱',
    },
  },
  custInfoDtl: {
    dialogTitle: '客戶信息詳情',
    relation: {
      cardTitle: '關聯信息',
    },
    basic: {
      cardTitle: '基本信息',
      basicInformation: {
        cardTitle: '基本信息',
        custId: {
          label: '客戶號',
        },
        custEname: {
          label: '客戶名稱 (英)',
        },
        custName: {
          label: '客戶名稱 (中)',
        },
        custType: {
          label: '客戶類型',
        },
        industry: {
          label: '行業',
        },
      },
      operationInformation: {
        cardTitle: '公司信息',
        certRegisAddrCountry: {
          label: '註冊國家/地區',
        },
        certRegisAddr: {
          label: '註冊地址',
        },
        busAddrCountry: {
          label: '運營國家/地區',
        },
        busRegisAddr: {
          label: '公司地址',
        },
        foundDt: {
          label: '成立日期',
        },
        empCnt: {
          label: '員工人數',
        },
        regisCcy: {
          label: '註冊貨幣',
        },
        regisCapit: {
          label: '註冊資本',
        },
        padCcy: {
          label: '實收資本貨幣',
        },
        paidCapit: {
          label: '實收資本',
        },
        isList: {
          label: '已上市',
        },
        fixTel: {
          label: '辦公室電話號碼',
        },
        fixTelAddrContry: {
          label: '辦公室電話區號',
        },
        phAddrContry: {
          label: '手機區號',
        },
        fixTelNo: {
          label: '辦公室號碼',
        },
        phoneNo: {
          label: '手機號碼',
        },
        fixTelNoExt: {
          label: '辦公室分機號',
        },
        primaryPhoneNo: {
          label: '首要電話號碼',
        },
      },
      classInfo: {
        cardTitle: '股東信息',
        entitySizeHkmaCode: {
          label: '實體規模',
        },
        holder: {
          label: '股東',
        },
        holdRat: {
          label: '股東持股比例 (%)',
        },
      },
      rmInformation: {
        cardTitle: 'RM 信息',
        rmCode: {
          label: 'RM編碼',
        },
        rmName: {
          label: 'RM名稱',
        },
        rmSellerCode: {
          label: '銷售編碼',
        },
        rmDepName: {
          label: '部門',
        },
        belgSectionName: {
          label: '分部負責人',
        },
        belgTeamName: {
          label: '團隊負責人',
        },
      },
    },
    doc: {
      cardTitle: '證件信息',
      tableColumns: {
        certCountry: {
          label: '證件簽發國家/地區',
        },
        certType: {
          label: '證件類型',
        },
        certCode: {
          label: '證件號',
        },
        certBegDate: {
          label: '證件簽發日期',
        },
        certValidDate: {
          label: '證件到期日',
        },
        remark: {
          label: '備註',
        },
      },
    },
    ultCon: {
      cardTitle: '集團資訊',
      tableColumns: {
        ultmCtrlrId: {
          label: '集團號',
        },
        ultmCtrlrNameEn: {
          label: '集團名稱 (英)',
        },
        ultmCtrlrNameCh: {
          label: '集團名稱 (中)',
        },
        ultmCtrlrGrmEname: {
          label: 'GRM',
        },
        grmDep: {
          label: '部門',
        },
      },
    },
    contacts: {
      cardTitle: '聯繫資訊',
      tableColumns: {
        contactName: {
          label: '聯繫人姓名',
        },
        title: {
          label: '聯繫人稱謂',
        },
        contactTelNo: {
          label: '聯繫公司電話',
        },
        contactPhoneNo: {
          label: '手機號碼',
        },
        email: {
          label: '郵箱',
        },
      },
    },
    shareholder: {
      cardTitle: '股東資訊',
      tableColumns: {
        relCustId: {
          label: '客戶號',
        },
        relCustEname: {
          label: '客戶名稱 (英)',
        },
        relCustName: {
          label: '客戶名稱 (中)',
        },
        isMgr: {
          label: '是否關聯人',
        },
        invPerc: {
          label: '占股比例 (%)',
        },
      },
    },
    reCompany: {
      cardTitle: '關聯企業信息',
      tableColumns: {
        mangCustId: {
          label: '關聯企業客戶號',
        },
        mangCustEname: {
          label: '關聯企業客戶名稱 (英)',
        },
        mangCustName: {
          label: '關聯企業客戶名稱 (中)',
        },
        relType: {
          label: '關係類型',
        },
      },
    },
    reMgt: {
      cardTitle: '關聯管理資訊',
      tableColumns: {
        mngCustId: {
          label: '客戶號',
        },
        mngCustEname: {
          label: '客戶名稱 (英)',
        },
        mngCustNameCh: {
          label: '客戶名稱 (中)',
        },
        relType: {
          label: '管理類型',
        },
        isShareholder: {
          label: '是否股東',
        },
      },
    },
  },
  groupInfo: {
    dialogTitle: '集團客戶詳情',
    basic: {
      cardTable: '基本',
      cardTitle: '基本信息',
      ultmCtrlrId: {
        label: '集團號',
      },
      ultmCtrlrNameEn: {
        label: '集團名稱(英)',
      },
      ultmCtrlrNameCh: {
        label: '集團名稱(中)',
      },
      onebankRegion: {
        label: 'OneBank 所屬區域',
      },
      grmRegion: {
        label: 'GRM 所屬區域',
      },
      ultmCtrlrGrmUnit: {
        label: 'GRM 歸屬',
      },
      grmcontact: {
        label: 'GRM',
      },
      grm2ndContact: {
        label: '第二 GRM',
      },
      ultmCtrlrLrmEname: {
        label: 'LRM',
      },
    },
    subsidiaries: {
      cardTable: '子公司',
      cardTitle: '子公司',
      tableColumns: {
        custId: {
          label: '客戶號',
        },
        custEname: {
          label: '客戶名稱(英)',
        },
        custName: {
          label: '客戶名稱(中)',
        },
        certType: {
          label: '證件類型',
        },
        certCode: {
          label: '證件號',
        },
        rmName: {
          label: 'RM',
        },
      },
    },
  },
  elMessageTip: {
    certType: '請先選擇證件類型',
    department: '請選擇部門',
    sectionHead: '請選擇分部負責人',
    teamHead: '請選擇團隊負責人',
    selectOneUser: '請至少選擇一名用戶',
  },
  attention: {
    tabs1: '公司客戶',
    tabs2: '集團客戶',
    editSucc: '取消關注成功',
    confirmText: '是否取消關注該客戶？',
    tableColumns: {
      grmUnit: 'GRM Unit',
      grmContact: '集團客戶經理',
      numOfSub: '子公司數量',
    },
  },
};
