import { getToken } from '@/utils/http/auth';
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw, useRouter,
} from 'vue-router';
//公共路由
import constantRoutes from './constant-modules';
import {getRouteList, hasRegistered} from './permission-modules';
import {useState} from "@/utils/hooks/store-hooks";
import {store} from "@/store";
import { ElMessage } from 'element-plus';
import { AppActionType } from '@/store/modules/app/action-types';

const creatRouter = (constantRoute: Array<RouteRecordRaw>) => {
  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoute,
  });
};
//创建路由实例
let router = creatRouter(constantRoutes());
//重置路由
export const resetRouter = () => {
  router = creatRouter(constantRoutes());
};
//判断是否存在已添加的路由
const hasRoute = (to: RouteLocationNormalized) => {
  const allRoutes = router.getRoutes();
  const extendRoute = allRoutes.filter((k) => k.path === to.path);
  return !!(extendRoute && extendRoute.length);
};
// 详情页数组
const detailPage: Array<string> = [];
// 不重定向白名单
const whiteList = ['login', 'To-Do flow form'];

export async function getMainPagePath() {
  const permissionRoutes = await getRouteList();
  for (const permissionRoute of permissionRoutes) {
    if (['makerMain', 'TeamHeadMainPage'].includes(permissionRoute.name)){
      return permissionRoute.redirect;
    }
  }
  return '/welcome'
}
router.beforeEach(async (to, from, next) => {
  console.log("route: ", to.path);

  // 设置是否隐藏
  console.log('eric', to.query);
  if (to.query.hide != undefined) {
    await store.dispatch(`app/${AppActionType['ACTION_SET_HIDE']}`, to.query.hide === 'true')
  }

  if (whiteList.indexOf(to.name as string) !== -1) {
    resetRouter();
    next();
  } else {
    //是否存在token
    const token = await getToken();
    console.log('是否存在token：', token);
    if (token) {
      if (to.path === '/main') {
        const mainPagePath = await getMainPagePath();
        if (mainPagePath != '/main') {
          next({path: mainPagePath});
          return;
        }
      }
      const menuPaths = JSON.parse(sessionStorage.getItem("dynamicRoutes") || "[]").map((menu: any) => menu.menuUrl);
      if (to.meta.permission !== false && !menuPaths.includes(to.path)) {
        ElMessage.warning('You do not have permission to access')
        next(false);
        return;
      }
      //是否已注册路由
      const isRoute = hasRoute(to);
      console.log('是否已注册路由：', isRoute);
      if (!isRoute) {
        const hasPage = await hasRegistered(to);
        console.log('hasRegistered：', hasPage);
        if (hasPage) {
          router.addRoute(hasPage);
          next({ ...to, replace: true });
        } else {
          //客户视图及其他详情页
          const isDetailPage = detailPage.filter(
            (k: any) => to.fullPath.indexOf(k) > -1
          );
          if (isDetailPage) {
            next();
          } else {
            next({
              name: 'empty',
              query: {
                from: to.fullPath || '/',
              },
            });
          }
        }
      } else {
        next();
      }
    } else {
      next({
        name: 'login',
        query: {
          from: to.fullPath || '/',
        },
      });
    }
  }
});

declare module 'vue-router' {
  interface Router {
    skipRoute: (path: string, key: string, query: {[key: string]: any}) => void;
  }
}

router.skipRoute = async function (path: string, key: string, query: {[key: string]: any}) {
  // key需要和route.meta.key一致，route.meta.key可以通过“{字段名}”动态设置
  const tagsViewState = store.state.tagsView;
  const view = tagsViewState.visitedViews.find((v: any) => v.meta.key === key);
  console.log("skipRoute:", view);
  await this.push({
    path: view ? view.path : path,
    query: view ? view.query : query
  })
}

export default router;
