export interface WebsocketState {
  ws: any,
  heartCheckTimer: any,
  notifyFun: any,
  caseExistMsg: any[]
}

export const state: WebsocketState = {
  ws: null,
  heartCheckTimer: null,
  notifyFun: null,
  caseExistMsg: []
};
