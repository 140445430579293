export default {
  approval: {
    myAppro: 'My Approvals',
    myAppli: 'My Submissions',
    status: 'Status',
    submType:'Submission Type',
    appName: 'Approver Name',
    creatBy: 'Created By',
    submiDate: 'Submission Date',
    currentAppr: 'Status',
    endState: 'Final Status',
    compDate:'Completion Date',
    action: 'Action',
    report: 'Report',
    reportDetals: 'Report Details',
    campaignDetails:'Campaign Details',
    AnnouncementDetails:'Announcement Details',
    basicInfo: 'Basic Infomation',
    reportTitle: 'Report Title',
    reporter: 'Reporter',
    team: 'Team',
    section: 'Section',
    department: 'Department',
    visitDetails: 'Visit Details Information',
    time: 'Time',
    address: 'Address',
    reportType: 'Report Type',
    custoName: 'Customer Name',
    remark: 'Remark',
    apprpeli: 'Previous Approver List',
    checkPoint: 'Checkpoint',
    receiDate: 'Received Date',
    proceDate: 'Processed Date',
    apprLanID: 'Approver LAN ID',
    liOfUnappr: 'Next Approver List',
    apprCom: 'Approver Comment',
    agree:'Agree',
    refuse:'Refuse',
    processing:'Process',
    camName:'Campaign Name',
    perType:'Period Type',
    camPer:'Campaign Period',
    targets:'Targets',
    campaign:'Campaign',
    indicator:'Indicator',
    attribute:'Attribute',
    unit:'Unit',
    lastTarCom:'Last Target Completion',
    supTarVa:'Supervisor Target Vlue',
    tarValue:'Target Value',
    assTar:'Assigned Target',
    overall:'Overall',
    halfYear:'Half-Yearly',
    quarterly:'Quarterly',
    monthly:'Monthly',
    assignedTo:'Assigned To',
    lastPerCom:'Last Period Completion',
    withdraw:'Withdraw',
    whthdrawLabel:'Are you sure to withdraw this application?',
    warning:'Warning',
    whthdrawSuc:'Withdraw successful',
    canWithdraw:'Cancel Whithdraw',
    approve:'Are you sure you have passed the approval?',
    rejectApp:'Are you sure you want to reject the approval',
    dismissal:'Are you sure to deny the approval?',
    notMatch:'Not matching the corresponding correct type',
    operCanc:'Operation cancelled',
    approved:'Approved',
    appReject:'Approval rejection',
    appDismissal:'Approval Dismissal',
    reject:'Reject',
    annTitle:'Announcement Title',
    annType:'Announcement Type',
    audien:'Audience',
    pubType:'Publish Type',
    pubDate:'Publish Date',
    expDate:'Expiry Date',
    details:'Details',
    attach:'Attachment',
    assiApp:'Assign Approver',
    assiTo:'Assign To',
    yes:'Yes',
    no:'No',
    appop:'Please fill in the approval Comment',
  },
};
