import navBarHk from '../components/navBar/hk';
import custMgtHk from '../components/custMgt/hk';
import mainHK from '../components/main/hk';
import groupHK from '../components/group/hk';
import sysHK from '../components/sys/hk';
import cbmHk from '../components/cbm/hk';
import cipHk from '../components/cip/hk';
import perferHK from '../components/pfAnalyse/hk';
import perforReviewHk from '../components/cip/performanceReview/hk';
import routeHk from '../components/route/hk';
import dashboardHk from '../components/dashboard/hk';

export default {
  main: { ...mainHK },
  // 工作平台
  dashboard: { ...dashboardHk },
  navBar: { ...navBarHk },
  //客户管理
  pfAnalyse: { ...perferHK },
  // 表格序号
  tableIndexNm: '序號',
  ...groupHK,
  // 业绩查看
  ...perforReviewHk,
  // 指标管理
  cip: { ...cipHk },
  // 经营计划
  cbm: { ...cbmHk },
  // 客户管理
  custMgt: { ...custMgtHk },
  //系统管理
  sys: { ...sysHK },
  // 语言切换
  message: {
    language: '繁體中文',
  },
  // 面包屑
  breadcrumbs: {
    menuTitle: '首頁', // 首页
  },
  // 接口报错提示
  errorMsg: {
    msg1: '未知錯誤，請聯繫管理員',
    msg2: '後端接口連接異常',
    msg3: '系統接口請求超時',
    msg4: '系統接口',
    msg5: '異常',
    noData: '暫無數據',
  },
  // 查询按钮
  searchBtn: {
    searchLabel: '查詢',
    resetLabel: '重置',
    collapseLabel: '收起',
    expandLabel: '展開',
  },
  // 表格操作按钮
  tableBtns: {
    addLabel: '新增',
    newLabel: '新增',
    delLabel: '刪除',
    modLabel: '修改',
    editLabel: '修改',
    copyLabel: '複製',
    viewLabel: '查看',
    resetPWLabel: '重置密碼',
    refreshLabel: '刷新',
    opt: '操作',
    export: '導出',
    exportDetail: '導出明細',
    action: '操作',
    download: '下載',
    upload: '上傳文件',
    freeze: '凍結',
    unFreeze: '解凍',
    submit: '提交',
    expandAll: '全部展開',
    noExpandAll: '全部收起',
    annex: '附件',
  },
  // 表格操作提示
  tableOptTips: {
    rowSelectTips: '請至少選擇一條數據',
    adaddSuccess: '新增成功！',
    updateSuccess: '修改成功！',
    saveSuccess: '保存成功！',
    submitSuccess: '提交成功！',
    promptTitleTips: '提示',
  },
  // 文件上传
  upload: {
    supType: '支持文件類型',
    maxSize: '每個文件最大',
    maxN: '最多',
    files: '個文件',
    clientFileName: '文件名',
    uploadTime: '上傳時間',
    delSucc: '刪除成功',
    delFail: '刪除失敗',
    msg1: '請上傳文件名後綴名為',
    msg2: '的附件',
    msg3: '上傳文件大小超過',
    msg4: '一次最多只能上傳',
    msg4_1: '最多只能上傳',
    msg5: '個文件',
    msg6: '文件上傳失敗，請注意文件內容是否為空!',
  },
  // 弹窗按钮
  dialogBtn: {
    cancelLabel: '取消',
    saveLabel: '保存',
    confirmLabel: '確定',
    resetLabel: '重置',
    closeLabel: '關閉',
    submitLabel: '提交',
  },
  // load提示语
  load: {
    loading: '正在請求數據...',
  },
  // 登录
  loginForm: {
    title: '歡迎來到 OKP !',
    loginName: {
      label: 'LAN ID',
      placeholder: 'LAN ID',
      rules: '請輸入用戶名',
      searchPlaceholder: '輸入關鍵字進行搜索',
    },
    password: {
      label: '密碼',
      placeholder: '密碼',
      rules: '請輸入密碼',
    },
    rememberMe: '記住我',
    loginLabel: '登 錄',
    forgotPassword: '忘記密碼'
  },
  // 表单输入框提示
  formPlaceholder: {
    input: '請輸入',
    select: '請選擇',
    searchPlaceholder: '輸入關鍵字進行搜索',
    textAreaInput: '在此處鍵入...',
  },
  // 日期范围输入框提示
  dateRangeholder: {
    dateTo: '至',
    startMonth: '開始月份',
    endMonth: '結束月份',
    startDate: '開始日期',
    endDate: '結束日期',
  },
  // 时间相关三语配置
  time: {
    year: '年',
    hy1: '上半年',
    hy2: '下半年',
    q1: '第1季度',
    q2: '第2季度',
    q3: '第3季度',
    q4: '第4季度',
    m1: '01月',
    m2: '02月',
    m3: '03月',
    m4: '04月',
    m5: '05月',
    m6: '06月',
    m7: '07月',
    m8: '08月',
    m9: '09月',
    m10: '10月',
    m11: '11月',
    m12: '12月',
    m: '月',
  },
  route: { ...routeHk },
};
