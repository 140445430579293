export default {
  user: {
    searchPlaceholder: 'Enter keywords for search',
    userDialog: {
      userDialogTitle: 'User List',
      userName: 'User Name',
    },
    columns: {
      sellerCode: 'Staff ID',
      userName: 'Staff Name (Chinese)',
      userNameEN: 'Staff Name',
      loginName: 'LAN ID',
      sex: 'Gender',
      orgName: 'orgName',
      roleName: 'role',
      orgLevelName: 'Level',
      _roleName: 'Default Position',
      roleNames: 'Role',
      status: 'Status',
      userId: 'User ID',
      password: 'Password',
      tel: 'Phone No.',
      idCardType: 'ID Type',
      idCardNo: 'ID Number',
      sortNo: 'Sort number',
      lawOrgId: 'Legal entity number',
      tellerNo: 'Teller number',
      email: 'Email',
      addr: 'Contact address',
      roleIds: 'All Roles',
      landline: 'Landline number',
      remark: 'Remark',
      rmCode: 'RM Code',
      roleId: 'Default Position',
    },
    searchForm: {
      sellerCode: 'Staff ID',
      userName: 'Staff Name',
      sex: 'Gender',
      status: 'Status',
      roleIds: 'Position',
      orgLevelName: 'Level',
    },
    delMsg: 'Please select the user you want to delete',
    editMsg: 'Modifying the user status successfully!',
    user: 'User',
    resetPwTitle: 'Reset password',
    newPw: 'New passwords',
    confirmPw: 'Confirm password',
    confirmMsg:
      'Confirm that the password is inconsistent with the new password, and please re-enter it',
    resetPwTip1: 'Are you sure to reset the password for ',
    resetPwTip2: '',
    resetSuccess: 'Password reset successful',
    rules: {
      userId: 'Please enter your user ID',
      sellerCode: 'Please enter the seller code',
      userNameEN1: 'Please enter the staff name',
      userName1: 'Please enter the staff name (Chinese)',
      userName2: 'The length must be less than 100 characters',
      loginName1: 'Please enter your login name',
      sex: 'Please select a gender',
      status: 'Please select a user status',
      orgName: 'Please select your affiliation',
      tel1: 'Please enter your mobile phone number',
      tel2: 'Please enter a valid mobile phone number',
      lawOrgId: 'The legal entity number cannot be empty',
      idCardNo1: 'Please enter your ID number',
      idCardNo2: 'Please enter the correct ID number',
      idCardNoNum: 'The length of the ID number cannot exceed 50',
      sortNo: 'Please enter a sequence number',
      roleId: 'Please select the default position',
      roleIds: 'Please select the positions',
      password: 'Please enter a new password',
      rePassword: 'Please enter your confirmation password',
      rmCode: 'Please select the RM Code',
    },
    elMessageTip: {
      freeze: 'Are you sure to Freeze user ',
      unFreeze: 'Are you sure to Unfreeze user ',
      isIncludeRoleId: 'The default position must be included in all positions',
      addSuc: 'Added successfully',
      updSuc: 'updated successfully',
      rmCode: 'Please select the level first',
      sellerCode: 'Please fill in seller Code first',
      loginName: 'Please fill in LAN ID first',
      userNameEN: 'Please fill in Staff Name first',
      levelChange: 'Changing the level will disconnect the RM CODE',
    },
    // org-user-select的语言
    orgUserSelect: {
      dialogTitle: 'Select User',
      userId: 'User Id',
      role: 'User Role',
      org: 'Organization',
      selectUserTip: 'Please select a User',
      selectUserLimitTip: 'Only one user can be selected',
    },
    authRm: 'Assign RM',
    unAuthRm: 'Remove RM',
    authARm: 'Assign ARM',
    unAuthARm: 'Remove ARM',
  },
};
