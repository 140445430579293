import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.show = $event),
    width: "760px",
    center: "",
    "modal-class": "hint-dialog"
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "button")])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "icon"), _renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]);
}