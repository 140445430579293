import { createApp, Directive } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from '@/store';
import * as ElIconModules from '@element-plus/icons-vue';
import globalPlugins from '@/components/global';
import globalDialogAPI from '@/components/dialog/index'
import '@/assets/iconfont/iconfont.js';
import '@/assets/iconfont/menufont.js';
import 'element-plus/theme-chalk/el-loading.css';
import 'element-plus/theme-chalk/el-message.css';
import 'element-plus/theme-chalk/el-message-box.css';
import * as directives from '@/utils/plugin/directive';
import i18n from '@/locales/setup-i18n';
import filtersObj from '@/utils/filters/index'
import mitt from "mitt";
import { formatDate } from '@/utils/filters/formatDate';
const emitter = mitt();
// import jQueryPlugin from '@/utils/plugin/jquery';


const app = createApp(App);
declare module "vue" {
  export interface ComponentCustomProperties {
    $bus: typeof emitter;
    $formatDate: (param: string) => string;
  }
}
//统一注册filter全局过滤函数
Object.keys(filtersObj).forEach((key) => {
  app.config.globalProperties[`$${key}`] = (filtersObj as any)[key]
});
// 统一注册Icon图标
Object.keys(ElIconModules).forEach((key) => {
  app.component(key, ElIconModules[key as keyof typeof ElIconModules]);
});
// 自定义指令
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key]);
});
/**
 * @description: n为保留的位数，this为输入的数字
 * @return {*} 保留位数的数字
 * @author: xp
 */
// 改写toFixed方法，改为四舍五入
Number.prototype.toFixed = function (n): any {
  const number = this as any;
  if (number) {
    if (isNaN(number) || number >= Math.pow(10, 21)) {
      return number.toString();
    }
    if (typeof n == 'undefined' || n == 0) {
      return Math.round(number).toString();
    }
    let result = number.toString();
    const arr = result.split('.');
    // 整数的情况
    if (arr.length < 2) {
      result += '.';
      for (let i = 0; i < n; i += 1) {
        result += '0';
      }
      return result;
    }
    const integer = arr[0];
    const decimal = arr[1];
    // 数字小数位数与需要保留的位数相等，直接返回
    if (decimal.length == n) {
      return result;
    }
    // 数字小数位数小于需要保留的位数，补齐0返回
    if (decimal.length < n) {
      for (let i = 0; i < n - decimal.length; i += 1) {
        result += '0';
      }
      return result;
    }
    result = integer + '.' + decimal.substring(0, n);
    const last = decimal.substring(n, n + 1);
    // 四舍五入，转换为整数再处理，避免浮点数精度的损失
    if (parseInt(last, 10) >= 5) {
      const x = Math.pow(10, n);
      result = (Math.round(parseFloat(result) * x) + 1) / x;
      const _arr = result.toString().split('.');
      const _decimal = _arr[1] || [];
      let _decimal1 = '';
      // 数字小数位数小于需要保留的位数，补齐0返回，
      // 补充9进一位，变成10，没有添加0返回，区分小数位之后有没有数字
      if (!_decimal.length) {
        // 无小数点，补齐小数点
        if (_decimal.length < n) {
          for (let i = 0; i < n - _decimal.length; i += 1) {
            _decimal1 += '0';
          }
          return result + '.' + _decimal1;
        }
      } else {
        // 有小数点，则补充0返回
        if (_decimal.length < n) {
          for (let i = 0; i < n - _decimal.length; i += 1) {
            _decimal1 += '0';
          }
          return result + _decimal1;
        }
      }
    }
    return result;
  } else {
    return '0.00';
  }
};
app.config.globalProperties.$i18n_global = i18n.global.t;
app.config.globalProperties.$bus = emitter;
app.config.globalProperties.$formatDate = formatDate

app.use(globalPlugins)
app.use(globalDialogAPI)
app.use(store)
app.use(router)
app.use(i18n)
// app.use(jQueryPlugin);
app.mount('#app');
