import { ActionContext, ActionTree } from 'vuex';
import { RootState, store } from '@/store';
import { WebsocketState } from './state';
import { Mutations, WebsocketMutationType } from './mutations';
import { WebsocketActionType } from './action-types';
import { getToken } from "@/utils/http/auth";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<WebsocketState, RootState>, 'commit'>;

export interface Actions {
  [WebsocketActionType.startWebSocket](
    { commit, dispatch, state }: AugmentedActionContext,
    notifyFun: any
  ): void;
  [WebsocketActionType.checkOpen](
    { commit, dispatch, state }: AugmentedActionContext
  ): void;
  [WebsocketActionType.sendWebSocketMessage](
    { commit, dispatch, state }: AugmentedActionContext,
    msg: any
  ): void;
  [WebsocketActionType.reconnectWebSocket](
    { commit, dispatch, state }: AugmentedActionContext
  ): void;
  [WebsocketActionType.clearWebSocket](
    { commit, dispatch, state }: AugmentedActionContext
  ): void;
  [WebsocketActionType.startHeartCheck](
    { commit, dispatch, state }: AugmentedActionContext
  ): void;
  [WebsocketActionType.clearHeartCheckTimer](
    { commit, dispatch, state }: AugmentedActionContext
  ): void;
  [WebsocketActionType.closeWebsocket](
    { commit, dispatch, state }: AugmentedActionContext,
    reason: any
  ): void;
  [WebsocketActionType.handleMsg](
    { commit, dispatch, state }: AugmentedActionContext,
    msg: any
  ): void;
}

export const actions: Actions & ActionTree<WebsocketState, RootState> = {
  async [WebsocketActionType.startWebSocket](
    { commit, dispatch, state }: AugmentedActionContext,
    notifyFun: any
  ) {
    if (notifyFun) {
      commit(WebsocketMutationType.SET_NOTIFY, notifyFun);
    }

    const token: any = await getToken();

    if (token && (!state.ws || state.ws.readyState !== WebSocket.OPEN)) {
      const isLocal = process.env.NODE_ENV === "local"
      let socketUrl;
      if (isLocal) {
        const url = process.env.VUE_APP_BASE_API;
        const wsUrlBase =  url.replaceAll('http', 'ws').replaceAll('https', 'wss').replaceAll('/api', '')
        socketUrl = `${wsUrlBase}/websocket/message?token=${token}`
      } else {
        socketUrl = `ws://${location.host}/websocket/message?token=${token}`;
      }
      const ws = new WebSocket(socketUrl);

      ws.onmessage = function (e) {
        if (e.data !== "pong") {
          console.log(`${new Date().toLocaleString()} >>>>> 收到消息 ${e.data}`, state.ws);
          const msg= JSON.parse(e.data);
          store.dispatch(`websocket/${WebsocketActionType.handleMsg}`, msg);
        }
        // else {
          // store.dispatch(`websocket/${WebsocketActionType.handleMsg}`, 'pong');
        // }
      };

      ws.onclose = function () {
        console.log(`${new Date().toLocaleString()} >>>>> 连接已关闭`);
        // 尝试重新连接
        store.dispatch(`websocket/${WebsocketActionType.reconnectWebSocket}`);
      };

      ws.onopen = function () {
        state.notifyFun?.closeAll();
        // 保存 WebSocket 连接信息
        commit(WebsocketMutationType.SET_WS, ws);
        // // 在这里调用 sendWebSocketMessage，确保 state.ws 已经被正确设置
        // 开始心跳检测
        store.dispatch(`websocket/${WebsocketActionType.startHeartCheck}`);
      };

      ws.onerror = function (e) {
        console.log(`${new Date().toLocaleString()} >>>>> 数据传输发生异常`, e);
      };
    }
  },
  [WebsocketActionType.checkOpen](
    { commit, dispatch, state }: AugmentedActionContext
  ): void {
    console.log('state.ws', state.ws);
    return state.ws && state.ws.readyState == WebSocket.OPEN;
  },
  [WebsocketActionType.sendWebSocketMessage](
    { commit, dispatch, state }: AugmentedActionContext,
    msg: any
  ): void {
    // console.log(`${new Date().toLocaleString()} >>>>> 发送消息：${msg}`, state.ws);
    if (state.ws) {
      state.ws.send(msg);
    }
  },
  async [WebsocketActionType.reconnectWebSocket](
    { commit, dispatch, state }: AugmentedActionContext
  ) {
    await store.dispatch(`websocket/${WebsocketActionType.clearWebSocket}`);
    // 递归调用，一直尝试重连
    setTimeout(() => {
      store.dispatch(`websocket/${WebsocketActionType.startWebSocket}`);
    }, 10000);
  },
  async [WebsocketActionType.clearWebSocket](
    { commit, dispatch, state }: AugmentedActionContext
  ) {
    if (state.ws) {
      state.ws.close();
      await store.dispatch(`websocket/${WebsocketActionType.clearHeartCheckTimer}`);
      commit(WebsocketMutationType.SET_WS, null);
    }
  },
  async [WebsocketActionType.startHeartCheck](
    { commit, dispatch, state }: AugmentedActionContext
  ) {
    // 清除之前的计时器
    await store.dispatch(`websocket/${WebsocketActionType.clearHeartCheckTimer}`);

    // 创建新的计时器
    await store.dispatch(`websocket/${WebsocketActionType.sendWebSocketMessage}`, 'ping');
    const timer = setInterval(() => {
      if (!state.ws || state.ws.readyState !== WebSocket.OPEN) {
        console.log(`${new Date().toLocaleString()} >>>>> 心跳检测失败,触发重连`, state.ws);
        // dispatch('reconnectWebSocket');
      } else {
        // console.log(`${new Date().toLocaleString()} >>>>> 心跳正常,继续下一次心跳检测`, state.ws);
        store.dispatch(`websocket/${WebsocketActionType.sendWebSocketMessage}`, 'ping');
      }
    }, 1000 * 30);
    commit(WebsocketMutationType.SET_HEART_CHECK_TIMER, timer);
  },
  async [WebsocketActionType.clearHeartCheckTimer](
    { commit, dispatch, state }: AugmentedActionContext
  ) {
    const timer = state.heartCheckTimer;
    timer && clearInterval(timer);
    commit(WebsocketMutationType.SET_HEART_CHECK_TIMER, null);
  },
  async [WebsocketActionType.closeWebsocket](
    { commit, dispatch, state }: AugmentedActionContext,
    reason: any
  ) {
    if (state.ws) {
      state.ws.close(1000, reason);
      console.log('关闭后状态', state.ws.readyState)
      await store.dispatch(`websocket/${WebsocketActionType.clearHeartCheckTimer}`);
    }
  },
  [WebsocketActionType.handleMsg](
    { commit, dispatch, state }: AugmentedActionContext,
    msg: any
  ) {
    state.caseExistMsg.push(msg);
  }
};
