import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65933eb4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "horizontal-menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [!$setup.getAuthMenuList.length ? (_openBlock(), _createBlock(_component_el_empty, {
    key: 0,
    description: "暂无可访问的菜单权限"
  })) : (_openBlock(), _createBlock(_component_el_menu, {
    key: 1,
    "default-active": $setup.activeMenu,
    mode: "horizontal",
    "text-color": '#fff',
    "active-text-color": "#1D70F5",
    "background-color": '#04336a'
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getAuthMenuList, item => {
      return _openBlock(), _createBlock($setup["MenuItem"], {
        key: item.path,
        item: item
      }, null, 8 /* PROPS */, ["item"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["default-active"]))]);
}