/*
 * @Author: zyl
 * @Date: 2023-07-17 11:02:13
 * @LastEditors: zyl
 * @LastEditTime: 2023-07-24 15:25:07
 * @Description:
 */
import { MutationTree } from 'vuex';
import { TagsViewState, TagView } from './state';
import { TagsMutationTypes } from './mutation-types';
import i18n from '@/locales/setup-i18n';
import {useRoute} from "vue-router";

const pathRegex = /:[a-zA-Z]+/g;
export function complementPath(path: string, route: {query: any, params: any}) {
  return path?.replace(pathRegex, (match: string) => {
    const fieldName = match.substring(1, match.length);
    return (route.query[fieldName] || route.params[fieldName]) as string;
  })
}

export type Mutations<S = TagsViewState> = {
  [TagsMutationTypes.ADD_VISITED_VIEW](state: S, view: TagView): void;
  [TagsMutationTypes.DEL_VISITED_VIEW](state: S, view: TagView): void;
  [TagsMutationTypes.REFRESH_VISITED_VIEW](state: S, view: TagView): void;
  [TagsMutationTypes.DEL_ALL_CACHED_VIEWS](state: S): void;
  [TagsMutationTypes.DEL_OTHER_VIEW](state: S, view: TagView): void;
  [TagsMutationTypes.DEL_RIGHT_VIEW](state: S, view: TagView): void;
};
export const regex = /\{[a-zA-Z]+}/g;
export function getMeatKey(key: string, query: object, params: object) {
  return key.replace(regex, (match: string) => {
    const fieldName = match.substring(1, match.length - 1);
    return query[fieldName] || params[fieldName];
  })
}
export const mutations: MutationTree<TagsViewState> & Mutations = {
  [TagsMutationTypes.ADD_VISITED_VIEW](state: TagsViewState, view: TagView) {
    if (view.query) {
      if (view.meta.title) {
        view.meta.title = view.meta.title?.replace(regex, (match: string) => {
          const fieldName = match.substring(1, match.length - 1);
          return view.query[fieldName] || view.params[fieldName];
        });
      }
      if (view.meta.key) {
        view.meta.key = getMeatKey(view.meta.key, view.query, view.params);
      }
    }
    if (view.meta.key && view.meta.key === state.activedViews.meta.key) {
      for (let visitedView of state.visitedViews) {
        if (visitedView.path === state.activedViews.path) {
          visitedView.path = view.path;
          break;
        }
      }
      state.activedViews = view;
      return;
    }
    const viewIndex = state.visitedViews.findIndex((v) => view.meta.key ?  v.meta.key === view.meta.key : v.path === view.path);
    if (viewIndex >= 0) {
      console.log("add tagView:", view);
      state.visitedViews.splice(
          viewIndex,
          1,
          view
      );
    } else {
      console.log("add tagView:", view);
      state.visitedViews.splice(
          state.visitedViews.findIndex(
              (i) => i.path === state.activedViews.path
          ) + 1,
          0,
          view
      );
    }
    console.log("to tagView:", view);
    state.activedViews = view;
  },
  [TagsMutationTypes.DEL_VISITED_VIEW](state: TagsViewState, view: TagView) {
    console.log('DEL_VISITED_VIEW', state, view);
    state.visitedViews = state.visitedViews.filter((k) => {
      console.log(k.path, k.meta.key, view.path, view.meta.key)
      console.log(k.path !== view.path, !k.meta.key, k.meta.key !== view.meta.key)
      return k.meta.key ? k.meta.key !== view.meta.key : k.path !== view.path;
    });
  },
  [TagsMutationTypes.REFRESH_VISITED_VIEW](state: TagsViewState, view) {
    state.visitedViews = JSON.parse(JSON.stringify(view))
  },
  [TagsMutationTypes.DEL_ALL_CACHED_VIEWS](state: TagsViewState) {
    state.visitedViews = [
      {
        path: '/main',
        name: 'mainPage',
        meta: {
          // title: `${i18n.global.t('breadcrumbs.menuTitle')}`,
          title: 'Maker Main Page',
          breadcrumb: [],
          level: 1,
          icon: null,
          isFist: true,
          // breadcrumbs: [`${i18n.global.t('breadcrumbs.menuTitle')}`],
          breadcrumbs: ['Maker Main Page']
        },
        fullpath: '/main',
      },
    ];
  },
  [TagsMutationTypes.DEL_OTHER_VIEW](state: TagsViewState, view: TagView) {
    if (view.path.indexOf('/main') > -1)
      state.visitedViews = [state.visitedViews[0]];
    else state.visitedViews = [state.visitedViews[0], view];
  },
  [TagsMutationTypes.DEL_RIGHT_VIEW](state: TagsViewState, view: TagView) {
    const viewIndex = state.visitedViews.findIndex((v) => {
      return v.path === view.path;
    });
    state.visitedViews.splice(viewIndex + 1);
  },
};
