import { NotificationInfo } from "@/layout/navbar/notification/NotificationInfo";
import $http from "@/utils/http";
import {DataGrid, SearchListParams} from "@/utils/http/http-type";

const urlPrefix = "/notification";

export function getList(params: SearchListParams<{ status: string }>) {
    return $http.post<DataGrid<NotificationInfo>>(`${urlPrefix}/list`, params, true, false);
}

export function markRead(id: string) {
    return $http.post(`${urlPrefix}/markRead/${id}`);
}
