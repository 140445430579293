export default {
  currentDtTip: '批次日期:',
  helloTip: '您好',
  modPswTip: '修改密码',
  logoutBtnTip: '退出登录',
  sysTitle: '公司客户关系管理系统',
  custSearchPlaceholder: '请输入客户号/客户名称/证件号/集团号/集团名称',
  search: {
    rules: '请输入',
  },
  tagView: {
    refreshTag: '刷新当前页面',
    closeRightTags: '关闭右侧页面',
    closeOtherTags: '关闭其他页面',
    closeAllTags: '关闭所有页面',
  },
  searchDialog: {
    customerLabel: '客户信息列表',
    customerColumns: {
      custId: '客户号',
      custEname: '客户名称 (英)',
      custName: '客户名称 (中)',
      certType: '证件类型',
      certCode: '证件号',
    },
    utimateLabel: '集团信息列表',
    utimateColumns: {
      ultmCtrlrId: '集团号',
      ultmCtrlrNameEn: '集团名称 (英)',
      ultmCtrlrNameCh: '集团名称 (中)',
      ultmCtrlrGrmEname: 'GRM',
    },
  },
};
