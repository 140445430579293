import tasks from './tasks/cn';
import reports from './reports/cn';
import notice from './notice/cn';
import message from './message/cn';
import approval from './approval/cn'
export default {
  ...tasks,
  ...reports,
  ...notice,
  ...message,
  ...approval,
};
