export default {
  currentDtTip: 'Batch Date: ',
  helloTip: 'Hello',
  modPswTip: 'Password modify',
  logoutBtnTip: 'Logout',
  sysTitle: 'ECR SYSTEM',
  custSearchPlaceholder: 'Please enter Customer No/Customer Name/Document ID/Ultimate Controller Number/Ultimate Controller Name',
  search: {
    rules:
      'Please enter Customer ID or Customer Name or Chinese Name or Certificate No',
  },
  tagView: {
    refreshTag: 'Refresh this page',
    closeRightTags: 'close right pages',
    closeOtherTags: 'close other pages',
    closeAllTags: 'close all pages',
  },
  searchDialog: {
    customerLabel: 'Customer List',
    customerColumns: {
      custId: 'Customer Number',
      custEname: 'Customer Name',
      custName: 'Customer Name (Chinese)',
      certType: 'Document Type',
      certCode: 'Document ID',
    },
    utimateLabel: 'Ultimate Controller List',
    utimateColumns: {
      ultmCtrlrId: 'Ultimate Controller Number',
      ultmCtrlrNameEn: 'Ultimate Controller Name',
      ultmCtrlrNameCh: 'Ultimate Controller Name (Chinese)',
      ultmCtrlrGrmEname: 'GRM',
    },
  },
};
