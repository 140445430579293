export default {
  remind: {
    alertName: '警報名稱',
    remindDate: '接收的日期',
    readStatus: '狀態',
    custName: '客戶名稱',
    custId: '客戶編號',
    dialogTitle: '事件提醒詳情',
    markReadBtn: '標注已讀',
    // 弹窗head text
    accountNumber: '帳號',
    currency: '貨幣',
    // 大额客户、大额提现客户
    largeAmountOne: 'As of',
    largeAmountTwo: 'The deposit balance of',
    largeAmountThree: 'Customer No. ',
    largeAmountFour: 'increased by',
    largeAmountFive: 'decreased by',
    largeAmountSix: 'HKD',
    // 定期七天內到期
    dueSeventOne: 'The Time Deposits of',
    dueSeventTwo: 'Account No. ',
    dueSeventSix: 'in HKD',
    dueSeventThree: 'will be matured in 7 days',
    dueSeventFour: 'Link',
    dueSeventFive: 'Dashboard - Time Deposit >> Time Deposit to be Matured in 7 Days',
    // 逾期未付利息的客户、逾期贷款客户
    dueOne: 'Customer No. ',
    dueTwo: 'with overdue interest record(s)',
    dueThree: 'with past due loan record(s)',
    // 贷款O/S将在7天内到期的客户
    loanOSOne: 'Please be informed that',
    loanOSTwo: 'Customer No. ',
    loanOSThree: 'with loan due record(s)',
    // 信用审查提醒
    creditOne: 'The following credit reviews under your RM code will be due in the coming three months',
    // 弹窗表格
    transDate: '交易日期',
    transCurrency: '交易貨幣',
    transAmountOrigin: '交易金額 (原貨幣)',
    transAmountHke: '交易金額 (HKE)',
    transRate: '匯率',
    alertDate: '警報日期',
    loanNo: '貸款號',
    loanReferenceNo: '貸款參考號',
    currencyOD: 'OD 貨幣',
    amountOD: 'OD 金額',
    currencyOS: 'O/S 貨幣',
    amountOS: 'O/S 金額',
    overdueDays: '逾期天數',
    accountNo: '帳戶號',
    accountName: '帳號名稱',
    nexrReviewDate: '下次審查日期',
    // 消息提示
    tipTitle: '提示',
    tipOne: '請至少選擇一條數據',
    markReadTip: '確認標記為已讀嗎？',
    actionCancel: '已取消操作',
    //列表提醒内容
    remindContentOne: '您的公司用户：',
    remindContentTwo: '發生',
    remindContentThree: '於【',
    remindContentFour: '】，金額：',
    remindContentFive: '，請儘快跟進！',
  }
}
