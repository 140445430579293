export default {
  tableColumns: {
    custEname: {
      label: 'Customer Name',
    },
    custName: {
      label: 'Customer Name (Chinese)',
    },
    Action: {
      label: 'Action',
    },
    rowAction: {
      label: 'View',
    },
  },
  groupListTableColumns: {
    Action: {
      label: 'Action',
    },
    rowAction: {
      label: 'View',
    },
    ultmCtrlrId: {
      label: 'Ultimate Controller Number',
    },
    ultmCtrlrNameEn: {
      label: 'Ultimate Controller Name',
    },
    ultmCtrlrNameCh: {
      label: 'Ultimate Controller Name (Chinese)',
    },
    grmDep: {
      label: 'Department',
    },
    ultmCtrlrGrmEname: {
      label: 'GRM',
    },
  },
  searchForm: {
    custName: {
      label: 'Customer Name',
    },
    custType: {
      label: 'Customer Type',
    },
    industryCode: {
      label: 'Industry',
    },
    custId: {
      label: 'Customer Number',
    },
    certType: {
      label: 'Document Type',
    },
    rmDepName: {
      label: 'Department',
    },
    certCode: {
      label: 'Document ID',
    },
    sectionHead: {
      label: 'Section Head',
    },
    teamHead: {
      label: 'Team Head',
    },
    rmCode: {
      label: 'RM',
    },
  },
  groupListSearchForm: {
    ultmCtrlrId: {
      label: 'Ultimate Controller Number',
    },
    ultmCtrlrName: {
      label: 'Ultimate Controller Name',
    },
    grmDep: {
      label: 'Department',
    },
    custId: {
      label: 'Customer Number',
    },
    custName: {
      label: 'Customer Name',
    },
  },
  custInfoDtl: {
    dialogTitle: 'Customer Information Detail',
    relation: {
      cardTitle: 'Relation',
    },
    basic: {
      cardTitle: 'Basic',
      basicInformation: {
        cardTitle: 'Basic',
        custId: {
          label: 'Customer Number',
        },
        custEname: {
          label: 'Customer Name',
        },
        custName: {
          label: 'Customer Name (Chinese)',
        },
        custType: {
          label: 'Customer Type',
        },
        industry: {
          label: 'Industry',
        },
      },
      operationInformation: {
        cardTitle: 'Company Information',
        certRegisAddrCountry: {
          label: 'Country of Registration',
        },
        certRegisAddr: {
          label: 'Registered Address',
        },
        busAddrCountry: {
          label: 'Country of Operation',
        },
        busRegisAddr: {
          label: 'Business Address',
        },
        foundDt: {
          label: 'Date of Establishment',
        },
        empCnt: {
          label: 'Number of Employees',
        },
        regisCcy: {
          label: 'Registered Currency',
        },
        regisCapit: {
          label: 'Registered Capital',
        },
        padCcy: {
          label: 'Paid-in Capital Currency',
        },
        paidCapit: {
          label: 'Paid-in Capital',
        },
        isList: {
          label: 'Is Listed',
        },
        fixTel: {
          label: 'Office Phone Number',
        },
        fixTelAddrContry: {
          label: 'Office Number Country Code',
        },
        phAddrContry: {
          label: 'Mobile Country Code',
        },
        fixTelNo: {
          label: 'Office Number',
        },
        phoneNo: {
          label: 'Mobile',
        },
        fixTelNoExt: {
          label: 'Office Number Extension',
        },
        primaryPhoneNo: {
          label: 'Primary Phone Number',
        },
      },
      classInfo: {
        cardTitle: 'Classification Information',
        entitySizeHkmaCode: {
          label: 'Entity Size',
        },
        holder: {
          label: 'Ultimate Controller Name',
        },
        holdRat: {
          label: 'Ultimate Controller’s Ownership Ratio',
        },
      },
      rmInformation: {
        cardTitle: 'RM Information',
        rmCode: {
          label: 'RM code',
        },
        rmName: {
          label: 'RM Name',
        },
        rmSellerCode: {
          label: 'Seller Code',
        },
        rmDepName: {
          label: 'Department',
        },
        belgSectionName: {
          label: 'Section Head',
        },
        belgTeamName: {
          label: 'Team Head',
        },
      },
    },
    doc: {
      cardTitle: 'Document',
      tableColumns: {
        certCountry: {
          label: 'Document Issued Country',
        },
        certType: {
          label: 'Document Type',
        },
        certCode: {
          label: 'Document Number',
        },
        certBegDate: {
          label: 'Document Issue Date',
        },
        certValidDate: {
          label: 'Document Expiry Date',
        },
        remark: {
          label: 'Remark',
        },
      },
    },
    ultCon: {
      cardTitle: 'Ultimate Controller',
      tableColumns: {
        ultmCtrlrId: {
          label: 'Ultimate Controller Number',
        },
        ultmCtrlrNameEn: {
          label: 'Ultimate Controller Name',
        },
        ultmCtrlrNameCh: {
          label: 'Ultimate Controller Name (Chinese)',
        },
        ultmCtrlrGrmEname: {
          label: 'GRM',
        },
        grmDep: {
          label: 'Department',
        },
      },
    },
    contacts: {
      cardTitle: 'Contact',
      tableColumns: {
        contactName: {
          label: 'Contact Person Name',
        },
        title: {
          label: 'Title',
        },
        contactTelNo: {
          label: 'Telephone',
        },
        contactPhoneNo: {
          label: 'Mobile',
        },
        email: {
          label: 'Email',
        },
      },
    },
    shareholder: {
      cardTitle: 'Shareholder Information',
      tableColumns: {
        relCustId: {
          label: 'Customer Number',
        },
        relCustEname: {
          label: 'Customer Name',
        },
        relCustName: {
          label: 'Customer Name (Chinese)',
        },
        isMgr: {
          label: 'Is Management',
        },
        invPerc: {
          label: 'Shareholding Percentage (%)',
        },
      },
    },
    reCompany: {
      cardTitle: 'Related Company Information',
      tableColumns: {
        mangCustId: {
          label: 'Customer Number',
        },
        mangCustEname: {
          label: 'Customer Name',
        },
        mangCustName: {
          label: 'Customer Name (Chinese)',
        },
        relType: {
          label: 'Relationship Type',
        },
      },
    },
    reMgt: {
      cardTitle: 'Related Management Information',
      tableColumns: {
        mngCustId: {
          label: 'Customer Number',
        },
        mngCustEname: {
          label: 'Customer Name',
        },
        mngCustNameCh: {
          label: 'Customer Name (Chinese)',
        },
        relType: {
          label: 'Management Type',
        },
        isShareholder: {
          label: 'Shareholder',
        },
      },
    },
  },
  groupInfo: {
    dialogTitle: 'Ultimate Information Detail',
    basic: {
      cardTable: 'Basic',
      cardTitle: 'Basic Information',
      ultmCtrlrId: {
        label: 'Ultimate Controller Number',
      },
      ultmCtrlrNameEn: {
        label: 'Ultimate Controller Name',
      },
      ultmCtrlrNameCh: {
        label: 'Ultimate Controller Name (Chinese)',
      },
      onebankRegion: {
        label: 'OneBank Region',
      },
      grmRegion: {
        label: 'GRM Region',
      },
      ultmCtrlrGrmUnit: {
        label: 'GRM Unit',
      },
      grmcontact: {
        label: 'GRM Contact',
      },
      grm2ndContact: {
        label: 'GRM 2nd Contact',
      },
      ultmCtrlrLrmEname: {
        label: 'LRM',
      },
    },
    subsidiaries: {
      cardTable: 'Subsidiaries',
      cardTitle: 'Subsidiaries',
      tableColumns: {
        custId: {
          label: 'Customer Number',
        },
        custEname: {
          label: 'Customer Name',
        },
        custName: {
          label: 'Customer Name (Chinese)',
        },
        certType: {
          label: 'Document Type',
        },
        certCode: {
          label: 'Document ID',
        },
        rmName: {
          label: 'RM',
        },
      },
    },
  },
  elMessageTip: {
    certType: 'Please select the certType first',
    department: 'Please choose the Department',
    sectionHead: 'Please choose the Section Head',
    teamHead: 'Please choose the Team Head',
    selectOneUser: 'Please select at least one user',
  },
  attention: {
    tabs1: 'Customer',
    tabs2: 'Ultimate Controller',
    editSucc: 'Unfollowing successfully',
    confirmText: 'Whether to unfollow the customer ?',
    tableColumns: {
      grmUnit: 'GRM Unit',
      grmContact: 'GRM Contact',
      numOfSub: 'Number of Subsidiaries',
    },
  },
};
