export default {
  notice: {
    noticeTitle: '公告標題',
    noticeType: '公告分類',
    noticeStatus: '公告狀態',
    createdDate: '創建日期',
    releaseDate: '發佈日期',
    invalidDate: '到期日期',
    effectiveTime: '有效期',
    action: '操作',
    recall: '下架',
    addTitle: '新增公告',
    modifyTitle: '修改公告',
    viewTitle: '公告詳情',
    notificationObject: '通知對象',
    selectObject: '選擇對象',
    object: '對象',
    releaseMethod: '發佈方式',
    noticeContent: '詳情',
    uploadFile: '附件',
    maintenancePersonnel: '創建人',
    maintenanceTime: '創建時間',
    downloadFile: '文件',
    assignAppr: '分配審批人',
    assignTo: '分配给',
    yesAssignAppr: '是',
    noAssignAppr: '否',
    haveNoFile: '無',
    // 消息提示
    deleteMessage: '是否確認刪除',
    deleteMessageOne: '請選擇需要刪除的公告',
    deleteMessageTwo: '確認刪除所選的',
    deleteMessageThree: '條公告嗎？',
    deleteMessageFour: '條公告嗎？',
    objectMessagedOne: '請先選擇通知對象',
    objectMessagedTwo: '請選擇對象',
    addSuccess: '新增成功！',
    modifySuccess: '修改成功！',
    submitSuccess: '提交成功！',
    saveSuccess: '保存成功！',
    optionSuccess: '操作成功！',
    tipTitle: '提示',
    offshelfMessage: '公告下架後不可重新編輯發佈，是否下架該公告？',
    offshelfMessageOne: '請選擇需要下架的公告',
    offshelfMessageTwo: '確認下架所選的',
    offshelfMessageThree: '條公告嗎？',
    offshelfMessageFour: '條公告嗎？',
    // 表單驗證
    titleForm: '請輸入公告標題',
    typeForm: '請選擇公告類型',
    releaseForm: '請選擇發佈時間',
    releaseDateForm: '請選擇發佈時間',
    invalidDateForm: '請選擇到期時間',
    noticeObjForm: '請選擇通知對象',
    objListForm: '選擇對象',
    contentForm: '請輸入公告內容',
    assignApprForm: '請選擇是否分配審批人',
    selectApprForm: '請選擇審批人',
  }
};
