export default {
  bireport: {
    reportName:'报表名称',
    reportID:'报表ID',
    reportURL:'报表URL',
    remark:'备注',
    sortNo:'排序',
    manage:'管理',
  },
};
