export default {
    leftTitle: '指標目錄管理',
    rightTitle: '當前目錄詳情',
    msg: {
      title: '提示',
      delSelect:'請選擇要刪除的指標目錄',
      delConfirm: '確認刪除所選目錄?',
      delConfirmChild:'所選菜單存在子目錄,無法刪除'
    },
    form: {
      kindId: {
        label: '指標分類Id',
      },
      kindName: { label: '指標分類名稱' },
      parentKindId: { label: '上級菜單ID' },
      parentKindNm: { label: '上級菜單名稱' },
      sortNo: { label: '排序號' },
    },
    tips: {
      add: '添加成功!',
      modify: '修改成功!',
      del: '刪除成功!',
      cancel:'操作已取消',
      delSelect:'請選擇需要刪除的項',
      delConfirm:'確認刪除以下指標嗎'
    },
    index: {
      leftTitle: '指標分類',
      placeHolder: {
        indexSearch: '輸入關鍵字進行搜索', // 输入关键字进行搜索
      },
      form:{
        indexId:'指標代碼',
        indexName:'指標名稱',
        calcType:'計算類型',
        indexType:'指標類型',
        unit:'指標單位',
        indexMeas:'指標度量',
        indexNumProp:'指標屬性',
        dataFrom:'數據來源',
        isOpen:'是否啟用',
        fitObj: '指標維度',
        howDecimal: '精確位數',
        isHot: '是否熱銷',
        indexDesc: '指標描述',
        formula: '計算公式',
        operator: '運算符',
        selectIndex: '選擇指標',
        ruleTable: '數據源表',
        fieldName: '來源表對應字段',
        calcMode: '歸集方式',
        whereSql: '自定義SQL',
        back: '上一步',
        next: '下一步',
      },
      formRules: {
        indexId: '請輸入指標代碼',
        indexName: '請輸入指標名稱',
        indexType: '請選擇指標性質',
        fitObj: '請選擇指標緯度',
        unit: '請選擇指標單位',
        indexMeas: '請選擇指標度量',
        indexNumProp: '請選擇數值屬性',
        dataFrom: '請選擇數據來源',
        isOpen: '請選擇是否有效',
        howDecimal: '請選擇精確位數',
        calcType: '請選擇計算類型',
        isHot: '請選擇是否熱銷',
        formula: '請輸入計算公式',
        calcMode: '請選擇歸集方式',
        ruleTableId: '請選擇數據源表',
        fieldNameEn: '請選擇來源表對應字段',
      },
      dialog: {
        dialogTitle: {
          mod: '修改指標',
          add: '新增指標',
        },
        form: {
          stepOne: '配置指標定義',
          stepTwo: '配置指標規則',
        }
      },
    },
  };