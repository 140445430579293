import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" 分页组件 "), _createVNode(_component_el_pagination, {
    background: true,
    "current-page": $props.page.pageNum,
    "page-size": $props.page.pageSize,
    "page-sizes": [10, 20, 50, 100],
    total: $props.page.total,
    layout: "total, sizes, prev, pager, next, jumper",
    onSizeChange: $setup.handleSizeChange,
    onCurrentChange: $setup.handleCurrentChange
  }, null, 8 /* PROPS */, ["current-page", "page-size", "total"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}