export default {
  mainInfo: {
    title: {
      work: '我的工作',
      tools: '工具台',
      dashboar: '經營儀表盤',
      performance: '業務構成分析',
      followedCustomers:'關註客戶',
      alerts: '事件提醒',
      calendar: '月歷展示',
      bulletin: '公告欄',
      tasks:'待辦任務',
    },
    tools: {
      logTitle: '工具台配置'
    },
    myWork: {
      toDoTasks: '待辦任務',
      messageReminder: '消息提醒',
      myApproval: '我的審批',
      mySchedule:'我的日程'
    },
    mgkpiIndex: {
      comparisonValue: {
        lastMonth: '較上月末',
        lastYear: '較上年末',
      }
    },
    mgkpiMore: {
      moreTitle: '儀表盤重要指標配置（幣種：人民幣）',
      moreTitleTips: '最多可展示6個指標',
    },
    
    calendar: {
      today: '今天',
      selectLabel: {
        chn: '中國',
        hk: '香港',
        us: '美國',
      },
    },
    planPressInfo: {
      echartsLabel: {
        income: '收入',
        loans: '貸款',
        deposits: '存款',
      },
      echartsQueryParam: {
        timeframe: '周期類型',
        calculationType: '計算類型',
        balanceType:'余額類型',
      },
      custLbael: '客戶排名',
      incomeTableLabel: {
        index: '排名',
        custNum: '客戶編號',
        custName: '客戶名稱',
        currentPeriodA: '本年收入',
        incomeMTD: '本月收入',
        incomeQTD: '本季度收入',
        incomeYTD: '本年收入 (YTD)',
        incomeLastMTD: '上月收入',
        incomeLastQTD: '上季度收入',
        incomeLastYTD: '上年收入 (YTD)',
        loanMTD: '月均余額 (本月)',
        loanYTD: '年均余額 (本月)',
        loanPeEnd: '期末余額(本期)',
        loanBalLastMTD: '月均余額 (去年同月)',
        loanBalLastYTD: '年均余額 (去年同月)',
        loanBalLastPeEnd: '期末余額 (去年年底)',
        depositMTD: '月均余額 (本月)',
        depositYTD: '年均余額 (本月)',
        depositPeEnd: '期末余額 (本期)',
        depositLastMTD: '月均余額 (去年同月)',
        depositLastYTD: '年均余額 (去年同月)',
        depositLastPeEnd: '期末余額 (去年年底)',
      }
    },
    taskRadio: {
      myReceivedTasks: '我收到的待辦',
      myCreatedTasks:'我發起的待辦'
    },
    task:{
      index: '序號',
      taskName: '任務名稱',
      taskStatus: '任務狀態',
      taskLevel: '重要程度',
      receivedDate: '接收日期',
      createdDate: '創建日期',
      taskEndDate: '任務周期',
      taskCreatorName: '發起人',
      taskRoleName: '發起人崗位',
      loansCreatorName: '發起人',
      loansRoleName: '發起人崗位',
      moreLogTitle: '詳情',
      moreLogTableTitle: '反饋記錄詳情',
    },
    alerts: {
      tableLabel: {
        index: '序號',
        eventType: '事件名稱',
        remindDate: '提醒日期',
        stauts: '閲讀狀態'
      },
      moreSreach: {
        custName: '客戶名稱：',
        custNo: '客戶編號：',
      },
      moreTableLabel: {
        index: '序號',
        eventType: '事件分類',
        remindDate: '提醒日期',
        custEname: '客戶名稱',
        custId: '客戶號',
        accountNo: '賬戶號',
        transacNo: '交易號',
        amount: '金額（『000HKE）',
        operation: '操作',
        view: '查看詳情'
      },
      viewLabel: {
        operationDate: '操作日期',
        operationTime: '操作時間',
        transCurrency: '交易貨幣',
        transAmount: '交易金額',
        operationKey: '操作鍵',
        operationKeyDes: '操作鍵簡述',
        accountNumber: '帳號',
        currency:'貨幣'
      },
      
    },
    workStatement: {
      tabsLabel: {
        report: '工作報告',
        schedule: '我的日程',
      },
      workStatementAdd: {
        title: '新增日程',
        scheduleTitle: '日程標題',
        scheduleDate: '日程日期',
        timeRange: ' 起止時間',
        address: '地點',
        relatedCustomers: '關聯客戶',
        scheduleContent: '日程內容',
        confirmTitle: '提示',
        confirmEvent: '是否確認刪除該日程？',
        confirmUndone: '刪除操作無法撤銷。'
      },
      placeholder: {
        scheduleTitle: '请输入',
        scheduleDate: '日期選擇器，到日',
        timeRange: ' 起止時間',
        startPlaceholder: '请選擇开始时间，时间選擇器',
        endPlaceholder: '请選擇截至时间，精确到分',
        address: '请输入',
        relatedCustomers: '请输入',
        scheduleContent: '请输入',
      },
      rules: {
        scheduleTitle: '请填写日程標題',
        scheduleDate: '请選擇日程日期',
        timeArr: ' 请選擇起止时间',
        address: '请填写地點',
      },
      port: {
        success: '日程新增成功!',
        edit: '日程修改成功!',
        error: '日程新增失敗!',
        del: '刪除成功!',
        msg: '該日程已超過有效期！',
        warn: '結束時間小於當前時間，請重新選擇',
      }
    },
    button: {
      other: '其他',
    },
    tips:{
      indexSuccess: '',
      indexError: '',
      indexWarning: '',
      kpiMore1: '经营仪表盘不能少于1个指标',
      kpiMore6: '经营仪表盘最多只能展示6个指标',
      kpiIndexDel: '成功移除指標',
      addSuccess: '操作成功',
    }
  },
  information: {
    button: {
      Search: '查詢',
      Resetting: '重置',
      Add: '新增',
      Delete: '刪除',
      Refresh: '刷新緩存',
      TpDownload: '模板下載',
      Import: '導入',
      Cancel: '取消',
      Determine: '確定',
      Update: '修改',
      Export: '導出',
    },
    title: {
      ultimateController: '集團名稱',
      borrowerGrade: '信貸評級',
      loanClassification: '五級分類',
      income: '收入',
      asOfDate: '統計日期',
      dod: '較上日',
      mom: '較上月',
      momEnd:'較上月末',
      yoy: '較上年同期',
      laYeEn: '較上年末',
      loan: '貸款',
      deposit: '存款',
    },
    /* 
            mileston:'合作裏程碑',
            business:'業務信息',
            management:'管理信息',
            attribute:'屬性信息' */
    summary: {
      summary: '概要信息',
      basic: {
        basic: '基本信息',
        basicInformation: '基本信息',
        customerName: '客戶名稱 (英)',
        customerCName:'客戶名稱(中)',
        priPhNo:'主要電話號碼',
        industry: '行業',
        dateOfEstablishment: '成立日期',
        businessAddress: '營業地址',
        countryOfOperation: '運營國家',
        officePhoneNumber: '辦公室電話號碼',
        mobile: '手機',
        email: '電子郵件',
      },
      document: {
        document: '證件',
        documentInfomation: '證件信息',
        documentIssuedCountry: '證件簽發國家',
        documentType: '證件類型',
        documentID: '證件號',
        issueDate: '簽發日期',
        expiryDate: '到期日期',
        mobile: '手機',
        email: '電子郵件',
      },
      income: {
        income: '收入',
        asOfDate: '統計日期',
        incomeYTD: '收入 (本年至今)',
        lastYearTotal: '去年總計',
        dod: '較上日',
        mom: '較上月',
        yoy: '較上年同期',
        incomeBreakdown: 'YTD收入構成',
        incomeLineChart: '月度收入趨勢',
      },
      loans: {
        loans: '貸款',
        asOfDate: '統計日期',
        loanBalance: '貸款余額',
        lbatPreviousYearEnd: '上年末貸款余額',
        mothlyAverge: '月平均值',
        yearlyAverge: '年平均值',
        momEnd:'較上月末',
        laYeEn: '較上年末',
        loanBreakdown: '貸款構成',
        loanLineChart: '貸款月均趨勢',
      },
      deposits: {
        deposits: '存款',
        asOfDate: '統計日期',
        depositBalance: '存款余額',
        dbatPreviousYearEnd: '上年末存款余額',
        mothlyAverge: '月平均值',
        yearlyAverge: '年平均值',
        momEnd:'較上月末',
        laYeEn: '較上年末',
        depositBreakdown: '存款構成',
        depositLineChart: '存款月均趨勢',
      },
    },
    milestone: {
      milestone: '合作裏程碑',
      dataRange: '數據範圍',
      to: '至',
    },
    business: {
      business: '業務信息',
      income: '收入',
      loan: '貸款',
      deposit: '存款',
      productType: '產品類型',
      selectProductType: '請選擇產品類型',
      calculationType: '計算類型',
      selectCalculationType: '請選擇計算類型',
      dateRange: '日期範圍',
      endDate: '結束時間',
      incomeDetails: '收入明細',
      incomeType: '收入類型',
      selincomeType: '請選擇收入類型',
      seltransactionMonth: '請選擇交易月',
      transactionMonth: '交易月',
      transactionMonthF: '交易月（從）',
      transactionMonthT: '交易月（到）',
      loanType: '貸款類型',
      selectLoanType: '請選擇貸款類型',
      loanBalance: '貸款余額',
      selectLoanBalance: '請選擇貸款余額',
      balance: '余額',
      selectBalance: '請選擇余額',
      basicInfo: '基本信息',
      loanDetails:'貸款明細',
      depositDetails:'存款明細',
      product: '產品',
      periodEndBalanceM: '月累計金額 (HKE)',
      MonthlyAveBal: '年累計金額 (HKE)',
      YTDAveBal: '本年至今平均余額',
      netIntIncome: '凈利息收入',
      nonNetIntIncome: '非凈利息收入',
      department: '部門',
      rmName: 'RM名稱',
      depName: '部門',
      loanNo: '貸款合同號',
      tradeRefNo: '交易號',
      transDate: '交易日期',
      currency: '幣種',
      amount: '交易金額',
      exchangeRate: '匯率',
      AmountHKE: '交易金額 (HKE)',
      baseRate: '基本利率',
      effectiveRate: '有效利率',
      spread: '貸款利差',
      RWA: '風險加權資產',
      loanClassif: '五級分類',
      pastDueAmount: '上次到期金額',
      depositType: '存款類型',
      dpstAcctNo: '存款賬號',
      accountNo: '賬戶編號',
      accountNum: '賬戶號碼',
      accType: '賬號類型',
      accountName: '賬戶名稱',
      timeDepNo: '定期存款編號',
      minAmountHKE: '最小余額 (HKE)',
      maxAmountHke: '最大余額 (HKE)',
      avgAmountHke: '平均余額 (HKE)',
      interestRate: '利率  (%)',
      accountStatus: '賬戶狀態',
      tenor: '存期',
      interestCurrent: '利息幣種',
      regularInterest: '定期利息',
      startDate: '開始日期',
      maturityDate: '到期日期',
      callDpstIndt: '通知存款標誌',
      particularInterest: '特定利率',
      openAccDt:'開戶日期',
      closAccDt:'銷戶日期',
      accountOpenDate: '開戶日期',
      callDepInd: '現金存款指標',
      matInstruction: '到期指示',
      greenDeposit: '綠色存款',
      preferRate: '優惠稅率  (%)',
      bonusCASA: '獎金CASA',
      action: '操作',
      transactionDtails: '交易',
      creditUseAmt: '信用已用金額',
      creditAmt: '信用額度',
      expCreditAmt: '風險信用額度',
      creditBal: '信用剩余金額',
      incomeTypeCheck: '請先選擇產品類型',
      operationDate:'操作日期',
      operationTime:'操作時間',
      transCurrency:'交易幣種',
      transAmount:'交易金額',
      operationKey:'操作鍵',
      operationKeyShDe:'操作鍵簡述',
    },
    management: {
      management: '管理',
      address: {
        address:'地址信息',
        registeredAddress: '註冊地址',
        registAddrInfo: '註冊地址信息',
        countryOfReg: '註冊國家',
        operationAddrInfo: '運營地址信息',
        operationAddr: '運營地址',
        countryOfOpera: '運營國家',
      },
      ulmimateCtrl: {
        ulmimateCtrl: '集團',
        ulmimateCtrlInfo: '集團信息',
        ulCtrlNum: '集團號',
        ulCtrlName: '集團名稱 (英)',
        ulCtrlNameCN: '集團名稱 (中文)',
        GRMInfo: 'OneBank信息',
        oneBankReg: 'OneBank所屬區域',
        GRMReg: 'GRM所屬區域',
        GRMU: 'GRM歸屬',
        GRMContact: 'GRM',
        GRMContactSecond: '第二GRM',
        GLCenterCode: 'GL中心代碼',
        LRMInfo: 'LRM信息',
        LRM: 'LRM',
      },
      RM: {
        RM: 'RM',
        rmInformation: 'RM信息',
        RMInfo: 'RM名稱',
        department: '部門',
        teamHead: '團隊負責人',
        sectionHead: '分部負責人',
        relationshipManager: '關系經理',
        rmCode: 'RM編碼',
        rmSellerCode: '銷售編碼'
      },
    },
    attribute: {
      attribute: '資本信息',
      basicInfo: '基本信息',
      registCapital: '註冊資本',
      paidInCapital: '實收資本',
      registAddress: '註冊地址',
      registCurrency:'註冊資本幣種',
      paidInCurrency:'實收資本幣種'
    },
  },
};
