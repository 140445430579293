import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "breadcrumb-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, {
    class: "app-breadcrumb",
    "separator-class": "el-icon-arrow-right"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.breadcrumbs, item => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: item.menuId
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.menuName), 1 /* TEXT */)]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  })]);
}