import $http from '@/utils/http/index';
import { HttpListQuery } from '@/utils/http/http-type';

/**
 * 1-14 菜单
 * @param params 请求参数
 * @returns
 */
// 获取下一级orgId
export const getNextOrgId = (params: any) => {
  return $http.post('/sys/org/getNextOrgId', params);
};
//前端菜单列表
export const getAuthMenuList = () => {
  return $http.post('/sys/menu/listMenuByAuth');
};
// 查询树型list
export const getMenuList = (params: HttpListQuery) => {
  return $http.post('/sys/menu/list', params);
};
//通过Id获取菜单对象
export const getMenu = (params: HttpListQuery) => {
  return $http.post('/sys/menu/get', params);
};
//新增报存
export const addMenu = (params: HttpListQuery) => {
  return $http.post('/sys/menu/save', params);
};
//修改
export const updateMenu = (params: HttpListQuery) => {
  return $http.post('/sys/menu/update', params);
};
//删除
export const deleteMenu = (params: HttpListQuery) => {
  return $http.post('/sys/menu/delete', params);
};

/**
 * 1-01 系统登录
 * @param params 请求参数
 * @returns
 */
//系统登录
export const login = (params: HttpListQuery) => {
  return $http.post('/sys/login', { ...params });
};
//获取角色列表
export const getRoleList = () => {
  return $http.post('/sys/getRoleList');
};
//角色切换
export const changeRole = (params: HttpListQuery) => {
  return $http.post('/sys/changeRole', params);
};
//超时15分钟，刷新token
export const refreshToken = () => {
  return $http.post('/sys/refresh');
};
/**
 * 修改密码
 * @returns
 */
export const updatePassword = (params: HttpListQuery) => {
  return $http.post('/sys/updatePassword', params);
};

/**
 * 1-02 获取缓存码值
 * @param params 请求参数
 * @returns
 */
export const getListCombo = (params: HttpListQuery) => {
  return $http.post('/sys/cache/listCombo', params);
};

/**
 * 1-17 角色
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const queryRole = (params: HttpListQuery) => {
  return $http.post('/sys/role/list', params);
};
//查看
export const getRole = (params: HttpListQuery) => {
  return $http.post('/sys/role/get', params);
};
//新增保存
export const addRoleSave = (params: HttpListQuery) => {
  return $http.post('/sys/role/save', params);
};
//修改
export const updateRole = (params: HttpListQuery) => {
  return $http.post('/sys/role/update', params);
};
//删除
export const deleteRole = (params: HttpListQuery) => {
  return $http.post('/sys/role/delete', params);
};
//角色启用
export const changeStsRole = (params: HttpListQuery) => {
  return $http.post('/sys/role/changeSts', params);
};

//批量配置角色
export const queryOwnRoleList = (params: HttpListQuery) => {
  return $http.post('/sys/user/ownRoleList', params);
};
export const queryNotOwnRoleList = (params: HttpListQuery) => {
  return $http.post('/sys/user/notOwnRoleList', params);
};
export const removeRoleUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/batchRemoveRole', params);
};
export const addRoleUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/batchAddRole', params);
};
/**
 * 1-18 角色操作权限
 * @param params 请求参数
 * @returns
 */
//查询树型list
export const authRoleList = (params: HttpListQuery) => {
  return $http.post('/sys/roleAuth/list', params);
};
//新增保存
export const authRoleSave = (params: HttpListQuery) => {
  return $http.post('/sys/roleAuth/save', params);
};

/**
 * 1-19 数据权限
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const roleDataList = (params: HttpListQuery) => {
  return $http.post('/sys/roleData/list', params);
};
//查看
export const roleDataGet = (params: HttpListQuery) => {
  return $http.get('/sys/roleData/get', params);
};
//新增保存
export const addRoleData = (params: HttpListQuery) => {
  return $http.post('/sys/roleData/save', params);
};
//修改
export const updateRoleData = (params: HttpListQuery) => {
  return $http.post('/sys/roleData/update', params);
};
//删除
export const deleteRoleData = (params: HttpListQuery) => {
  return $http.post('/sys/roleData/delete', params);
};

/**
 * 1-15 功能
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const getFuncList = (params: HttpListQuery) => {
  return $http.post('/sys/func/list', params);
};
//查看
export const getFunc = (params: HttpListQuery) => {
  return $http.post('/sys/func/get', params);
};
//新增保存
export const addFuncSave = (params: HttpListQuery) => {
  return $http.post('/sys/func/save', params);
};
//修改
export const updateFunc = (params: HttpListQuery) => {
  return $http.post('/sys/func/update', params);
};
//删除
export const deleteFunc = (params: HttpListQuery) => {
  return $http.post('/sys/func/delete', params);
};

/**
 * 1-12 代码类别
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const getCodeTypeList = (params: HttpListQuery) => {
  return $http.post('/sys/codeType/list', params);
};
//查看
export const getCodeType = (params: HttpListQuery) => {
  return $http.post('/sys/codeType/get', params);
};
//新增和保存
export const codeTypeSave = (params: HttpListQuery) => {
  return $http.post('/sys/codeType/save', params);
};
//修改
export const updateCodeType = (params: HttpListQuery) => {
  return $http.post('/sys/codeType/update', params);
};
//删除
export const delCodeType = (params: HttpListQuery) => {
  return $http.post('/sys/codeType/delete', params);
};
//获取码值类型下拉框
export const comboCodeType = () => {
  return $http.post('/sys/codeType/listCombo');
};
export const importCodeFile = (params: HttpListQuery,type:string) => {
  return $http.post('/sys/codeType/importFile/'+type, params);
};
/**
 * 1-13 代码信息
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const getCodeInfoList = (params: HttpListQuery) => {
  return $http.post('/sys/codeInfo/list', params);
};
//查看
export const getCodeInfo = (params: HttpListQuery) => {
  return $http.post('/sys/codeInfo/get', params);
};
//新增保存
export const codeInfoSave = (params: HttpListQuery) => {
  return $http.post('/sys/codeInfo/save', params);
};
//修改
export const updateCodeInfo = (params: HttpListQuery) => {
  return $http.post('/sys/codeInfo/update', params);
};
//删除
export const delCodeInfo = (params: HttpListQuery) => {
  return $http.post('/sys/codeInfo/delete', params);
};

/**
 * 1-16 机构
 * @param params 请求参数
 * @returns
 */
//查询树型list
export const getOrgList = (params: HttpListQuery) => {
  return $http.post('sys/org/list', params);
};
// 查询树型list，包括RM
export const queryOrgTree = (params: HttpListQuery) => {
  return $http.post('/cip/comp/queryOrgTree', params);
};
// 获取业绩查看机构RM树
export const getOrgRmTreeList = (params: HttpListQuery) => {
  return $http.post<any[]>('/sys/org/orgRmlist', params);
};
// 获取业绩查看机构层级和角色信息
export const getOrgLevelAndRole = () => {
  return $http.post('/sys/org/orgLevelAndRole');
};
// 根据层级和id获取机构数树
export const getLevelOrgList = (params: HttpListQuery) => {
  return $http.post('/sys/org/listLevelOrg', params);
};
// 根据层级和id获取机构数列表
export const getOrgInfoList = (params: HttpListQuery) => {
  return $http.post('/sys/org/orgInfoList', params);
};
//查询有权限的机构
export const getTableAuthTree = (params: HttpListQuery) => {
  return $http.post('/sys/org/getTableAuthTree', params);
};
//根据权限范围,查询有权限的机构
export const getScopeTree = (params: HttpListQuery) => {
  return $http.post('/sys/org/getScopeTree', params);
};
//查询树型list-到支行级别
export const getTopOrg = () => {
  return $http.post('/wp/workLog/getTopOrg');
};
//查询数据权限树
export const getAuthScopeTree = (params: HttpListQuery) => {
  return $http.post('sys/org/getAuthScopeTree', params);
};
//根据id获取机构对象
export const getOrgById = (params: HttpListQuery) => {
  return $http.post('sys/org/get', params);
};
//删除
export const delOrg = (params: HttpListQuery) => {
  return $http.post('/sys/org/delete', params);
};
//修改
export const updOrg = (params: HttpListQuery) => {
  return $http.post('/sys/org/update', params);
};
//获取当前登录角色的管辖机构
export const queryAuthOrg = (params: HttpListQuery) => {
  return $http.post('/sys/org/queryAuthOrg', params);
};
//新增保存
export const addOrg = (params: HttpListQuery) => {
  return $http.post('/sys/org/save', params);
};
//查询树型list-到支行级别
export const queryOrgEnd = (params: HttpListQuery) => {
  return $http.post('sys/org/queryOrgEnd', params);
};

//查询树型list-到支行级别
export const list2ndOrg = (params: HttpListQuery) => {
  return $http.post('sys/org/list2ndOrg', params);
};

//查询树型list-到支行级别
export const list3rdOrg = (params: HttpListQuery) => {
  return $http.post('sys/org/list3rdOrg', params);
};
//查询树型list
export const getAuthTreeOrg = (params: HttpListQuery) => {
  return $http.post('sys/org/getAuthTree', params);
};
/**
 * 1-20 用户
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const getUserList = (params: HttpListQuery) => {
  return $http.post('/sys/user/list', params);
};
//查询和分页
export const getUserInfoList = (params: HttpListQuery) => {
  return $http.post('/sys/user/queryUserInfo', params);
};
//tableName查询有权限的机构
export const getAuthList = (params: HttpListQuery) => {
  return $http.post('/sys/user/authList', params);
};
//根据权限范围,查询有权限的机构
export const getScopeList = (params: HttpListQuery) => {
  return $http.post('/sys/user/scopeList', params);
};
//新增保存
export const addUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/save', params);
};
//修改
export const updUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/update', params);
};
//删除
export const delUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/delete', params);
};
//客户经理分页和查询
export const getCustMgrList = (params: HttpListQuery) => {
  return $http.post('/sys/user/getCustMgrList', params);
};
//经理分页和查询
export const getByRoles = (params: HttpListQuery) => {
  return $http.post('/sys/user/getByRoles', params);
};
//获取当前登录人的管辖用户
export const queryAuthUser = (params: HttpListQuery) => {
  return $http.post('/sys/user/queryAuthUser', params);
};
//根据ID获取用户详情
export const getUser = (params: HttpListQuery) => {
  return $http.get('/sys/user/get', params);
};
//重置密码
export const resetPwd = (params: HttpListQuery) => {
  return $http.get('/sys/user/resetPwd', params);
};
export const resetPassWord = (params: HttpListQuery) => {
  return $http.post('/sys/user/resetPassWord', params);
};
//设置用户状态
export const updateStates = (params: HttpListQuery) => {
  return $http.post('/sys/user/updataStatus', params);
};
// 通过部门或分部或团队id获取用户列表
export const listAllRM = (params: HttpListQuery) => {
  return $http.post('/sys/team/listAllRM', params);
};
// 通过所属机构的id获取rmCode列表
export const listNoPage = (params: HttpListQuery) => {
  return $http.post('/sys/team/listNoPage', params);
};
// 查询当前用户机构信息
export const getLoginUserInfo = (params: HttpListQuery) => {
  return $http.post('/sys/user/getLoginUserInfo', params);
};

/**
 * 1-21 登录日志/历史
 * @param params 请求参数
 * @returns
 */
export const getLogList = (params: HttpListQuery) => {
  return $http.post('/sys/log/list', params);
};
// 访问量统计
export const listVisitsStat = (params: HttpListQuery) => {
  return $http.post('/sys/log/listVisitsStat', params);
};
// 月活统计
export const listMonthActivityStat = (params: HttpListQuery) => {
  return $http.post('/sys/log/listMonthActivityStat', params);
};

/**
 * 1-22 操作日志/历史
 * @param params 请求参数
 * @returns
 */
export const getOperLogList = (params: HttpListQuery) => {
  return $http.post('/sys/operLog/list', params);
};
export const getOperLogListNew = (params: HttpListQuery) => {
  return $http.post('/sys/operLog/listNew', params);
};
export const listModuleAnalysis = (params: HttpListQuery) => {
  return $http.post('/sys/operLog/listModuleAnalysis', params);
};

/**
 * 1-11 系统参数
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const getConfigData = (params: HttpListQuery) => {
  return $http.post('/sys/config/list', params);
};
//新增保存
export const getConfig = (params: HttpListQuery) => {
  return $http.post('/sys/config/get', params);
};
//新增保存
export const addConfig = (params: HttpListQuery) => {
  return $http.post('/sys/config/save', params);
};
//修改
export const updConfig = (params: HttpListQuery) => {
  return $http.post('/sys/config/update', params);
};
//删除
export const delConfig = (params: HttpListQuery) => {
  return $http.post('/sys/config/delete', params);
};

/**
 * 1-18 文件下载
 * @param params 请求参数
 * @returns
 */
//查询
export const getFileList = (params: HttpListQuery) => {
  return $http.post('/sys/file/list', params, false);
};
//图片URL
export const getImgUrl = (params: HttpListQuery) => {
  return $http.post('/sys/file/imgUrl', params);
};
//文件下载
export const downLoadFile = (params: HttpListQuery) => {
  return $http.post('/sys/file/download', params);
};
//文件删除
export const deleteFile = (params: HttpListQuery) => {
  return $http.post('/sys/file/delete', params);
};
// 文件删除依据文件名称和路径
export const deleteFileOnly = (params: HttpListQuery) => {
  return $http.post('/sys/file/delByName', params);
};
// 文件删除 (新接口)
export const delFile = (params: HttpListQuery) => {
  return $http.post('/file/delFile', params);
};
//获取id
export const getBizId = () => {
  return $http.post('/sys/file/getId');
};

// export function getDepCal(params: { saveMoney: number; investDay: number; depRate: number; interest: number; sumMoney: number; }): { data: any; }|PromiseLike<{ data: any; }> {
//   throw new Error("Function not implemented.");
// }

//贷款计算器
export const getLoanCal = (params: HttpListQuery) => {
  return $http.post('/sys/calculator/loanCal/loanEqual', params);
};
//定期存款计算器
export const getDepCal = (params: HttpListQuery) => {
  return $http.post('/sys/calculator/depCal/cal', params);
};
//基金赎回计算器
export const getFundRedeemCal = (params: HttpListQuery) => {
  return $http.post('/sys/calculator/fundRedeemCal/cal', params);
};
//基金申购认购计算器
export const getFundApplyCal = (params: HttpListQuery) => {
  return $http.post('/sys/calculator/fundApplyCal/fundApplyCal', params);
};
//理财产品收益计算器
export const getWealthCal = (params: HttpListQuery) => {
  return $http.post('/sys/calculator/wealthCal/wealthCal', params);
};

/**
 * 手工标签管理模块接口
 * @param params 请求参数
 * @returns
 */
export const getTagHand = (params: HttpListQuery) => {
  return $http.get('/sys/tagHand/get', params);
};
export const queryTagHand = (params: HttpListQuery) => {
  return $http.post('/sys/tagHand/list', params);
};
export const addTagHand = (params: HttpListQuery) => {
  return $http.post('/sys/tagHand/save', params);
};
export const updateTagHand = (params: HttpListQuery) => {
  return $http.post('/sys/tagHand/update', params);
};
export const deleteTagHand = (params: HttpListQuery) => {
  return $http.post('/sys/tagHand/delete', params);
};

/**
 * 公告管理模块接口
 * @param params 请求参数
 * @returns
 */
export const getNotice = (params: HttpListQuery) => {
  return $http.post('/sys/notice/get', params);
};
export const getNoticeByRead = (params: HttpListQuery) => {
  return $http.post('/sys/notice/getByRead', params);
};
export const queryNotice = (params: HttpListQuery) => {
  return $http.post('/sys/notice/list', params);
};
export const queryNoticeForUser = (params: HttpListQuery) => {
  return $http.post('/sys/notice/userList', params);
};
export const addNotice = (params: HttpListQuery) => {
  return $http.post('/sys/notice/save', params);
};
export const updateNotice = (params: HttpListQuery) => {
  return $http.post('/sys/notice/update', params);
};
export const deleteNotice = (params: HttpListQuery) => {
  return $http.post('/sys/notice/delete', params);
};
export const readAll = (params: HttpListQuery) => {
  return $http.post('/sys/noticeRead/readAll', params);
};
export const read = (params: HttpListQuery) => {
  return $http.post('/sys/noticeRead/save', params);
};
export const noticeAnalyBypie = (params: HttpListQuery) => {
  return $http.post('/sys/notice/analyBypie', params);
};
export const noticeAnalyList = (params: HttpListQuery) => {
  return $http.post('/sys/notice/analyByList', params, false);
};
/**
 * 数据脱敏配置模块接口
 * @param params 请求参数
 * @returns
 */
export const getDdc = (params: HttpListQuery) => {
  return $http.get('/sys/ddc/get', params);
};
export const queryDdc = (params: HttpListQuery) => {
  return $http.post('/sys/ddc/list', params);
};
export const addDdc = (params: HttpListQuery) => {
  return $http.post('/sys/ddc/save', params);
};
export const updateDdc = (params: HttpListQuery) => {
  return $http.post('/sys/ddc/update', params);
};
export const deleteDdc = (params: HttpListQuery) => {
  return $http.post('/sys/ddc/delete', params);
};
/**
 * 系统管理-日程管理模块接口
 * @param params
 * @returns
 */
export const getScheduleList = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/list', params);
};
export const addSchedule = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/save', params);
};
export const delSchedule = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/delete', params);
};
export const getDayScNum = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/getDayNums', params);
};
// 根据国家和当前月份查询节假日
export const holidayQueryHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/queryHoliday', params);
};
export const getHoursScNum = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/getHourNums', params);
};
export const getDayScDetail = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/getTodayList', params);
};
export const getHourScDetail = (params: HttpListQuery) => {
  return $http.post('/sys/schedule/getHourList', params);
};
// 根据当前月份查询有工作报告和日程的日期
export const queryActiveDate = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/queryActiveDate', params);
};

/**
 * 跑批规则模块接口
 * @param params 请求参数
 * @returns
 */
export const getOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/get', params);
};
export const queryOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/list', params);
};
export const addOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/save', params);
};
export const updateOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/update', params);
};
export const deleteOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/delete', params);
};

export const copyOrgRule = (params: HttpListQuery) => {
  return $http.post('/sys/orgRule/copyOrgRule');
};

/**
 * 32_快捷菜单管理模块接口
 * @param params 请求参数
 * @returns
 */
export const getShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/get', params);
};
export const queryShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/list', params);
};
export const addShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/save', params);
};
export const updateShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/update', params);
};
export const deleteShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/delete', params);
};
export const getRoleAuthMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/getRoleList', params);
};
export const deleteShortMenuAuth = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenuRoleAuth/delete', params);
};
export const updateMenuAuth = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenuRoleAuth/saveOrDel', params);
};
export const getShortMenuSelect = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/getSelect', params);
};
export const chanceStsShortMenu = (params: HttpListQuery) => {
  return $http.post('/sys/shortMenu/chanceSts', params);
};
export const saveShrotMenuConfig = (params: HttpListQuery) => {
  return $http.post('/sys/appShortMenuConfig/save', params);
};
export const delShrotMenuConfig = (params: HttpListQuery) => {
  return $http.post('/sys/appShortMenuConfig/delete', params);
};

/**
 * 1-35 指标配置
 * @param params 请求参数
 * @returns
 */
//查询和分页
export const indexConfigList = (params: HttpListQuery) => {
  return $http.post('/sys/indexConfig/list', params);
};
//获取指标详情
export const getIndexConfig = (params: HttpListQuery) => {
  return $http.post('/sys/indexConfig/get', params);
};
//新增保存
export const addIndexConfig = (params: HttpListQuery) => {
  return $http.post('/sys/indexConfig/save', params);
};
//修改
export const updateIndexConfig = (params: HttpListQuery) => {
  return $http.post('/sys/indexConfig/update', params);
};
//删除
export const deleteIndexConfig = (params: HttpListQuery) => {
  return $http.post('/sys/indexConfig/delete', params);
};

/**
 * 36_消息通知模块接口
 * @param params 请求参数
 * @returns
 */
export const getNotify = (params: HttpListQuery) => {
  return $http.get('/sys/notify/get', params);
};
export const queryNotify = (params: HttpListQuery) => {
  return $http.post('/sys/notify/list', params);
};
//一键阅读
export const readNotify = (params: HttpListQuery) => {
  return $http.post('/sys/notify/read', params);
};

/**
 * 37_节假日维护模块接口
 * @param params 请求参数
 * @returns
 */
export const getHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/get', params);
};
export const queryHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/list', params);
};
export const addHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/save', params);
};
export const updateHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/update', params);
};
export const deleteHoliday = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/delete', params);
};
export const importHolidayTemplate = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/import', params);
};
export const saveHolidayByYear = (params: HttpListQuery) => {
  return $http.post('/sys/holiday/saveByYear', params);
};
/**
 * 38_sql执行日志模块接口
 * @param params 请求参数
 * @returns
 */
export const getSqlLog = (params: HttpListQuery) => {
  return $http.post('/sys/sqlLog/get', params);
};
export const querySqlLog = (params: HttpListQuery) => {
  return $http.post('/sys/sqlLog/list', params);
};
/**
 * 后端刷新Redis刷新缓存接口
 */
export const loadConfigToRedis = () => {
  return $http.post('/sys/cache/loadConfigToRedis');
};
export const loadCodeTypeToRedis = () => {
  return $http.post('/sys/cache/loadCodeTypeToRedis');
};

/**
 * 切换语言
 */
export const langSwitch = (params: HttpListQuery) => {
  return $http.post('/sys/langSwitch/exec', params);
};
// 获取机构team下的rm
export const getTeamList = (params: HttpListQuery) => {
  return $http.post('/sys/team/list', params);
};
// 新增机构team下的rmcode
export const saveTeamRmcode = (params: HttpListQuery) => {
  return $http.post('/sys/team/save', params);
};
// 删除机构team下的rmcode
export const delTeamRmcode = (params: HttpListQuery) => {
  return $http.post('/sys/team/delete', params);
};

/**
 * 页面头部搜索
 * @returns
 */
export const custSearchList = (params: HttpListQuery) => {
  return $http.post('/ccm/custSearch/list', params);
};
/**
 * GienBi管理
 */
//查询和分页
export const getGienBiReport = (params: HttpListQuery) => {
  return $http.post('/sys/gienBi/list', params);
};
//新增后保存
export const addGienBiReport = (params: HttpListQuery) => {
  return $http.post('/sys/gienBi/save', params);
};
//修改
export const updateGienBiReport = (params: HttpListQuery) => {
  return $http.post('/sys/gienBi/update', params);
};
//删除
export const deleteGienBiReport = (params: HttpListQuery) => {
  return $http.post('/sys/gienBi/delete', params);
};
//获取GienBiToken
export const tokenGienBiReport = () => {
  return $http.post('/sys/user/getGienBiToken');
};
/**
 * 提示语管理
 */
//提示语配置查询
export const getLangPrompt = (params: HttpListQuery) => {
  return $http.post('/sys/langPrompt/list', params);
};
//提示语配置新增
export const addLangPrompt = (params: HttpListQuery) => {
  return $http.post('/sys/langPrompt/save', params);
};
//提示语配置修改
export const updLangPrompt = (params: HttpListQuery) => {
  return $http.post('/sys/langPrompt/update', params);
};
//提示语配置删除
export const delLangPrompt = (params: HttpListQuery) => {
  return $http.post('/sys/langPrompt/delete', params);
};
