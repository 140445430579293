import { createI18n } from 'vue-i18n';
import EN from './lang/us';
import CN from './lang/cn';
import TC from './lang/hk';

const i18n = createI18n({
  //这里是语种的持久化，刷新也存在
  locale: localStorage.getItem('language') || 'EN', // 默认是英文
  fallbackLocale: 'CN', // 语言切换的时候是英文
  globalInjection: true, //全局配置
  legacy: false, //vue3写法
  messages: { EN, CN, TC }, //本地message，也就是你需要做国际化的语种
  datetimeFormats: {
    EN: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    },
    CN: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
    TC: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    },
  },
  numberFormats: {
    EN: {
      currency: {
        //添加 $
        style: 'currency',
        currency: 'USD',
      },
    },
  },
});

export default i18n;
