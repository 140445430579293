import { WebsocketState } from './state';

export interface Getters {
  getWs(state: WebsocketState, _getters: Getters): any;
  getHeartCheckTimer(state: WebsocketState, _getters: Getters): any;
  getNotifyFun(state: WebsocketState, _getters: Getters): any;
}
export const getters = {
  getWs(state: WebsocketState, _getters: Getters): any {
    return state.ws;
  },
  getHeartCheckTimer(state: WebsocketState, _getters: Getters): any {
    return state.heartCheckTimer;
  },
  getNotifyFun(state: WebsocketState, _getters: Getters): any {
    return state.notifyFun;
  },
};
