import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return $setup.isRouteActive ? (_openBlock(), _createBlock(_component_el_config_provider, {
    key: 0,
    locale: $setup.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"])) : _createCommentVNode("v-if", true);
}