import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createBlock(_component_el_table, {
    data: $props.data,
    height: $props.height,
    onSelectionChange: $setup.handleSelectionChange,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [$props.showMutilSelect ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      type: "selection",
      width: "55"
    })) : _createCommentVNode("v-if", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, column => {
      return _openBlock(), _createBlock(_component_el_table_column, {
        key: column.prop,
        prop: column.prop,
        label: column.label,
        width: column.width,
        align: column.align,
        fixed: column.fixed,
        "show-overflow-tooltip": column.showOverflowTooltip
      }, {
        default: _withCtx(scope => [column.render ? (_openBlock(), _createBlock(_resolveDynamicComponent(column.render), _mergeProps({
          key: 0,
          ref_for: true
        }, scope), null, 16 /* FULL_PROPS */)) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(scope.row[column.prop]), 1 /* TEXT */))]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "width", "align", "fixed", "show-overflow-tooltip"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data", "height"]);
}