export default {
  performanceButton: {
    reset: 'Reset',
    search: 'Search',
    export: 'Export',
  },
  performanceRank: {
    pleaseSelect: 'Please Select',
    periodType: 'Period Type',
    campaignName: 'Campaign Name:',
    campaignPeriod:'Campaign Period',
    rmRanking: 'RM Ranking',
    dimension: 'Dimension',
    rankBy: 'Rank by',
    calculation: 'Calcultion',
    dataTime: 'Selected Month',
    rank: 'Rank',
    RM: 'RM',
    sellerCode: 'Seller Code',
    rmCode: 'RM Code',
    rankInNa: 'Ranking Indicator Name',
    rankInVa: 'Ranking Indicator Value',
    sectionHead: 'Section Head',
    department: 'Department',
    division: 'Division',
    teamHeRank: 'Team Ranking',
    teamHead: 'Team',
    teHeSeCo:'Team Head Seller Code',
    seHeRMCode: 'Team Head RM Code',
    section: 'Section',
    team: 'Team',
    pickADay: 'Please Select',
    plsPT:'please select the Period Type'
  },
  complete: {
    searchForm: {
      planPeriodType: 'Period Type',
      planId: 'Campaign Name',
      date: 'Selected Date',
    },
    label: 'Campaign Name',
    tableColumns: {
      title: 'Indicator Completion Analysis',
      dimAgg: 'Division / RM',
      indCode: 'Indicator',
      indValue: 'Completed Target Amount',
      compLastMonthEnd: 'Comparing to last month end',
      compLastYearEnd: 'Comparing to last year end',
      compLastYearSp: 'Comparing to last year same period',
      curMVal: 'Current Month Target',
      curMRate: 'Current Month Target Completion Rate',
      curYVal: 'Target',
      curYRate: 'Target Completion Rate',
    },
  },
};
