export default {
  report: {
    myReports: '我的工作报告',
    myTeamsReports: '我的团队工作报告',
    reportType: '报告类型',
    reportTitle: '报告标题',
    costomerName: '客户名称',
    reportstate: '报告状态',
    reportDate: '报告日期',
    number: '编号',
    createDate: '创建时间',
    userId: '提交人',
    reportDetails: '报告详情',
    editReport:'修改报告',
    newReport:'添加报告',
    basicInfo:'基本信息',
    reporter:'报告人',
    team:'团队',
    section:'分部',
    department:'部门',
    visitdetails:'报告详细信息',
    time:'时间',
    Address:'地址',
    remark:'备注',
    appointAppr: '指定审批人',
    selectAppr: '选择审批人',
    yesAppointAppr: '是',
    noAppointAppr: '否',
    formRules: {
      reportTitleRule: '请输入报告标题',
      visitDateRule: '请选择日期',
      custIdRule: '请选择客户',
      appointApprRule: '请选择是否指定审批人',
      selectApprRule: '请选择审批人',
      deleteMsg: '确认删除该条报告吗？',
      reportTypeRule:'请选择报告类型',
    },
    attachment:'附件',
    selectFile:'选择文件',
    supportedFileType:'支持的文件类型:',
    eachfile:'每个文件不得超过15MB。最多5个文件'
  },
};
