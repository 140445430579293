import { changeRole, langSwitch } from '@/apis/sys';
import i18n from './setup-i18n';
import { setToken } from '@/utils/http/auth';
import { store } from '@/store';
import { PermissionActionType } from '@/store/modules/permission/action-types';
import { AppActionType } from '@/store/modules/app/action-types';
import { useActions, useGetters, useState } from '@/utils/hooks/store-hooks';
import { UserActionTypes } from '@/store/modules/user/action-types';
import { TagsActionTypes } from '@/store/modules/tagsview/action-types';
import constantRoutes from '@/router/constant-modules';

export const changeLanguage = async (val: any) => {
  localStorage.setItem('language', val);
  i18n.global.locale.value = val;
  const { code } = await langSwitch({ language: val, fedbackSrc: 'PC' });
  // 全部异步执行完之后再抛出
  if (code == 200) {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo') as string);
    //重新获取用户当前角色的信息
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    // 更新码值信息
    await store.dispatch(
      `app/${AppActionType.ACTION_SET_CODELIST}`,
      {},
      { root: true }
    );
    // 保存更新后的用户信息
    await store
      .dispatch(`user/${UserActionTypes.ACTION_CHANGE_ROLES}`, userInfo, {
        root: true,
      })
      .then(() => {
        handleResetMenu();
      });
  }
};

// 循环数组
export const loop = (list: any, key: string) => {
  const tagsViewState = store.state.tagsView;
  let _list: Array<string> = [];
  tagsViewState.visitedViews.forEach((item: any) => {
    const { name } = item;
    _list.push(name);
  });
  let obj: Array<object> = [];
  list.map((item: any) => {
    if (_list.indexOf(item[key]) !== -1) {
      obj.push({ ...item });
    } else {
      if (item.children && item.children.length > 0) {
        let _obj = loop(item.children, key);
        if (_obj && _obj.length > 0) {
          obj = [...obj, ..._obj];
        }
      }
    }
  });
  return obj;
};

/* 语言切换重置面包屑导航栏
 */
export const handleResetMenu = async () => {
  // 后端返回全路由
  const allMenuList = JSON.parse(sessionStorage.getItem('allMenuList') || '{}');
  // 静态路由
  const constantRoute = constantRoutes();
  const itemList = loop(allMenuList, 'id');
  const itemList2 = loop(constantRoute, 'name');
  const tagsViewState = store.state.tagsView;
  let visitedList: Array<object> = [];
  tagsViewState.visitedViews.forEach((item: any) => {
    visitedList.push(item);
  });
  const newList: Array<object> = [];
  visitedList.forEach((item: any) => {
    // 先判断后端返回的路由有没有包含，如果没有，再找静态路由有没有匹配的
    itemList.some((childItem: any) => {
      if (item.name == childItem.id) {
        newList.push({
          ...item,
          meta: {
            ...item.meta,
            title: childItem.meta.title,
          },
        });
        return true;
      }
    }) ||
      itemList2.some((childItem: any) => {
        if (item.name == childItem.name) {
          newList.push({
            ...item,
            meta: {
              ...item.meta,
              title: childItem.meta.title,
            },
          });
          return true;
        }
      });
  });

  // await refreshTags(newList);
  store.dispatch(
    `tagsView/${TagsActionTypes.ACTION_REFRESH_VISITED_VIEW}`,
    newList,
    {
      root: true,
    }
  );
};

export const loginChangeLanguage = async (val: any) => {
  localStorage.setItem('language', val);
  i18n.global.locale.value = val;
};
export default { ...i18n.global };
