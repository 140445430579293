import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21d8a41e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "user-box"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "user-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: $setup.personSvg,
    alt: "head portrait",
    class: "round-icon"
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("div", _hoisted_3, _toDisplayString($setup.getUserInfo['userName']), 1 /* TEXT */)]);
}