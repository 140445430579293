export default {
  groupView: {
    button: {
      search: '查询',
      reset: '刷新',
      export: '导出',
    },
    title: {
      borrowGrade: '借款人等级',
      BorrowGradeReDa: '借款人等级审核日期',
      income: '收入',
      dod: '较上日',
      mom: '较上月',
      yoy: '较上年同期',
      laMonEn: '较上月末',
      laYeEn: '较上年末',
      loan: '贷款',
      deposit: '存款',
      asOfDate: '统计日期',
    },
    summary: {
      summary: '概要信息',
      basic: '基本信息',
      basicInfo: '基本信息',
      ulCtrlNum: '集团号',
      ulCtrlName: '集团名称 (英)',
      ulCtrlNameCN: '集团名称 (中)',
      certCode: '证件号码',
      certType: '证件类型',
      ulCtrlInfo: '集团信息',
      subsidiaries: '子公司',
      customNum: '客户号',
      customName: '客户名称 (英)',
      customNameCN: '客户名称 (中)',
      RM: 'RM',
      department: '部门',
      sectionHead: '分部负责人',
      teamHead: '团队负责人',
    },
    milestone: {
      milestone: '合作里程碑',
    },
    business: {
      business: '业务信息',
      income: '收入',
      loans: '贷款',
      deposits: '存款',
      timeframe: '周期类型',
      incomeTypeValue: '收入类型',
      productTypeValue: '产品类型',
      calculationType: '计算类型',
      detSumData: '详细汇总数据',
      // 表头
      rank: '排序',
      customNum: '客户号',
      customName: '客户名称 (英)',
      rmEname: 'RM',
      comparison: '比较',
      percentage: '百分比 (%)',
      balance: '余额',
      balanceType: '余额类型',
      incomeMTD: '本月收入',
      incomeQTD: '本季度收入',
      incomeYTD: '本年收入 (YTD)',
      incomeLastMTD: '上月收入',
      incomeLastQTD: '上季度收入',
      incomeLastYTD: '上年收入 (YTD)',
      loanMTD: '月均余额 (本月)',
      loanYTD: '年均余额 (本月)',
      loanPeEnd: '期末余额(本期)',
      loanBalLastMTD: '月均余额 (去年同月)',
      loanBalLastYTD: '年均余额 (去年同月)',
      loanBalLastPeEnd: '期末余额 (去年年底)',
      depositMTD: '月均余额 (本月)',
      depositYTD: '年均余额 (本月)',
      depositPeEnd: '期末余额 (本期)',
      depositLastMTD: '月均余额 (去年同月)',
      depositLastYTD: '年均余额 (去年同月)',
      depositLastPeEnd: '期末余额 (去年年底)',
      monthly: '按月计算',
      yearly: '按年计算',
      quarterly: '按季度计算',
      mtd: '月至今',
      ytd: '年至今',
      qtd: '季至今',
      peEnBa: '期末余额',
      daAv: '每日平均',
      peEn: '期末',
      usedCredit:'已使用额度',
      unit: '单位',
    },
    management: {
      management: '管理',
      credLimts: '信用额度',
      ulConInfo: '集团信息',
      ulConCreLim: '集团信用额度',
      creLimReaMax: '信用额度最大值',
      creLimCur: '信用额度货币',
      creLimCurOut:'信用额度伙伴 (未清余额)',
      OutstanBa:'未清余额',
      creLimt: '信用额度',
      exCreLim: '风险信用额度',
      oneInfo: 'OneBank 信息',
      oneBankReg: 'OneBank所属区域',
      GRMRegion: 'GRM所属区域',
      GRMUnit: 'GRM归属',
      GRMContact: 'GRM',
      GRM2Contact: '第二GRM',
      LRM: 'LRM',
    },
  },
};
