export default {
  make: {
    // 经营计划 -- 创建计划
    createPlan: {
      createPlanTitle: 'Basic information',
      planName: 'Campaign Name',
      planNamePlaceholder: 'Please Enter Campaign Name',
      cycleType: 'Period Type',
      cycleTypePlaceholder: 'Please Enter Period Type',
      cycleVal: 'Campaign Period',
      cycleValPlaceholder: 'Please Enter Period Type',
      nextBtnText: 'Next',
      closeBtnText: 'Cancel',
      confirmText: 'The basic information of the plan has been saved',
    },
    // 经营计划 -- 创建与修改计划
    edit: {
      totalTarget: 'Overall Target',
      cycleTarget: 'Period Target',
      errorMessage:
        'If the plan type does not exist, please contact the administrator',
      confirmMessage:
        'Confirm whether it is closed, and unsaved modified information will be lost!',
      confirmTitle: 'Confirmation',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      cancleInfoText: 'Cancle Close',
      objInitValue: 'The previous planning stage completion value',
      taskVal: 'Target Value',
      firstQtr: 'Quarter 1',
      secondQtr: 'Quarter 2',
      thirdQtr: 'Quarter 3',
      fourthQtr: 'Quarter 4',
      total: 'Sum',
      auto: 'Auto-fill',
      firstMth: 'Month 1',
      secondMth: 'Month 2',
      thirdMth: 'Month 3',
      fourthMth: 'Month 4',
      fifthMth: 'Month 5',
      sixthMth: 'Month 6',
      seventhMth: 'Month 7',
      eighthMth: 'Month 8',
      ninthMth: 'Month 9',
      tenthMth: 'Month 10',
      eleventhMth: 'Month 11',
      twelfthMth: 'Month 12',
      indexName: 'Indicator Name',
      unit: 'Unit',
      indexNumProp: 'indicators Attributes',
      initVal: 'Last Period Completed Value',
      taskVal1: 'Target value',
      taskVal2: 'Setting Target Value',
      parentTaskVal: 'Assigned Target Value',
      action: 'Action',
      check: 'Verify',
      distributedTask: 'Assign Target',
      remove: 'Remove',
      addIndex: 'Add Indicator',
      objType: 'Target Assigned to',
      objTypePlaceholder: 'Please select the target of the task',
      addTaskObj: 'Add',
      index: 'Indicator Name',
      objName: 'Assigned To',
      save: 'Save',
      close: 'Close',
      submit: 'Submit',
      errorMessage1: 'If the plan type is incorrect, contact O&M personnel',
      approval: 'Approval',
      submitTitle: 'Submit Success',
      saveTitle: 'Save Success',
      removeSucc: 'Remove Success',
      removeMessage: 'Are you sure to remove the task object?',
      cancelledTitle: 'cancelled',
      addMessage: 'Please select the Target Assigned to',
      addTitle: 'This indicator is already on the list!',
      confirmRemoveText: 'Determine remove indicator',
      msg1: 'The target value of the set indicators is less than the target value of the parent level',
      msg2: 'The current indicator has a target value that is not split!',
      checkSuccess: 'Check Success',
      customizeTitle: 'Customize monitoring indicators',
      selectIndex: 'Select an indicator',
      selectIndexPlaceholder: 'Please enter a name for the indicator',
      allSel: 'Check all',
      allCancleSel: 'Cancel all',
      realTitle: 'Confirm delete',
      replenish: 'All indicators selected in ?',
      title: 'Confirm set the selected indicators?',
      indexSel: 'The indicators is selected',
      indexCanSel: 'Deselected',
      orgSel: 'Select an institution',
      orgSelPlaceholder: 'Please enter a keyword to search',
      useSel: 'Select Users',
      userId: 'UserId',
      userName: 'UserName',
      roleName: 'RoleName',
      orgId: 'Functional department',
      orgId1: 'Affiliation',
      search: 'Search',
      selObjName: 'Assign to',
      qtrRadio1: 'By Value',
      qtrRadio2: 'By Percentage',
      cycleName: 'Quarter',
      num: 'Numeric value',
      taskPercent: 'Percent',
      cycleName1: 'Month',
      previous: 'Previous',
      message1:
        'If the verification fails and the current plan type is incorrect, contact the administrator.',
      message2:
        'Operation failed! There is no periodic task for the selected task',
      message3:
        'There are undisassembled values, and the sum of dismantles in the current quarter is not equal to the target value!',
      message4:
        'There are undisassembled values, and the sum of the current monthly dismantles is not equal to the target value',
      message5:
        'The Q1 Split Value is not equal to the Monthly Split Value for the 1st, 2nd, and 3rd Quarters',
      message6:
        'The Q2 split value is not equal to the 4th, 5th, and 6th monthly split totals',
      message7:
        'The Q3 Split Value is not equal to the 7th, 8th, and 9th Month Split Total',
      message8:
        'The Q4 Split Value is not equal to the 10th, 11th, and 12th Monthly Split Total',
      message9: 'The target value was not met in the fourth quarter',
      message10: 'The target value was not reached in December',
      message11:
        'The December teardown value is not equal to the Q4 teardown value',
      message12:
        'The September teardown value is not equal to the Q3 teardown value',
      message13:
        'The June teardown value is not equal to the Q2 teardown value',
      message14:
        'The 3rd month teardown value is not equal to the 1st quarter teardown value',
      message15:
        'Please contact the administrator if the verification fails and the current indicator attribute is wrong, please contact the administrator.',
      message16: 'The month-end teardown value did not reach the target value',
      message17: 'The month-end teardown value did not reach the target value',
      message18: 'The verification passes',
      message19: 'Only 10 can be selected',
      indexText: 'Indicator List',
      objText: 'Object list',
      monthText: 'Month List',
      dayText: 'Day List',
      quarterText: 'Quarter List',
    },
    // 经营计划 -- 列表三语表格
    tableColumn: {
      fixedPlanId: 'Campaign ID',
      planName: 'Campaign Name',
      cycleType: 'Period Type',
      cycleVal: 'Campaign Period',
      createDate: 'Created Date',
      createOrgName: 'Created Department',
      createUserName: 'Created User',
      planSts: 'Status',
    },

    addBtn: 'Create Campaign',
    searchForm: {
      cycleType: 'Period Type',
      cycleVal: 'Campaign Period',
      createDate: 'Created Date',
      planName: 'Campaign Name',
      planSts: 'Campaign Status',
      cycleTypePlaceholder: 'Please select the period Type',
      cycleValPlaceholder: 'Please select the campaign Period',
      createDatePlaceholder: 'Please select the created Date',
      planNamePlaceholder: 'Please enter the campaign Name',
      planStsPlaceholder: 'Please select the status',
    },
    // 经营计划 -- 承接计划分解
    assimt: {
      undertakeTitle: 'Basic Information',
      planName: 'Distributed Campaign Name',
      startDate: 'Start date',
      endDate: 'End date',
      planDesc: 'Program description',
    },
    // 经营计划 -- 查看弹窗三语表格
    view: {
      basicSetp: 'Basic Information',
      targetSetp: 'Targets',
      planName: 'Campaign Name',
      cycleType: 'Period Type',
      cycleVal: 'Campaign Period',

      campaign: 'Indicators',
      overall: 'Total Target',
      quarterly: 'Quarterly',
      monthly: 'Monthly Target',
      daily: 'Daily Target',
    },
  },
  // 经营承接
  assimt: {
    // 经营计划承接 -- 列表三语表格
    tableColumn: {
      parentPlanName: 'Campaign Name', // 上一级计划名称
      publisher: 'Publisher',
      status: 'Status',
      planName: 'Distributed Campaign Name',
      assimtDate: 'Distribution Date',
      receiveDate: 'Receive Date',
      planSts: 'Distribution Status',
    },
  },
  action: {
    action: 'Action',
    actionBtn: {
      view: 'View',
      edit: 'Edit',
      delete: 'Delete',
      submit: 'Submit',
      publish: 'Publish',
      adjust: 'Adjust',
      unadjust: 'Unadjust',
      withdraw: 'Withdraw',
      approvalDetails: 'ApprovalDetails',
      distribute: 'Distribute',
    },
  },
  elMessageTip: {
    delete: 'Confirm the delete of the Campaign',
    submit: 'Confirm the submit of the Campaign?',
    submitSuc: 'Submitted successfully',
    publish: 'Confirm the publication of the Campaign?',
    publishSuc: 'Publish successfully',
    adjust: 'Confirm the adjustment of the Campaign?',
    adjustSuc: 'The campaign has entered adjucting state',
    withdraw: 'Confirm to withdraw this campaign?',
    withdrawSuc: 'Withdrawn successfully',
    unadjust: 'Confirm to cancel the campaign',
    unadjustSuc: 'Operation successful',
    assimt: 'Distribute the Campaign?',
  },
};
