export default {
  groupView: {
    button: {
      search: 'Search',
      reset: 'Reset',
      export: 'Export',
    },
    title: {
      borrowGrade: 'Borrower Graade',
      BorrowGradeReDa: 'Borrower Grade Reviewed Date',
      income: 'Income',
      dod: 'DoD',
      mom: 'MoM',
      yoy: 'YoY',
      laMonEn: 'Last Month End',
      laYeEn: 'Last Year End',
      loan: 'Loans',
      deposit: 'Deposits',
      asOfDate: 'As Of Date',
    },
    summary: {
      summary: 'Summary',
      basic: 'Basic',
      basicInfo: 'Basic Information',
      ulCtrlNum: 'Ultimate Controller Number',
      ulCtrlName: 'Ultimate Controller Name',
      ulCtrlNameCN: 'Ultimate Controller Name (Chinese)',
      certCode: 'Document ID',
      certType: 'Document Type',
      ulCtrlInfo: 'Ultimate Controller Information',
      subsidiaries: 'Subsidiaries',
      customNum: 'Customer Number',
      customName: 'Customer Name',
      customNameCN: 'Customer Name (Chinese)',
      RM: 'RM',
      department: 'Department',
      sectionHead: 'Section Head',
      teamHead: 'Team Head',
    },
    milestone: {
      milestone: 'Milestone',
    },
    business: {
      business: 'Business',
      income: 'Income',
      loans: 'Loans',
      deposits: 'Deposits',
      timeframe: 'Period Type',
      incomeTypeValue: 'Income Type',
      productTypeValue: 'Product Type',
      calculationType: 'Calculation Type',
      detSumData: 'Detailed Summary Data',
      balance: 'Balance',
      balanceType: 'Balance Type',
      // 表头
      rank: 'Rank',
      customNum: 'Customer Number',
      customName: 'Customer Name',
      rmEname: 'RM',
      comparison: 'Comparison',
      percentage: 'Percentage (%)',
      incomeMTD: 'Current Month Income',
      incomeQTD: 'Current Quarter Income',
      incomeYTD: 'Current Year Income (YTD)',
      incomeLastMTD: 'Last Month Income',
      incomeLastQTD: 'Last Quarter Income',
      incomeLastYTD: 'Last Year Income (YTD)',
      loanMTD: 'Monthly Average Balance (Current Month)',
      loanYTD: 'Yearly Average Balance (Current Month)',
      loanPeEnd: 'Period End Balance (Current)',
      loanBalLastMTD: 'Monthly Average Balance (Last Year Same Month)',
      loanBalLastYTD: 'Yearly Average Balance (Last Year Same Month)',
      loanBalLastPeEnd: 'Period End Balance (last Year End)',
      depositMTD: 'Monthly Average Balance (Current Month)',
      depositYTD: 'Yearly Average Balance (Current Month)',
      depositPeEnd: 'Period End Balance (Current)',
      depositLastMTD: 'Monthly Average Balance (Last Year Same Month)',
      depositLastYTD: 'Yearly Average Balance (Last Year Same Month)',
      depositLastPeEnd: 'Period End Balance (Last Year End)',
      monthly: 'Monthly',
      yearly: 'Yearly',
      quarterly: 'Quarterly',
      mtd: 'MTD',
      ytd: 'YTD',
      qtd: 'QTD',
      peEnBa: 'Period End Balance',
      daAv: 'Daily Average',
      peEn: 'Period End',
      usedCredit:'Used Credit',
      unit: 'Unit',
    },
    management: {
      management: 'Management',
      credLimts: 'Credit Limits',
      ulConInfo: 'Ultimate Controller',
      ulConCreLim: 'Ultimate Controller Credit Limits',
      creLimReaMax: 'Credit Limit Reached Maximum',
      creLimCur: 'Credit Limit Currency',
      creLimCurOut:'Credit Limit Currency (Outstanding Balance)',
      OutstanBa:'Outstanding Balance',
      creLimt: 'Credit Limit',
      exCreLim: 'Exposure Credit Limit',
      oneInfo: 'OneBank Information',
      oneBankReg: 'OneBank Region',
      GRMRegion: 'GRM Region',
      GRMUnit: 'GRM Unit',
      GRMContact: 'GRM Contact',
      GRM2Contact: 'GRM 2nd Contact',
      LRM: 'LRM',
    },
  },
};
