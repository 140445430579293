export default {
  make: {
    // 经营计划 -- 创建计划
    createPlan: {
      createPlanTitle: '計劃基本信息',
      planName: '計劃名稱',
      planNamePlaceholder: '請輸入計劃名稱',
      cycleType: '週期類型',
      cycleTypePlaceholder: '請選擇週期類型',
      cycleVal: '計劃週期',
      cycleValPlaceholder: '請選擇計劃週期',
      nextBtnText: '下一步',
      closeBtnText: '取 消',
      confirmText: '已保存計劃基本信息',
    },
    // 经营计划 -- 创建与修改计划
    edit: {
      totalTarget: '總任務目標',
      cycleTarget: '週期任務目標',
      errorMessage: '計劃類型不存在,請聯繫管理人員',
      confirmMessage: '確認是否關閉，未保存的已修改信息將丟失！',
      confirmTitle: '提示',
      confirmButtonText: '確認',
      cancelButtonText: '取消',
      cancleInfoText: '取消關閉',
      objInitValue: '上一計劃階段完成值',
      taskVal: '目標值',
      taskVal2: '設定目標值',
      firstQtr: '第1季度',
      secondQtr: '第2季度',
      thirdQtr: '第3季度',
      fourthQtr: '第4季度',
      total: '合計',
      firstMth: '第1個月',
      secondMth: '第2個月',
      thirdMth: '第3個月',
      fourthMth: '第4個月',
      fifthMth: '第5個月',
      sixthMth: '第6個月',
      seventhMth: '第7個月',
      eighthMth: '第8個月',
      ninthMth: '第9個月',
      tenthMth: '第10個月',
      eleventhMth: '第11個月',
      twelfthMth: '第12個月',
      indexName: '指標名稱',
      unit: '指標單位',
      indexNumProp: '指標屬性',
      initVal: '上一計劃階段完成值',
      taskVal1: '目標值',
      auto: '自動填充',
      parentTaskVal: '上級目標值',
      action: '操作',
      check: '校 驗',
      distributedTask: '分配任務',
      remove: '移 除',
      addIndex: '添加指標',
      objType: '任務對象類型',
      objTypePlaceholder: '請選擇任務對象',
      addTaskObj: '添加任務對象',
      index: '指標',
      objName: '對象名稱',
      save: '暫 存',
      close: '關 閉',
      submit: '提 交',
      errorMessage1: '計劃類型錯誤,請聯繫運維人員',
      approval: '審批',
      submitTitle: '提交成功！',
      saveTitle: '保存成功！',
      removeSucc: '移除成功',
      removeMessage: '確認移除該任務對象？',
      cancelledTitle: '已取消',
      addMessage: '請選擇任務對象類型',
      addTitle: '該指標已在列表中！',
      confirmRemoveText: '確定移除指標',
      msg1: '設定指標目標值小於上級目標值!',
      msg2: '當前指標存在目標值未拆分',
      checkSuccess: '校驗通過',
      customizeTitle: '自定義監測指標',
      selectIndex: '選擇指標',
      selectIndexPlaceholder: '請輸入指標名稱',
      allSel: '全部勾選',
      allCancleSel: '全部取消勾選',
      realTitle: '確定刪除',
      replenish: '中選中的所有指標?',
      title: '確定設置所選指標?',
      indexSel: '指標已選擇',
      indexCanSel: '已取消選擇',
      orgSel: '選擇機構',
      orgSelPlaceholder: '請輸入關鍵字進行搜索',
      useSel: '選擇用戶',
      userId: '用戶號',
      userName: '用戶名稱',
      roleName: '用戶角色',
      orgId: '所屬職能部門',
      orgId1: '所屬機構',
      search: '查詢',
      selObjName: '對象',
      qtrRadio1: '按數值',
      qtrRadio2: '按比例',
      cycleName: '季度',
      num: '數值',
      taskPercent: '比例',
      cycleName1: '月份',
      previous: '上一步',
      message1: '校驗失敗,當前計劃類型錯誤,請聯繫管理員.',
      message2: '操作失敗！所選任務不存在週期任務',
      message3: '存在未拆解值,當前季度拆解總和不等於目標值!',
      message4: '存在未拆解值,當前月度拆解總和不等於目標值',
      message5: '第1季度拆解值不等於第1、2、3月度拆分總值',
      message6: '第2季度拆解值不等於第4、5、6月度拆分總值',
      message7: '第3季度拆解值不等於第7、8、9月度拆分總值',
      message8: '第4季度拆解值不等於第10、11、12月度拆分總值',
      message9: '第4季度未達到目標值',
      message10: '第12月未達到目標值',
      message11: '第12月拆解值不等於第4季度拆解值',
      message12: '第9月拆解值不等於第3季度拆解值',
      message13: '第6月拆解值不等於第2季度拆解值',
      message14: '第3月拆解值不等於第1季度拆解值',
      message15: '校驗失敗,當前指標屬性錯誤,請聯繫管理員.',
      message16: '存在未拆解值,當前月度拆解總和不等於目標值',
      message17: '月度末拆解值未達到目標值',
      message18: '校驗通過',
      message19: '只能選擇10條',
      indexText: '指標數據',
      objText: '對象數據',
      monthText: '月份數據',
      dayText: '天數據',
      quarterText: '季度數據',
    },
    // 经营计划 -- 列表三语表格
    tableColumn: {
      fixedPlanId: '計畫Id',
      planName: '計畫名稱',
      cycleType: '週期類型',
      cycleVal: '計畫週期',
      createDate: '創建時間',
      createOrgName: '創建部門',
      createUserName: '創建人',
      planSts: '狀態',
    },

    addBtn: '創建計畫',
    searchForm: {
      cycleType: '週期類型',
      cycleVal: '計畫週期',
      createDate: '創建時間',
      planName: '計畫名稱',
      planSts: '狀態',
      cycleTypePlaceholder: '請選擇週期類型',
      cycleValPlaceholder: '請選擇計畫週期',
      createDatePlaceholder: '請選擇日期',
      planNamePlaceholder: '請輸入計畫名稱',
      planStsPlaceholder: '請選擇計畫狀態',
    },
    // 经营计划 -- 承接计划分解
    assimt: {
      undertakeTitle: '承接計畫基本資訊',
      planName: '計畫名稱',
      startDate: '開始日期',
      endDate: '結束日期',
      planDesc: '計畫描述',
    },
    // 经营计划 -- 查看弹窗三语表格
    view: {
      basicSetp: '基本資訊',
      targetSetp: '目標',
      planName: '計畫名稱',
      cycleType: '週期類型',
      cycleVal: '計畫週期',

      campaign: '計畫指標',
      overall: '總目標',
      quarterly: '季度目標',
      monthly: '月度目標',
      daily: '日目標',
    },
  },
  // 经营承接
  assimt: {
    // 經營計畫承接 -- 列表三語表格
    tableColumn: {
      parentPlanName: '計畫名稱', // 上一級計畫名稱
      publisher: '發佈人',
      status: '狀態',
      planName: '計畫承接名稱',
      assimtDate: '承接日期',
      receiveDate: '接收日期',
      planSts: '承接狀態',
    },
  },
  action: {
    action: '操作',
    actionBtn: {
      view: '查看',
      edit: '修改',
      delete: '刪除',
      submit: '提交',
      publish: '發佈',
      adjust: '檢視調整',
      unadjust: '取消檢視調整',
      withdraw: '撤回',
      approvalDetails: '審批詳情',
      distribute: '承接',
    },
  },
  elMessageTip: {
    delete: '確認刪除該記錄嗎？',
    submit: '確認提交審批計畫嗎？',
    submitSuc: '提交成功',
    publish: '確認發佈計畫？',
    publishSuc: '發佈成功',
    adjust: '確認檢視調整計畫？',
    adjustSuc: '計畫已進入檢視調整狀態',
    withdraw: '確認撤回計畫審批申請？',
    withdrawSuc: '已撤回',
    unadjust: '確認取消檢視調整計畫？',
    unadjustSuc: '操作成功',
    assimt: '確認承接審批計畫嗎？',
  },
};
